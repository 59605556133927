
export const modalBaseGetters = {
  modalCloseWarning(state) {
    return false;
  },
  modalCloseButton(state) {
    return false;
  },
  modalDefaultClose(state) {
    return true;
  },
  modalCloseEnabled(state) {
    return true;
  },
};
