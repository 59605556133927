const axios = require('axios');
const { DateTime } = require('luxon');

function deleteAllCookies() {
  if (document) {
    var cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      deleteCookie(name);
    }
  }
}

function deleteCookie(name) {
  setCookie(name, {
    value: '',
    expiresDateTime: DateTime.now().minus({ days: 1 }),
  });
}

function setCookie(name, cookieData) {
  cookieData = Object.assign(
    { path: '/' },
    cookieData
  );

  const cookieParts = [
    name,
    'path',
    'domain',
    'max-age',
    'expires',
    'secure',
    'samesite',
  ];

  cookieData[name] = cookieData.value;

  if (cookieData.expiresDateTime) {
    cookieData.expires = cookieData.expiresDateTime.toHTTP();
  }

  const cookieConfigString = cookieParts
    .map((name) => {
      const value = cookieData[name];
      if (value === undefined) {
        return null;
      }
      return `${name}=${value};`;
    })
    .filter(x => x)
    .join(' ');

  if (document) {
    document.cookie = cookieConfigString;
  }
}

class Sdk {

  static baseUrl = process.env.apiBaseUrl// || 'https://cp.learningful.com';

  constructor(context) {
    this.context = context;

    this.captchaToken = null;
    this.requestBlockingEnabled = true;
    this.requestBlockedByCaptcha = null;
    this.initBlockedRequest();

    this.credentialsToResubmit = null;

    this.handlingBadToken = false;
  }

  static getStub() {
    return new Sdk({
      store: {
        state: {
          main: {
            accessToken: null,
            requestLimit: {},
          },
        },
        commit() { },
      },
    });
  }

  get accessToken() {
    return this.context.store.state.main.accessToken;
  }

  get accessTokenDecoded() {
    return this.context.store.getters['main/accessTokenDecoded'];
  }

  applyAuthHeader(headers) {
    const headersCopy = Object.assign({}, headers);
    if (this.isAuthenticated()) {
      headersCopy.Authorization = `Bearer ${this.accessToken}`;
    }
    if (this.captchaToken) {
      headersCopy['captcha-token'] = this.captchaToken;
    }
    return headersCopy;
  }

  async fetch(url, options = {}) {
    const baseOptions = {
      method: 'get',
      headers: this.applyAuthHeader({
        'Content-Type': 'application/json',
      }),
    };

    const allOptions = Object.assign({}, baseOptions, options);
    const fullUrl = `${Sdk.baseUrl}${url}`

    return await this.processFetch(fullUrl, allOptions);
  }

  async downloadFile(url, ref, onProgress) {
    return axios({
      method: 'post',
      url: url,
      responseType: 'blob', // For downloading files
      onDownloadProgress: onProgress, // Axios callback for progress
      headers: this.applyAuthHeader({
        refCode: ref
      }),
    });
  }

  async submitPattern(pattern) {
    this.context.store.commit('pattern/set', { checking: true });
    try {
      await this.authenticate(Object.assign({}, this.credentialsToResubmit, {
        pattern,
      }));
      this.context.store.commit('pattern/set', {
        checking: false,
        success: true,
      });

      await new Promise(resolve => window.setTimeout(() => {
        this.context.store.commit('pattern/set', {
          isShowing: false,
        });
        resolve();
      }, 3000));


      this.context.store.commit('login/set', {
        successfulLogin: true,
        invalidCredentials: false,
      });

      await new Promise(resolve => window.setTimeout(resolve, 500));
      await this.finishAuthentication();
      return true;
    }
    catch (e) {
      this.context.store.commit('pattern/set', { checking: false });
      return false;
    }
  }

  async processFetch(url, options = {}) {
    let response = await fetch(url, options);

    let responseJson = await this.getJsonBodySafely(response);
    if (response.status === 403) {
      this.context.store.commit('login/set', {
        invalidCredentials: true,
      });
    }

    if (response.status === 401 && ['requires_captcha', 'cooling_off'].includes(responseJson.reason)) {


      this.context.store.commit('captcha/loadCaptchaFromResponseBody', responseJson);
      this.context.store.dispatch('captcha/dispatchIsImageShowing');
      if (!this.requestBlockedPromise && this.requestBlockingEnabled) {
        response = await this.blockRequestUntilCaptchaSolved({ url, options });
        //responseJson = await this.getJsonBodySafely(response);
      }
    }

    if (response.status === 401 && ['requires_pattern'].includes(responseJson.reason)) {
      this.context.store.commit('pattern/set', {
        maxTries: responseJson?.maxTries,
        recentTries: responseJson?.recentTries,
        state: responseJson?.state,
      });

      // if (responseJson.state === 'access_locked') {
      //   this.context.store.commit('pattern/set', { isShowing: false });
      //   this.context.store.commit('login/set', { isLocked: true });
      //   return response;
      // }

      if (this.credentialsToResubmit) {
        try {
          await this.context.store.dispatch('pattern/animateError');
        } catch (e) {
          console.error(e)
        }
        return response;
      }
      this.context.store.commit('pattern/set', { isShowing: true });
      this.credentialsToResubmit = JSON.parse(options.body);
    }

    // if (response.status === 401 && responseJson.reason === 'email_not_verified') {
    //   this.context.store.commit('login/set', { isEmailLocked: true });
    //   return;
    // }

    if (response.status === 401 && responseJson.reason === 'insufficient_permissions') {
      this.context.store.commit('login/set', { isLocked: true });
      return;
    }

    if (response.status === 401 && responseJson.reason === 'invalid_token') {
      if (this.handlingBadToken) {
        return;
      }
      if (this.context.route.name === 'index-login') {
        return;
      }
      this.handlingBadToken = true;
      this.clearAuthentication(false);
      this.setPostAuthRedirect(this.context.route.path);
      this.context.redirect('/login');
      return response;
    }

    if (response.status === 429) {
      this.context.store.dispatch('main/setRequestLimitExceeded', {
        isExceeded: true,
        earliestRetry: responseJson.earliestRetry,
        isLocked: responseJson.isLocked,
        url,
      });
    }
    else if (!this.isStatusCodeAnError(response.status)) {
      const requestLimit = this.context.store.state.main.requestLimit;
      if (url === requestLimit.url) {
        await this.context.store.dispatch('main/clearRequestLimitExceeded');
      }
    }

    return response;
  }

  isStatusCodeAnError(code) {
    if (code >= 400 && code <= 499) {
      return true;
    }

    if (code >= 500 && code <= 599) {
      return true;
    }

    return false;
  }

  async blockRequestUntilCaptchaSolved({ url, options }) {
    this.requestBlockedByCaptcha = {
      url,
      options,
    };

    this.requestBlockedPromise = new Promise((resolve) => {
      this.resolveBlockedRequest = resolve;
    });

    const response = await this.requestBlockedPromise;
    return response;
  }

  initBlockedRequest() {
    this.requestBlockedByCaptcha = null;
    this.requestBlockedPromise = null;
    this.resolveBlockedRequest = () => { };
  }

  async clearBlockedRequest(response) {
    this.resolveBlockedRequest(response);
    this.initBlockedRequest();
    this.context.store.commit('captcha/clear');
  }

  async submitCaptcha() {
    const captchaResponse = {
      id: this.context.store.state.captcha.challenge.id,
      inputCodes: this.context.store.getters['captcha/responseCodes'],
    };

    this.context.store.commit('captcha/setChecking', true);
    await new Promise(resolve => window.setTimeout(resolve, 500));
    const response = await this.processFetch(`/api/auth/captcha`, {
      method: 'POST',
      body: JSON.stringify(captchaResponse),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this.context.store.commit('captcha/setChecking', false);

    const responseJson = await this.getJsonBodySafely(response);
    if (this.requestBlockedByCaptcha) {
      if (!(response.status === 401 && ['requires_captcha', 'cooling_off'].includes(responseJson.reason))) {
        // this.context.store.commit('sign-up/set', { signUpImage: 'Sign Up Left Panel Background (Intermediary)' });
        // window.setTimeout(() => {
        //   this.context.store.commit('sign-up/set', { signUpImage: 'Sign Up Left Panel Background (Captcha)' });
        // }, 500);


        this.context.store.commit('captcha/setChecking', false);
        this.context.store.commit('captcha/setSuccess', true);

        await new Promise((resolve) => { window.setTimeout(resolve, 500); });

        this.context.store.commit('captcha/set', {
          isShowing: false,
        });

        await new Promise((resolve) => { window.setTimeout(resolve, 500); });

        this.captchaToken = responseJson.captchaToken;
        const replayResponse = await this.replayRequestBlockedByCaptcha();
        await this.clearBlockedRequest(replayResponse);
      }
      else {
        this.context.store.commit('captcha/setSuccess', false);
        this.context.store.commit('captcha/setTimeOut', responseJson.timeout);
        await new Promise((resolve) => { window.setTimeout(resolve, 2000); });
        this.context.store.commit('captcha/clearSuccess');
      }
    }

    return response;
  }

  async replayRequestBlockedByCaptcha() {
    if (!this.requestBlockedByCaptcha) {
      throw new Error(`No request to replay!`);
    }

    const { url, options } = this.requestBlockedByCaptcha;

    const optionsWithCaptcha = Object.assign({}, options, {
      headers: this.applyAuthHeader(Object.assign({}, options.headers || {})),
    });

    const response = await this.processFetch(url, optionsWithCaptcha);
    return response;
  }

  async getJsonBodySafely(response) {
    const responseClone = response.clone();
    try {
      const json = await responseClone.json();
      return json;
    } catch (e) {
      return {};
    }
  }

  async get(url, params = {}) {
    const searchParams = new URLSearchParams(params);
    const suffix = searchParams.toString() ? '?' + searchParams.toString() : '';
    const urlWithParams = url + suffix;
    return await this.fetch(urlWithParams);
  }

  async performRequestWithBody(method, url, body) {
    if (body.constructor?.name === 'FormData') {
      return await this.fetch(url, {
        method,
        body,
        headers: this.applyAuthHeader({}),
      });
    }

    return await this.fetch(url, {
      method,
      body: JSON.stringify(body),
    });
  }

  async post(url, body = {}) {
    return await this.performRequestWithBody('POST', url, body);
  }

  async put(url, body = {}) {
    return await this.performRequestWithBody('PUT', url, body);
  }

  async patch(url, body = {}) {
    return await this.performRequestWithBody('PATCH', url, body);
  }

  async delete(url, body = {}) {
    return await this.performRequestWithBody('DELETE', url, body);
  }

  getXhr({ method, url }) {
    const xhr = new XMLHttpRequest();
    xhr.open(method.toUpperCase(), url);
    xhr.setRequestHeader('Authorization', `Bearer ${this.accessToken}`);

    xhr.sendAsync = function (data) {
      return new Promise((resolve, reject) => {
        xhr.onreadystatechange = async function () {
          if (xhr.readyState == 4) {
            resolve(xhr);
          }
        };

        xhr.send(data);
      });
    }

    return xhr;
  }

  async authenticate(credentials) {
    let response;
    if (credentials.loginToken) {
      response = await this.post(`/auth/token/login-token`, credentials);
      if (!response.ok) {
        throw new Error(`Invalid token`);
      }
    }
    else {
      response = await this.post(`/auth/token`, credentials);
    }

    if (response.status !== 200) {
      throw new Error(`Invalid credentials`);
    }

    const data = await response.json();

    // this.accessToken = data.accessToken;
    this.context.store.commit('main/setAccessToken', data.accessToken);
    this.context.store.commit('main/set', { user: data.user });
    this.context.store.commit('main/saveToLocalStorage');

    const postLoginEvent = data.tokenEvent || null;
    this.context.store.commit('footer/set', { justLoggedIn: true });
    this.context.store.commit('main/set', { postLoginEvent });
  }

  isAuthenticated() {
    return this.context.store.getters['main/isAuthenticated'];
  }

  setPostAuthRedirect(redirect) {
    // only allow post-auth redirects for certain routes
    if (!redirect.startsWith('/resources') && redirect !== '/support') {
      return;
    }
    this.context.store.commit('main/setPostAuthRedirect', redirect);
    this.save();
  }

  async finishAuthentication() {
    this.credentialsToResubmit = null;
    this.context.store.commit('login/set', {
      email: '',
      invalidCredentials: false,
    });
    await this.context.store.dispatch('main/finishAuthentication');
  }

  async clearAuthentication(showOffers = true) {
    window.localStorage.setItem('clearAuth', true);
    await this.context.store.commit('main/set', {
      justLoggedOut: true,
    });
    await this.context.store.commit('footer/set', {
      justLoggedOut: true,
    });
    if (showOffers) {
      if (!this.context.store.getters['main/shouldDisplayAds']) {
        this.context.redirect('/donation');
      } else {
        this.context.redirect('/offers?logout');
      }

    }
  }

  clearAuthenticationData() {
    this.context.store.commit('main/clearAuthentication');
    this.context.store.commit('main/setPostAuthRedirect', null);
    this.save();
    deleteCookie('accessToken');
    window.localStorage.removeItem('clearAuth');
  }

  load() {
    this.context.store.commit('main/loadFromLocalStorage');
  }

  save() {
    this.context.store.commit('main/saveToLocalStorage');
  }

  async isEmailRecognized(email) {
    const response = await this.get('/auth/username-validation', {
      username: email,
    });

    if (response.status === 404) {
      return false;
    }

    if (response.status === 200) {
      return true;
    }
  }

  async getRandomProTip() {
    const response = await this.get('/help/random-pro-tip');
    const body = await response.json();
    return body.proTip;
  }

  async sendLoginLink(email, event = null) {
    return await this.post('/auth/token/email-login-link', {
      username: email,
      event,
    });
  }

  async register(data) {

    // this.context.store.commit('sign-up/set', { signUpImage: 'Sign Up Left Panel Background (Captcha)' });
    // this.context.store.dispatch('Sign Up Left Panel Background (Captcha)');
    return await this.post('/auth/sign-up', data);
  }

}

module.exports.Sdk = Sdk;
