
import { randomShuffle } from '@/lib/power-tools';
import { modalBaseGetters } from '@/mixins/ModalStore';

const defaults = {
  imageDataCache: {},
  imageCache: [],
  siteKeys: [],
  siteImages: [],
  selectedText: null,
  selectedImageVariation: null,
  selectedImageColorVariation: null,
  editingVariation: false,
  editingAlt: false,
  editingTime: false,
  editingColors: false,
  editingColorVariation: false,
  deletingId: null,
  paymentModalBackgroundImage: ''
};

export const state = () => Object.assign({}, defaults);

export const getters = Object.assign({}, modalBaseGetters, {
  modalCloseButton(state) {
    return false;
  },
  modalDefaultClose(state) {
    return false;
  },
  getPageSettings: (state) => (imageKey) => {
    if (state.imageDataCache.hasOwnProperty(imageKey)) {
      return state.imageDataCache[imageKey];
    }
    return {}
  },
  getSiteImages(state) {
    return state.siteImages;
  },
  getSiteKeys(state) {
    return state.siteKeys;
  },
  getSelectedText(state) {
    return state.selectedText;
  },
  getSelectedImageVariation(state) {
    return state.selectedImageVariation;
  },
  getSelectedImageColorVariation(state) {
    return state.selectedImageColorVariation;
  },
  getEditingVariation(state) {
    return state.editingVariation;
  },
  getEditingAlt(state) {
    return state.editingAlt;
  },
  getEditingTime(state) {
    return state.editingTime;
  },
  getEditingColors(state) {
    return state.editingColors;
  },
  getEditingColorVariation(state) {
    return state.editingColorVariation;
  },
  getDeletingId(state) {
    return state.deletingId;
  },
  getImage: (state) => (imageName) => {
    return state.siteImages.find(image => image.title === imageName)
  },
  getPaymentModalBackgroundImage(state) {
    return state.paymentModalBackgroundImage;
  }
});

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  reset(state) {
    for (const [key, value] of Object.entries(defaults)) {
      state[key] = value;
    }
  },

  addToCache: (state, { imageKey, data }) => {
    const imageData = state.imageDataCache[imageKey] ? { ...state.imageDataCache[imageKey], ...data } : data
    state.imageDataCache = {
      ...state.imageDataCache,
      [imageKey]: imageData
    }
  },
  setSiteImages: (state, data) => {
    state.siteImages = data
  },
  setSiteKeys: (state, data) => {
    state.siteKeys = data
  },
  setFirstTime: (state, val) => {
    state.firstTime = val
  },

  setText: (state, text) => {
    state.selectedText = text
  },
  setSelectedImageVariation: (state, value) => {
    state.selectedImageVariation = value
  },
  setSelectedImageColorVariation: (state, value) => {
    state.selectedImageColorVariation = value
  },
  setEditingVariation: (state, value) => {
    state.editingVariation = value
  },
  setEditingAlt: (state, value) => {
    state.editingAlt = value
  },
  setEditingTime: (state, value) => {
    state.editingTime = value
  },
  setEditingColors: (state, value) => {
    state.editingColors = value
  },
  setEditingColorVariation: (state, value) => {
    state.editingColorVariation = value
  },
  setDeletingId: (state, value) => {
    state.deletingId = value
  },
  addImageToCache: (state, value) => {
    state.imageCache.push(value)
  },
  setPaymentModalBackgroundImage: (state, value) => {
    state.paymentModalBackgroundImage = value
  },
  async reset(state) {
    state.selectedText = null;
    state.selectedImageVariation = null;
    state.selectedImageColorVariation = null;
    state.editingVariation = false;
    state.editingAlt = false;
    state.editingTime = false;
    state.editingColors = false;
    state.editingColorVariation = false;
  }
};

export const actions = {
  async getImageData({ commit, state }, imageKey) {
    if (state.imageDataCache[imageKey]) {
      return state.imageDataCache[imageKey]
    }
    const imageData = await this.$sdk.get(`/site-images/${imageKey}`)
    const data = await imageData.json()
    commit('addToCache', {
      imageKey,
      data
    })
    data.forEach(d => {
      if (d.variation) {
        d.variation.colors.forEach(color => {
          if (color.css_var_name !== '' && color.color_hex !== '') {
            document.documentElement.style.setProperty(`--${color.css_var_name}`, color.color_hex);
          }
        })
      }
    })
    return data;
  },
  async getSiteData({ commit }) {
    const response = await this.$sdk.get('/site-images')
    const responseJson = await response.json();
    commit('setSiteImages', responseJson.map(image => Object.assign({}, image, { type: 'text' })))
  },
  async getSiteDataKeys({ commit }) {
    const response = await this.$sdk.get('/site-images/all-settings')
    const responseJson = await response.json();
    const { allSiteImages, siteKeys } = responseJson
    for (let index = 0; index < allSiteImages.length; index++) {
      const element = allSiteImages[index];
      if (element.variation !== false) {
        document.documentElement.style.setProperty(this.$imageManager.toVarString(element.title), element.variation.image_locator);
        if (element?.variation?.alt && (element.variation.alt !== '' || element.variation.alt !== null)) {
          document.documentElement.style.setProperty(`${this.$imageManager.toVarString(element.title)}-alt`, element.variation.alt);
        }
        document.documentElement.style.setProperty(this.$imageManager.toVarString(element.title, true), `url('${element.variation.image_locator}')`);
        if (element.variation.colors.length > 0) {
          for (let colorIndex = 0; colorIndex < element.variation.colors.length; colorIndex++) {
            const elementColor = element.variation.colors[colorIndex];
            let imageKey;
            try {
              if (typeof element.image_field_names === "string") {
                imageKey = JSON.parse(element.image_field_names)[elementColor.css_var_name].database_color_name
              } else {
                imageKey = element.image_field_names[elementColor.css_var_name].database_color_name
              }
              document.documentElement.style.setProperty(`--acol-${imageKey}`, elementColor.color_hex, "important");
            } catch { }
          }
        }
      }
    }
    const patternImages = allSiteImages.filter(x => x?.title && x.title.includes("Array Pattern Overlay") && x?.variation).map(x => x.variation.image_locator)

    commit('setSiteImages', allSiteImages.map(image => Object.assign({}, image, { type: 'text' })))
    commit('setSiteKeys', siteKeys)
    commit("resource-grid/set", {
      patternPool: randomShuffle(patternImages)
    }, { root: true })
  },
  async preload({ state, commit }, validImages) {
    const loadImage = (variation) => new Promise((resolve) => {
      const img = new Image();
      img.addEventListener('load', () => {
        commit("addImageToCache", variation.image_locator)
        document.body.appendChild(img);
        resolve(img)
      });
      img.addEventListener('error', (err) => resolve(false));
      img.src = variation.image_locator;
      img.style.display = "none"
    });
    if (validImages && Array.isArray(validImages) && validImages.length > 0) {
      const promises = validImages.filter(img => img?.variation?.image_locator && !state.imageCache.includes(img.variation.image_locator))
        .map((img, i) => {
          return loadImage(img.variation)
        })
      await Promise.all(promises)
    }

    return true;
  },
  async getSiteImage({ state }, imageName) {
    return state.siteImages.find(image => image.title === imageName)
  }
};
