
import { detectAnyAdblocker } from 'just-detect-adblock';

export default {
  data() {
    return {
    };
  },
  components: {
  },
  computed: {
  },
  methods: {
  },
  async mounted() {
    if (this.$store.state.main.detectedAdBlock === null) {
      const hasAdblock = await detectAnyAdblocker();
      console.log('hasAdblock: ', hasAdblock);
      this.$store.commit('main/set', {
        detectedAdBlock: hasAdblock,
      });
    }
    document.body.classList.add('loaded')
  },
};
