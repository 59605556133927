
import Vue from "vue"
import globalUtilitiesMixin from '@/mixins/GlobalUtilitiesMixin';

if (!Vue.__custom_utilities__) {
  Vue.__custom_utilities__ = true
  Vue.mixin(globalUtilitiesMixin);
}

export default (context, inject) => {
  inject('getRef', function(name) {
    return this.$refs[name] || {};
  });
};
