
export default async function (context) {
  const { $sdk, route, redirect, from, store } = context;

  if (store.state.main.colors.length === 0) {
    await Promise.all([
      store.dispatch('main/loadTexts'),
      store.dispatch('main/loadColors'),
    ]);
  }

  return true;
}
