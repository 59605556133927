export const AdEditSection = () => import('../../components/AdEditSection.vue' /* webpackChunkName: "components/ad-edit-section" */).then(c => wrapFunctional(c.default || c))
export const AdminFooter = () => import('../../components/AdminFooter.vue' /* webpackChunkName: "components/admin-footer" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const ButtonProcessing = () => import('../../components/ButtonProcessing.vue' /* webpackChunkName: "components/button-processing" */).then(c => wrapFunctional(c.default || c))
export const Captcha = () => import('../../components/Captcha.vue' /* webpackChunkName: "components/captcha" */).then(c => wrapFunctional(c.default || c))
export const CharsRemaining = () => import('../../components/CharsRemaining.vue' /* webpackChunkName: "components/chars-remaining" */).then(c => wrapFunctional(c.default || c))
export const CheckBox = () => import('../../components/CheckBox.vue' /* webpackChunkName: "components/check-box" */).then(c => wrapFunctional(c.default || c))
export const DetailLine = () => import('../../components/DetailLine.vue' /* webpackChunkName: "components/detail-line" */).then(c => wrapFunctional(c.default || c))
export const DottedLine = () => import('../../components/DottedLine.vue' /* webpackChunkName: "components/dotted-line" */).then(c => wrapFunctional(c.default || c))
export const DownloadInfo = () => import('../../components/DownloadInfo.vue' /* webpackChunkName: "components/download-info" */).then(c => wrapFunctional(c.default || c))
export const FileInput = () => import('../../components/FileInput.vue' /* webpackChunkName: "components/file-input" */).then(c => wrapFunctional(c.default || c))
export const FileUpload = () => import('../../components/FileUpload.vue' /* webpackChunkName: "components/file-upload" */).then(c => wrapFunctional(c.default || c))
export const FooterMessages = () => import('../../components/FooterMessages.vue' /* webpackChunkName: "components/footer-messages" */).then(c => wrapFunctional(c.default || c))
export const HelpMessageHistory = () => import('../../components/HelpMessageHistory.vue' /* webpackChunkName: "components/help-message-history" */).then(c => wrapFunctional(c.default || c))
export const InfoLineInput = () => import('../../components/InfoLineInput.vue' /* webpackChunkName: "components/info-line-input" */).then(c => wrapFunctional(c.default || c))
export const LoadableComponent = () => import('../../components/LoadableComponent.vue' /* webpackChunkName: "components/loadable-component" */).then(c => wrapFunctional(c.default || c))
export const LogOutButton = () => import('../../components/LogOutButton.vue' /* webpackChunkName: "components/log-out-button" */).then(c => wrapFunctional(c.default || c))
export const LoginPattern = () => import('../../components/LoginPattern.vue' /* webpackChunkName: "components/login-pattern" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const ModalCloseButton = () => import('../../components/ModalCloseButton.vue' /* webpackChunkName: "components/modal-close-button" */).then(c => wrapFunctional(c.default || c))
export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Odometer = () => import('../../components/Odometer.vue' /* webpackChunkName: "components/odometer" */).then(c => wrapFunctional(c.default || c))
export const PaymentModalContent = () => import('../../components/PaymentModalContent.vue' /* webpackChunkName: "components/payment-modal-content" */).then(c => wrapFunctional(c.default || c))
export const PaymentOrderSummaryAdvanced = () => import('../../components/PaymentOrderSummaryAdvanced.vue' /* webpackChunkName: "components/payment-order-summary-advanced" */).then(c => wrapFunctional(c.default || c))
export const PaymentOrderSummaryClassic = () => import('../../components/PaymentOrderSummaryClassic.vue' /* webpackChunkName: "components/payment-order-summary-classic" */).then(c => wrapFunctional(c.default || c))
export const ReadMore = () => import('../../components/ReadMore.vue' /* webpackChunkName: "components/read-more" */).then(c => wrapFunctional(c.default || c))
export const ResourceCell = () => import('../../components/ResourceCell.vue' /* webpackChunkName: "components/resource-cell" */).then(c => wrapFunctional(c.default || c))
export const RoundedCornerButton = () => import('../../components/RoundedCornerButton.vue' /* webpackChunkName: "components/rounded-corner-button" */).then(c => wrapFunctional(c.default || c))
export const RoundedCornerButtonWrapper = () => import('../../components/RoundedCornerButtonWrapper.vue' /* webpackChunkName: "components/rounded-corner-button-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Scroller = () => import('../../components/Scroller.vue' /* webpackChunkName: "components/scroller" */).then(c => wrapFunctional(c.default || c))
export const ScrollerItem = () => import('../../components/ScrollerItem.vue' /* webpackChunkName: "components/scroller-item" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SettingsList = () => import('../../components/SettingsList.vue' /* webpackChunkName: "components/settings-list" */).then(c => wrapFunctional(c.default || c))
export const SidebarFooter = () => import('../../components/SidebarFooter.vue' /* webpackChunkName: "components/sidebar-footer" */).then(c => wrapFunctional(c.default || c))
export const TeacherFooter = () => import('../../components/TeacherFooter.vue' /* webpackChunkName: "components/teacher-footer" */).then(c => wrapFunctional(c.default || c))
export const TopDownloadedResources = () => import('../../components/TopDownloadedResources.vue' /* webpackChunkName: "components/top-downloaded-resources" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const UpdatedMessage = () => import('../../components/UpdatedMessage.vue' /* webpackChunkName: "components/updated-message" */).then(c => wrapFunctional(c.default || c))
export const SpeechBubbleContainer = () => import('../../components/SpeechBubble/Container.vue' /* webpackChunkName: "components/speech-bubble-container" */).then(c => wrapFunctional(c.default || c))
export const SpeechBubbleControlButtons = () => import('../../components/SpeechBubble/ControlButtons.vue' /* webpackChunkName: "components/speech-bubble-control-buttons" */).then(c => wrapFunctional(c.default || c))
export const SpeechBubbleFileSection = () => import('../../components/SpeechBubble/FileSection.vue' /* webpackChunkName: "components/speech-bubble-file-section" */).then(c => wrapFunctional(c.default || c))
export const SpeechBubbleTextContent = () => import('../../components/SpeechBubble/TextContent.vue' /* webpackChunkName: "components/speech-bubble-text-content" */).then(c => wrapFunctional(c.default || c))
export const AdsPremiumContent = () => import('../../components/ads/premiumContent.vue' /* webpackChunkName: "components/ads-premium-content" */).then(c => wrapFunctional(c.default || c))
export const AdsTypeFour = () => import('../../components/ads/typeFour.vue' /* webpackChunkName: "components/ads-type-four" */).then(c => wrapFunctional(c.default || c))
export const AdsTypeThree = () => import('../../components/ads/typeThree.vue' /* webpackChunkName: "components/ads-type-three" */).then(c => wrapFunctional(c.default || c))
export const CardsTextLeftButtonsRight = () => import('../../components/cards/TextLeftButtonsRight.vue' /* webpackChunkName: "components/cards-text-left-buttons-right" */).then(c => wrapFunctional(c.default || c))
export const FooterLink = () => import('../../components/footer-message/FooterLink.vue' /* webpackChunkName: "components/footer-link" */).then(c => wrapFunctional(c.default || c))
export const FooterMessageLink = () => import('../../components/footer-message/FooterMessageLink.vue' /* webpackChunkName: "components/footer-message-link" */).then(c => wrapFunctional(c.default || c))
export const FooterMessageSingle = () => import('../../components/footer-message/FooterMessageSingle.vue' /* webpackChunkName: "components/footer-message-single" */).then(c => wrapFunctional(c.default || c))
export const FooterMessageStrong = () => import('../../components/footer-message/FooterMessageStrong.vue' /* webpackChunkName: "components/footer-message-strong" */).then(c => wrapFunctional(c.default || c))
export const FooterMessageWithInfo = () => import('../../components/footer-message/FooterMessageWithInfo.vue' /* webpackChunkName: "components/footer-message-with-info" */).then(c => wrapFunctional(c.default || c))
export const FooterSpan = () => import('../../components/footer-message/FooterSpan.vue' /* webpackChunkName: "components/footer-span" */).then(c => wrapFunctional(c.default || c))
export const ResourcesOwnershipCard = () => import('../../components/resources/OwnershipCard.vue' /* webpackChunkName: "components/resources-ownership-card" */).then(c => wrapFunctional(c.default || c))
export const ResourcesResourceSection = () => import('../../components/resources/ResourceSection.vue' /* webpackChunkName: "components/resources-resource-section" */).then(c => wrapFunctional(c.default || c))
export const ResourcesSaleCard = () => import('../../components/resources/SaleCard.vue' /* webpackChunkName: "components/resources-sale-card" */).then(c => wrapFunctional(c.default || c))
export const SettingsColors = () => import('../../components/settings/Colors.vue' /* webpackChunkName: "components/settings-colors" */).then(c => wrapFunctional(c.default || c))
export const SettingsFile = () => import('../../components/settings/File.vue' /* webpackChunkName: "components/settings-file" */).then(c => wrapFunctional(c.default || c))
export const SettingsPassword = () => import('../../components/settings/Password.vue' /* webpackChunkName: "components/settings-password" */).then(c => wrapFunctional(c.default || c))
export const SettingsPatternInput = () => import('../../components/settings/PatternInput.vue' /* webpackChunkName: "components/settings-pattern-input" */).then(c => wrapFunctional(c.default || c))
export const SettingsPricing = () => import('../../components/settings/Pricing.vue' /* webpackChunkName: "components/settings-pricing" */).then(c => wrapFunctional(c.default || c))
export const SettingsSecurityPattern = () => import('../../components/settings/SecurityPattern.vue' /* webpackChunkName: "components/settings-security-pattern" */).then(c => wrapFunctional(c.default || c))
export const SettingsSettingValue = () => import('../../components/settings/SettingValue.vue' /* webpackChunkName: "components/settings-setting-value" */).then(c => wrapFunctional(c.default || c))
export const SettingsSettingValueMixin = () => import('../../components/settings/SettingValueMixin.vue' /* webpackChunkName: "components/settings-setting-value-mixin" */).then(c => wrapFunctional(c.default || c))
export const SettingsSiteImages = () => import('../../components/settings/SiteImages.vue' /* webpackChunkName: "components/settings-site-images" */).then(c => wrapFunctional(c.default || c))
export const SettingsTexts = () => import('../../components/settings/Texts.vue' /* webpackChunkName: "components/settings-texts" */).then(c => wrapFunctional(c.default || c))
export const SettingsUserAvatar = () => import('../../components/settings/UserAvatar.vue' /* webpackChunkName: "components/settings-user-avatar" */).then(c => wrapFunctional(c.default || c))
export const UserModal = () => import('../../components/user-modal/UserModal.vue' /* webpackChunkName: "components/user-modal" */).then(c => wrapFunctional(c.default || c))
export const UserModalContent = () => import('../../components/user-modal/UserModalContent.vue' /* webpackChunkName: "components/user-modal-content" */).then(c => wrapFunctional(c.default || c))
export const UserModalNav = () => import('../../components/user-modal/UserModalNav.vue' /* webpackChunkName: "components/user-modal-nav" */).then(c => wrapFunctional(c.default || c))
export const UserSummary = () => import('../../components/user-modal/UserSummary.vue' /* webpackChunkName: "components/user-summary" */).then(c => wrapFunctional(c.default || c))
export const UserSummaryUrl = () => import('../../components/user-modal/UserSummaryUrl.vue' /* webpackChunkName: "components/user-summary-url" */).then(c => wrapFunctional(c.default || c))
export const AdminAccountingCalendar = () => import('../../components/admin/accounting/AccountingCalendar.vue' /* webpackChunkName: "components/admin-accounting-calendar" */).then(c => wrapFunctional(c.default || c))
export const AdminAccountingUserListContainer = () => import('../../components/admin/accounting/UserListContainer.vue' /* webpackChunkName: "components/admin-accounting-user-list-container" */).then(c => wrapFunctional(c.default || c))
export const AdminAccountingUserListContainerCell = () => import('../../components/admin/accounting/UserListContainerCell.vue' /* webpackChunkName: "components/admin-accounting-user-list-container-cell" */).then(c => wrapFunctional(c.default || c))
export const AdminUsersUserListCell = () => import('../../components/admin/users/UserListCell.vue' /* webpackChunkName: "components/admin-users-user-list-cell" */).then(c => wrapFunctional(c.default || c))
export const ResourcesPreviewSectionedList = () => import('../../components/resources/preview/SectionedList.vue' /* webpackChunkName: "components/resources-preview-sectioned-list" */).then(c => wrapFunctional(c.default || c))
export const SettingsSiteImagesImageVariationDetailLineEmpty = () => import('../../components/settings/siteImages/ImageVariationDetailLineEmpty.vue' /* webpackChunkName: "components/settings-site-images-image-variation-detail-line-empty" */).then(c => wrapFunctional(c.default || c))
export const SettingsSiteImagesImageVariationDetailLineWithUpload = () => import('../../components/settings/siteImages/ImageVariationDetailLineWithUpload.vue' /* webpackChunkName: "components/settings-site-images-image-variation-detail-line-with-upload" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
