

const donation = {
    images: [
        "Donation Splash",
    ],
    children: {
        completed: {
            images: [
                'Checkout Donation Splash Payment Background (Thank You)'
            ]
        },
        payment: {
            images: [
                'Checkout Donation Splash Payment Background'
            ]
        }
    }
}

module.exports = donation;