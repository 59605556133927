
import { orderFormBaseState, orderFormBaseGetters } from '@/mixins/OrderFormStore';
import { modalBaseGetters } from '@/mixins/ModalStore';
import path from 'path';

const defaults = Object.assign({}, orderFormBaseState, {
  paymentSource: 'credit-card',

  tipAmounts: [1, 2, 3, 4, 5, 10, 25, 50, 100],
  tipIndex: 4,
  tipAmount: -1,
  tipNote: '',
  recipientId: null,
  recipientUsername: null,
  recipientName: '',
  valuesToSave: ['tipIndex', 'tipNote', 'recipientId', 'recipientUsername', 'recipientName', 'tipAmount',],

  localStorageKey: 'tipInProgress',
  searchText: '',
  portfolioUser: {},
  followUserError: false,
  portfolioImageOverride: '',
  systemAvatars: [],
  resources: [],
  userLoaded: false,
  fromRoute: "/",
});

export const state = () => Object.assign({}, defaults);

export const getters = Object.assign({}, modalBaseGetters, orderFormBaseGetters, {

  modalCloseEnabled(state, getters, externalState, externalGetters) {
    if ($nuxt.$route.name.startsWith('index-profile-tip-summary')) {
      return true;
    }
    return !externalState.main.user?.is_deactivated;
  },

  modalCloseWarning() {
    if (
      $nuxt.$route.name.startsWith('index-profile-id-tip')
      || $nuxt.$route.name.startsWith('index-profile-id-message')
      || $nuxt.$route.name.startsWith('index-profile-tip-summary')
    ) {
      return true;
    }
    return false;
  },

  amount(state) {
    if (state.tipAmount !== -1) return state.tipAmount;

    return state.tipAmounts[state.tipIndex];
  },

  systemAvatarName(state, getters, rootState) {
    let user = state.portfolioUser;
    if (!user.avatar_locator) {
      user = rootState.main.user;
    }
    const avatar_locator = user.avatar_locator;
    const name = path.basename(avatar_locator);
    const dir = path.dirname(avatar_locator);
    if (!dir.includes('image-manager')) return null;
    return name;
  },

});

export const mutations = {

  clear(state) {
    for (const key of Object.entries(state.valuesToSave)) {
      state[key] = defaults[key];
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  save(state) {
    const values = {};
    for (const value of state.valuesToSave) {
      values[value] = state[value];
    }
    const json = JSON.stringify(values);
    window.localStorage.setItem(state.localStorageKey, json);
  },

  load(state) {
    const json = window.localStorage.getItem(state.localStorageKey);
    const values = JSON.parse(json);
    for (const [key, value] of Object.entries(values)) {
      state[key] = value;
    }
  },

  updateSearchText(state, searchText) {
    state.searchText = searchText;
  },

};

export const actions = {

  blockUntilUserLoaded({ state }) {
    return new Promise((resolve) => {
      const interval = window.setInterval(() => {
        if (state.userLoaded) {
          window.clearInterval(interval);
          return resolve();
        }
      }, 50);
    });
  },

  async loadPortfolio({ commit }, userId) {
    commit('set', { userLoaded: false });
    const response = await this.$sdk.get(`/users/${userId}/summary`);
    if (response.status === 404) {
      commit('set', {
        portfolioUser: {},
        userLoaded: false,
      });
      window.location.href = '/'
      return;
    } else {
      const responseJson = await response.json();
      commit('set', {
        portfolioUser: responseJson.user,
        userLoaded: true,
      });
    }
  },

  async loadResources({ state, commit }) {
    const response = await this.$sdk.get(`/users/${state.portfolioUser.id}/resources`);
    const responseJson = await response.json();

    commit('set', {
      resources: responseJson.resources,
    });
  },

  async followUser({ state, commit }) {
    const response = await this.$sdk.put(`/users/following/${state.portfolioUser.id}`);
    if (response.ok) {
      commit('set', {
        portfolioUser: Object.assign({}, state.portfolioUser, {
          isFollowedByMe: true,
        })
      });
      return true;
    } else {
      commit('set', {
        followUserError: true,
      });
      return false;
    }
  },

  async unfollowUser({ state, commit }) {
    const response = await this.$sdk.delete(`/users/following/${state.portfolioUser.id}`);
    if (response.ok) {
      commit('set', {
        portfolioUser: Object.assign({}, state.portfolioUser, {
          isFollowedByMe: false,
        })
      });
    }
  },

  async loadSystemAvatars({ state, commit }) {
    const response = await this.$sdk.get(`/images/system-avatars`);
    const respJson = await response.json();
    if (response.ok) {
      commit('set', {
        systemAvatars: respJson.systemAvatars,
      });
    }
  },

};
