
import { modalBaseGetters } from '@/mixins/ModalStore';

export const state = () => ({
  list: [],
  index: 0,
  itemSelectedIndex: 0,
  changedTosEntries: [],
  feedback: '',
  editsMade: false,
  uploadingImage: false,
  uploadingImageProgress: 0,
  uploadingImageFile: null,
  uploadingVideo: false,
  uploadingVideoProgress: 0,
  uploadingVideoFile: null,

  fromSignUp: false,

  entriesFetched: false,
  isVideoShowing: false,

  itemDefaults: {
    type: 'article',
    id: null,
    short_title: '',
    long_title: '',
    body: '',
    image: '',
    image_locator: '',
    accent_color: '',
    image_alt_text: '',
    order_index: null,
    update: false,
    deleteImage: false,
    deleteVideo: false,
    name: '',

    relatedVideoIndex: null,
    relatedImageIndex: null,

    imageFile: {
      name: '',
      size: 0,
    },
    imageFileUploading: false,
    imageFileUploadProgress: 0,

    videoFile: {
      name: '',
      size: 0,
    },
    videoFileUploading: false,
    videoFileUploadProgress: 0,
  },

  previousItem: {},
  selectedItem: {},

  storedPath: '',
})

export const getters = Object.assign({}, modalBaseGetters, {

  modalCloseEnabled(state) {
    return state.changedTosEntries.length === 0;
  },

  modalCloseWarning(state) {
    return (
      state.feedback.length > 0
      || state.editsMade
      || ($nuxt.$route.params.id || '').startsWith('new-')
    );
  },

  getHelpCenterList: state => state.list,

  entriesById(state) {
    const entries = {};
    for (const entry of state.list) {
      entries[entry.id] = entry;
    }
    return entries;
  },

  entriesByName(state) {
    const entries = {};
    for (const entry of state.list) {
      entries[entry.name] = entry;
    }
    return entries;
  },

  changedTosEntriesIdsSet(state) {
    return new Set(state.changedTosEntries.map(entry => entry.id));
  },

  entriesSorted(state, getters) {
    const placementForType = {
      article: 0,
      image: 1,
      video: 2,
    };

    return state.list.slice().sort(function (a, b) {
      let aIndex = a.order_index + placementForType[a.type] * 1000;
      let bIndex = b.order_index + placementForType[b.type] * 1000;

      if (a.type === 'article' && getters.changedTosEntriesIdsSet.has(a.id)) { aIndex -= 1000; }
      if (a.type === 'article' && getters.changedTosEntriesIdsSet.has(b.id)) { bIndex -= 1000; }
      return aIndex - bIndex;
    })
  },
});

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  setForSelectedItem(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state.selectedItem[key] = val;
    }
  },

  SET_HELP_CENTER_LIST: (state, value) => { state.list = value },
  UPDATE_ITEM_TO_LIST: (state, value) => { state.list.splice(value.index, 1, value) },
  DELETE_ITEM_FROM_LIST: (state, value) => { state.list.splice(value.index, 1) },
  SET_CURRENT_INDEX: (state, value) => { state.index = value },
  SORT_LIST: (state) => {
    state.list = state.list.sort(function (a, b) {
      return a.order_index - b.order_index;
    })
  },

  setFile(state, { type, file }) {
    const typeCapitalized = type[0].toUpperCase() + type.slice(1);
    state.selectedItem = Object.assign({}, state.selectedItem, {
      // file to be uploaded
      [type]: file,
      // local file reference
      [`${type}File`]: {
        name: file.name,
        size: file.size,
      },
      [`delete${typeCapitalized}`]: false,
    });
    if (type === 'image') {

      state.uploadingImage = true;
      state.uploadingImageProgress = 0;
      state.uploadingImageFile = file;
    }

    if (type === 'video') {
      state.uploadingVideo = true;
      state.uploadingVideoProgress = 0;
      state.uploadingVideoFile = file;
    }

    for (let index = 0; index < 100; index++) {
      if (index === 99) {
        if (type === 'video') {

          state.uploadingVideo = false;
          state.uploadingVideoProgress = 0;
          state.uploadingVideoFile = null;

        } else {
          state.uploadingImage = false;
          state.uploadingImageProgress = 0;
          state.uploadingImageFile = null;

        }
      }
      if (type === 'video') {

        state.uploadingVideoProgress = (index + 1) / 100;

      } else {
        state.uploadingImageProgress = (index + 1) / 100;

      }
    }
  },

  deleteFile(state, type) {
    const typeCapitalized = type[0].toUpperCase() + type.slice(1);

    state.selectedItem = Object.assign({}, state.selectedItem, {
      [type]: null,
      // imageFile: {
      [`${type}File`]: {
        name: '',
        size: 0,
      },
      [`delete${typeCapitalized}`]: true,
      [`${type}FileUploading`]: false,
    });

    if (type === 'video') {

      state.uploadingVideo = false;
      state.uploadingVideoProgress = 0;
      state.uploadingVideoFile = null;

    } else {
      state.uploadingImage = false;
      state.uploadingImageProgress = 0;
      state.uploadingImageFile = null;

    }
  },

}

export const actions = {
  async fetchList({ state, getters, commit }, { id = null, all = false }) {
    try {
      const response = await this.$sdk.get('/help/' + (all ? '' : 'articles'));
      const body = await response.json();

      // const dummyEntries = [];
      // for (let i = 0; i < 20; i++) {
      //   dummyEntries.push({
      //     id: i,
      //     short_title: `entry #${i}`,
      //     long_title: `entry #${i} loooong`,
      //     order_index: i,
      //     body: '',
      //   });
      // }
      // await commit('SET_HELP_CENTER_LIST', dummyEntries)

      await commit('SET_HELP_CENTER_LIST', body.entries)

      const itemSelectedIndex = getters.entriesSorted.findIndex(entry => entry.id === id);
      commit('set', {
        itemSelectedIndex,
        entriesFetched: true,
      });

    } catch (error) {
      console.error(error)
    }
  },

  blockUntilEntriesFetched({ state }) {
    return new Promise((resolve, reject) => {
      const interval = window.setInterval(() => {
        if (state.entriesFetched) {
          window.clearInterval(interval);
          resolve();
        }
      }, 10);
    });
  },

  async putMedia({ state, commit }, type) {
    const entryId = state.selectedItem.id;
    const xhr = this.$sdk.getXhr({
      method: 'put',
      url: `/api/help/${entryId}/media`,
    });

    commit('setForSelectedItem', {
      [`${type}FileUploading`]: true,
      [`${type}FileUploadProgress`]: 0,
    });

    xhr.upload.addEventListener(
      "progress",
      function (event) {
        if (!event.lengthComputable) {
          return;
        }
        const progress = event.loaded / event.total;
        commit('setForSelectedItem', { [`${type}FileUploadProgress`]: progress });
      },
      false
    );

    const formData = new FormData();
    formData.append(type, state.selectedItem[type]);

    const response = await xhr.sendAsync(formData);
    const responseJson = JSON.parse(response.responseText);

    commit('setForSelectedItem', {
      [`${type}FileUploading`]: false,
      [`${type}FileUploadProgress`]: 0,
    });
  },

  // async storeItem({ commit, dispatch }, item) {
  async storeItem({ state, commit, dispatch }) {
    try {
      const item = state.selectedItem;

      // const formData = new FormData();
      // for (const [key, value] of Object.entries(item)) {
      //   formData.append(key, value);
      // }
      // const response = await this.$sdk.post('/help', formData);

      const response = await this.$sdk.post('/help', item)
      const responseJson = await response.json();

      if (!response.ok) {
        throw new Error(response);
      }

      const id = responseJson.item.id;
      commit('setForSelectedItem', { id });

      const promises = [];
      if (item.image) { promises.push(dispatch('putMedia', 'image')); }
      if (item.video) { promises.push(dispatch('putMedia', 'video')); }
      await Promise.all(promises);

      await dispatch('fetchList', { id, all: true });
      await dispatch('reloadSelectedEntry', id);
      return responseJson.item;
    }
    catch (exceptions) {
      window.alert(`Could not save entry.`);
    }
  },

  async submitFeedback({ commit }, item) {
    try {
      await this.$sdk.post('/help/feedback', { feedback: item })
    } catch (error) {
      console.error(error)
    }
  },

  // async updateItem({ commit, dispatch }, item) {
  async updateItem({ state, commit, dispatch }) {
    const item = state.selectedItem;
    try {
      // await commit('SET_CURRENT_INDEX', item.index)

      // const formData = new FormData();
      // for (const [key, value] of Object.entries(item)) {
      //   formData.append(key, value);
      // }
      // const response = await this.$sdk.put(`/help/${item.id}`, formData);

      const response = await this.$sdk.put(`/help/${item.id}`, item)
      const responseJson = await response.json();
      // const data = await response.json().item
      // data.index = item.index
      // item.image = `/${item.image_locator}`
      // item.image_locator = `/${item.image_locator}`

      // await commit('UPDATE_ITEM_TO_LIST', data)
      // await commit('SORT_LIST')

      const promises = [];
      if (item.image) { promises.push(dispatch('putMedia', 'image')); }
      if (item.video) { promises.push(dispatch('putMedia', 'video')); }
      await Promise.all(promises);

      const id = responseJson.item.id;
      await dispatch('fetchList', { id, all: true });
      await dispatch('reloadSelectedEntry');

    } catch (error) {
      console.error(error)
    }
  },

  async setFile({ commit }, { file, type }) {
    commit('setFile', { file, type });
  },

  async deleteFile({ commit }, type) {
    // TODO send file deletion request immediately IF the file isn't just a
    // local file
    commit('deleteFile', type);
  },

  reloadSelectedEntry({ state, dispatch }, id) {
    dispatch('loadSelectedItemDetails', { id: id || state.selectedItem.id });
  },

  flushSelectedItem({ state, commit, getters, dispatch }) {
    commit('set', { previousItem: Object.assign({}, state.selectedItem) });
  },

  loadSelectedItemDetails({ state, commit, getters, dispatch }, { id }) {
    const newIds = new Set(['new-article', 'new-image', 'new-video']);
    if (newIds.has(id)) {
      const type = id.split('-')[1];
      commit('set', {
        editsMade: false,
        selectedItem: Object.assign({}, state.itemDefaults, { type }),
      });
      return;
    }

    let entry;
    entry = getters.entriesByName[id];
    commit('set', {
      editsMade: false,
      selectedItem: Object.assign({}, entry, {
        imageFile: {
          name: entry?.image_name || '',
          size: entry?.image_size_bytes || 0,
        },
        imageFileUploading: false,
        imageFileUploadProgress: 0,

        relatedImageIndex: entry?.image_entry?.order_index,

        videoFile: {
          name: entry?.video_name || '',
          size: entry?.video_size_bytes || 0,
        },
        videoFileUploading: false,
        videoFileUploadProgress: 0,

        relatedVideoIndex: entry?.video_entry?.order_index,
      }),
    });
  },

  async deleteItem({ state, getters, commit, dispatch }, item) {
    try {
      const response = await this.$sdk.delete(`/help/${item.id}`)

      // if (getters.entriesSorted.length < 2) {
      //   await dispatch('fetchList', { all: true });
      //   $nuxt.$router.push(`/helpbook/new`);
      //   return;
      // }

      // let newFirstEntryId;
      // for (const entry of getters.entriesSorted) {
      //   if (entry.id !== item.id) {
      //     newFirstEntryId = entry.id;
      //     break;
      //   }
      // }
      // await dispatch('fetchList', { id: newFirstEntryId, all: true });
      // $nuxt.$router.push(`/helpbook/${newFirstEntryId}`);

      // await dispatch('fetchList', { id: null, all: true });
      // $nuxt.$router.push(`/helpbook/manage`);
    } catch (error) {
      console.error(error)
    }
  },

  async updateChangedTos(context) {
    const { state, commit, rootState, rootGetters } = context;

    if (!rootGetters['main/isAuthenticated']) {
      return;
    }

    const response = await this.$sdk.get(`/help/tos-changed`);
    if (!response?.ok) {
      return;
    }
    const responseJson = await response.json();
    commit('set', {
      changedTosEntries: responseJson.entries,
    });
  },

  async acceptTos({ dispatch }) {
    await this.$sdk.put('/help/tos-agreement');
    await dispatch('updateChangedTos');
    $nuxt.$router.push('/terms-accepted');
  },

}
