
import { getImageBase64, getImageObject } from "@/lib/power-tools";

import { orderFormBaseState } from '@/mixins/OrderFormStore';
import { modalBaseGetters } from '@/mixins/ModalStore';

export const state = () => (Object.assign({}, orderFormBaseState, {
  ads: [],

  adRouteId: 'new',
  // newAdId: null,
  // newAdId: 7,

  uploadingImage: false,
  uploadingImageProgress: 0,
  uploadingImageFile: null,

  uploadingBusinessImage: false,
  uploadingBusinessImageProgress: 0,
  uploadingBusinessImageFile: null,

  adsLoaded: false,

  adType: '1',

  selectedAd: {},
  defaultAdDetails: {
    ad_type: '1',
    css_override: null,
    discount_code: '',
    title: '',
    subtitle: '',
    subject: '',
    statement: '',
    business_name: '',
    business_image: null,
    click_url: '',
    image_url: '',
    image_name: '',
    spend_per_day: 500,
    run_days_total: 7,
    gradesArray: [],
    isAdminAd: false,
  },

  estimates: {
    impressions: 0,
    clicks: 0,
    share: 0,
  },

  error: null,

  // confirmingDeletion: false,
  deletionCancelReturnUrl: null,
}));

export const allSteps = [
  { step: 1, label: 'Image', name: 'image_name', to: 'image', ariaLabel: 'alt-ad-creation-image' },
  { step: 2, label: 'URL', name: 'click_url', to: 'url', ariaLabel: 'alt-ad-creation-url' },

  { step: 3, label: 'Title', name: 'title', to: 'title', ariaLabel: 'alt-ad-creation-title' },
  { step: 3, label: 'Discount Code', name: 'discount_code', to: 'discount-code', ariaLabel: '' },

  { step: 4, label: 'Description', name: 'description', to: 'description', ariaLabel: 'alt-ad-creation-description' },
  { step: 4, label: 'Subtitle', name: 'subtitle', to: 'subtitle', ariaLabel: '' },
  { step: 4, label: 'Title/Description', name: 'title_description', to: 'title-description', ariaLabel: '' },

  { step: 5, label: 'Button', name: 'button', to: 'button', ariaLabel: 'alt-ad-creation-button' },
  { step: 5, label: 'Subject Area', name: 'subject', to: 'subject', ariaLabel: '' },
  { step: 5, label: 'Identity', name: 'identity', to: 'identity', ariaLabel: '' },

  { step: 6, label: 'Statement', name: 'statement', to: 'statement', ariaLabel: 'alt-ad-creation-statement' },
  { step: 7, label: 'Targeting', name: 'targeting', to: 'targeting', ariaLabel: 'alt-ad-creation-targeting' },

  { step: 8, label: 'Ad Spend', name: 'ad_spend', to: 'ad-spend', ariaLabel: 'alt-ad-creation-ad-spend' },
  { step: 8, label: 'Styling', name: 'styling', to: 'styling', ariaLabel: 'alt-ad-creation-styling' },

  { step: 9, label: 'Payment Info', name: 'payment', to: 'payment', ariaLabel: 'alt-ad-creation-payment-info' },
  { step: 9, label: 'Ad Code', name: 'code', to: 'code', ariaLabel: 'alt-ad-creation-ad-code' },

  { step: 10, label: 'Status', name: 'status', to: 'status', ariaLabel: 'alt-ad-creation-status' },
  { step: 10, label: 'Review', name: 'review', to: 'review', ariaLabel: 'alt-ad-creation-review' },

  { step: 99, label: 'Delete', name: 'delete', to: 'delete', ariaLabel: '' },
];

function getDefaultStatementForAdType(type) {
  if (type === '2') {
    return "This ad was paid for by a member of the Learningful community.";
  }
  if (type === '3') {
    return "Paid for by a member of the Learningful community.";
  }
  if (type === '4') {
    return "Paid for by a member of Learningful.";
  }
  return "This promotion was paid for by a member of the Learningful community.";
}

export const getters = Object.assign({}, modalBaseGetters, {

  modalCloseWarning() {
    return $nuxt.$route.name.startsWith('index-advertising-id-edit') || $nuxt.$route.name.startsWith('index-advertising-id-submitted');
  },

  adsById(state) {
    const adsById = {};
    for (const ad of state.ads) {
      adsById[ad.id] = ad;
    }
    return adsById;
  },

  stepList(state, getters, externalState, externalGetters) {
    let stepList = allSteps.slice();
    // delete #3b
    stepList.splice(3, 1);
    // delete #4b
    stepList.splice(4, 1);
    // delete #4c
    stepList.splice(4, 1);
    // delete #5b
    stepList.splice(5, 1);
    // delete #5c
    stepList.splice(5, 1);
    // delete #8b
    stepList.splice(8, 1);
    // delete #9b
    stepList.splice(9, 1);
    // delete #10b
    stepList.splice(10, 1);
    // remove "delete" step
    stepList = stepList.slice(0, 10);

    for (const step of stepList) {
      step.label = externalState.main.texts['ad creation--menu title step ' + step.step.toString()];
    }

    if (state.adType === '2') {
      stepList[3] = { step: 4, label: externalState.main.texts['ad creation--menu title step 4 ad 2'], name: 'subtitle', to: 'subtitle' };
      stepList[4] = { step: 5, label: externalState.main.texts['ad creation--menu title step 5 ad 2'], name: 'subject', to: 'subject' };
    }

    // if (state.adType === '5') {
    //   stepList[2] = { step: 3, label: 'Discount Code',     name: 'discount_code',     to: 'discount-code' };
    //   stepList[3] = { step: 4, label: 'Title/Description', name: 'title_description', to: 'title-description' };
    //   stepList[4] = { step: 5, label: 'Identity',          name: 'identity',          to: 'identity' };
    // }

    if (externalGetters['main/isAdmin']) {
      stepList[7] = { step: 8, label: 'Styling', name: 'styling', to: 'styling' };
      stepList[8] = { step: 9, label: 'Ad Code', to: 'code' };
      stepList[9] = { step: 10, label: 'Review', name: 'review', to: 'review' };
    }

    const requiredFields = getters.requiredFields;
    const stepMapper = (field) => {
      const idx = stepList.findIndex(step => step?.name === field);
      if (idx !== -1) return idx;
      if (field === 'gradesArray') return 6;
      if (field === 'run_days_total' || field === 'spend_per_day') return 7;
    }


    for (const field of requiredFields) {
      if (['run_days_total', 'spend_per_day'].includes(field)
        && externalGetters['main/isAdmin']) continue;

      stepList[stepMapper(field)].is_required = true;
    }

    return stepList;
  },

  currentStep(state, getters) {
    const path = $nuxt.$route.path;
    const pathParts = path.split('/');
    const pathBaseName = pathParts[pathParts.length - 1];

    return getters.stepList.find(step => step.to === pathBaseName);
  },

  currentStatus(state, getters) {
    if (!getters.areAllRequiredFieldsComplete) {
      return 'incomplete';
    }

    const ad = state.selectedAd;

    if (ad.approval_status === 'awaiting_approval') {
      return 'inReview';
    }

    if (ad.approval_status === 'rejected') {
      return 'rejected';
    }

    if (ad.approval_status === 'approved' && ad.running_status === 'running') {
      return 'running';
    }

    if (ad.approval_status === 'approved' && ad.running_status === 'stopped') {
      return 'stopped';
    }

    if (ad.running_status === 'expired') {
      return 'expired';
    }

    // if (resource.approval_status === 'none' && resource.status !== 'active') {
    //   return 'inactive';
    // }
    return 'creating';
  },

  imageMinDimensions(state) {
    if (state.adType === '1')
      return { width: 496, height: 260 };

    if (state.adType === '2')
      return { width: 300, height: 388 };

    if (state.adType === '3')
      return { width: 100, height: 100 };

    if (state.adType === '4')
      return { width: 1044, height: 496 };

    if (state.adType === '5')
      return { width: 900, height: 900 };

    return { width: Infinity, height: Infinity };
  },

  businessImageMinDimensions(state) {
    return { width: 50, height: 50 };
  },

  canEdit(state, getters, externalState, externalGetters) {
    if (externalGetters['main/isAdmin']) {
      return true;
    }

    if (state.selectedAd.running_status === 'running') {
      return false;
    }
    if (state.selectedAd.approval_status === 'awaiting_approval') {
      return false;
    }
    return true;
  },

  canEditSpend(state, getters) {
    return getters.canEdit && (!state.selectedAd.running_status || state.selectedAd.running_status === 'expired');
  },

  requiredFields(state) {
    const requiredFields = [
      'image_name',
      'click_url',
      'title',
      'spend_per_day',
      'run_days_total',
    ];

    const adType = state.selectedAd?.ad_type || state.adType;


    if (adType === '2') {
      requiredFields.push('subtitle');
      requiredFields.push('subject');
      requiredFields.push('gradesArray');
    }
    else if (adType === '5') {
      requiredFields.push('description');
    }
    else {
      requiredFields.push('description');
      requiredFields.push('button');
    }

    return requiredFields;
  },

  areAllRequiredFieldsComplete(state, getters, externalState, externalGetters) {
    // TODO: refactor to use getters.fieldCompletionStates
    const requiredFields = getters.requiredFields;

    for (const requiredField of requiredFields) {
      if (!state.selectedAd[requiredField]) {
        return false;
      }
    }

    if (state.adType === '2') {
      if (state.selectedAd.gradesArray.length < 1) {
        return false;
      }
    }

    if (externalGetters['main/isAdmin']) {
      return true;
    }

    // if ad the ad was "stopped", then it was already running (i.e. had an
    // approved order already), and therefore doesn't need an order
    // submission to start again
    if (!['stopped', 'running'].includes(state.selectedAd.running_status)) {
      if (state.selectedAd.approval_status !== 'awaiting_approval') {
        if (!getters.isPaymentSourceComplete) {
          return false;
        }
      }
    }

    return true;
  },

  textFieldRequirements(state) {
    let titleMaxChars = 30;
    if (state.adType === '2')
      titleMaxChars = 32;
    else if (state.adType === '3')
      titleMaxChars = 28;
    else if (state.adType === '5')
      titleMaxChars = 24;

    let descriptionMaxChars = 60;
    if (state.adType === '1')
      descriptionMaxChars = 75;
    else if (state.adType === '3')
      descriptionMaxChars = 100;
    else if (state.adType === '4')
      descriptionMaxChars = 36;
    else if (state.adType === '5')
      descriptionMaxChars = 150;

    let statementMaxChars = 75;
    if (state.adType === '2')
      statementMaxChars = 75;
    if (state.adType === '3')
      statementMaxChars = 48;
    if (state.adType === '4')
      statementMaxChars = 48;

    let buttonMaxChars = 12;
    if (['1', '5'].includes(state.adType))
      buttonMaxChars = 15;
    if (state.adType === '3')
      buttonMaxChars = 18;

    return {
      image_name: {
        minChars: 1,
        maxChars: Infinity,
      },
      click_url: {
        minChars: 11,
        maxChars: 1000,
      },
      title: {
        minChars: 1,
        maxChars: titleMaxChars,
      },
      subtitle: {
        minChars: 1,
        maxChars: 25,
      },
      subject: {
        minChars: 1,
        maxChars: 15,
      },
      statement: {
        minChars: 1,
        maxChars: statementMaxChars,
      },
      business_name: {
        minChars: 1,
        maxChars: 25,
      },
      description: {
        minChars: 1,
        maxChars: descriptionMaxChars,
      },
      button: {
        minChars: 1,
        maxChars: buttonMaxChars,
      },
      discount_code: {
        minChars: 1,
        maxChars: 20,
      },
      code: {
        minChars: 1,
        maxChars: 75,
      },

    };
  },

  fieldCompletionStates(state, getters, rootState) {
    const states = {};
    const textFields = [
      'image_name',
      'click_url',
      'title',
      // 'spend_per_day',
      // 'run_days_total',
      'subtitle',
      'subject',
      'statement',
      'business_name',
      'description',
      'button',
      'discount_code',
      'code',
    ];

    const textFieldRequirements = getters.textFieldRequirements;
    for (const textField of textFields) {
      const value = state.selectedAd[textField] || '';
      const minChars = textFieldRequirements[textField].minChars;
      const maxChars = textFieldRequirements[textField].maxChars;
      states[textField] = (value.length >= minChars && value.length <= maxChars);
    }

    states.business_image = !!state.selectedAd.business_image;
    states.identity = states.business_name && states.business_image;

    states.title_description = states.title && states.description;

    states.targeting = state.selectedAd.gradesArray?.length > 0;

    const totalCostMinimum = rootState.main.siteSettings.adMinimums[state.selectedAd.ad_type]?.totalCost || 20;
    const spendPerDayMinimum = rootState.main.siteSettings.adMinimums[state.selectedAd.ad_type]?.spendPerDay || 1;


    states.ad_spend = (state.selectedAd.spend_per_day && state.selectedAd.run_days_total) &&
      getters.totalCost >= totalCostMinimum * 100 &&
      state.selectedAd.spend_per_day >= 100 * spendPerDayMinimum;

    return states;
  },

  totalCost(state) {
    return state.selectedAd.spend_per_day * state.selectedAd.run_days_total;
  },

  isPaymentSourceComplete(state, getters, externalState) {
    if (state.paymentSource !== 'credit-card') {
      return true;
    }

    const quickSettings = state.cardName.length >= 2
      && state.cardNumber.length >= 15
      && state.cardExp.length === 4
      && state.cardCvv.length >= 3;
    const longSettings = state.billingStreetAddress.length >= 1
      && state.billingCity.length >= 1
      && state.billingState.length === 2
      && state.billingZipCode.length >= 5;


    const useQuickCheckout = externalState.main.siteSettings?.quickCheckout;

    const complete = (useQuickCheckout && quickSettings) || (!useQuickCheckout && quickSettings && longSettings)


    return complete;

  },

  getAdStatements(state, getters, externalState, externalGetters) {
    const allAdStatements = [
      { type: 1, statement: externalState.main.texts['advertising statement--default field text ad 1'] },
      { type: 2, statement: externalState.main.texts['advertising statement--default field text ad 2'] },
      { type: 3, statement: externalState.main.texts['advertising statement--default field text ad 3'] },
      { type: 4, statement: externalState.main.texts['advertising statement--default field text ad 4'] },
      { type: 5, statement: externalState.main.texts['advertising statement--default field text ad 5'] },
    ];
    return allAdStatements;
  },

});

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  setAdDetails(state, details) {
    state.selectedAd = details;
  },

  updateSelectedAd(state, ad) {
    const adIndex = state.ads.findIndex(x => x.id === ad?.id);
    if (adIndex !== -1) {
      state.ads[adIndex] = ad;
    }
    state.selectedAd = ad;
    state.adType = ad.ad_type;
  },

  clearAd(state) {
    state.selectedAd = Object.assign({}, state.defaultAdDetails, {
      ad_type: state.adType,
      statement: getDefaultStatementForAdType(state.adType),
    });
    state.uploadingImage = false;
    state.uploadingImageProgress = 0;
    state.uploadingImageFile = null;
  },

  

};

export const actions = {

  resetPaymentDetails({commit}) {
    commit('set', {
      cardName: '',
      cardNumber: '',
      cardExp: '',
      cardCvv: '',
      billingStreetAddress: '',
      billingCity: '',
      billingState: '',
      billingZipCode: '',
    });
  },

  async saveIfEditable({ getters, dispatch }) {
    if (getters.canEdit) {
      await dispatch('saveAdChanges');
    }
  },

  async updateSelectedAdIsGuest({ commit, getters }, ad) {
    if (getters.canEdit) {
      commit('updateSelectedAd', ad);
      const response = await this.$sdk.patch(`/advertisements/${ad.id}/is_free_user_only`, ad);
      const responseJson = await response.json();
    }
  },

  async nextPage({ state, getters, dispatch }) {
    const currStepNumber = getters.currentStep.step;
    const nextStepNumber = currStepNumber + 1;
    const nextStep = getters.stepList.find(step => step.step === nextStepNumber);

    if (getters.canEdit) {
      await dispatch('saveAdChanges');
    }

    $nuxt.$router.push(`/advertising/${state.selectedAd.id}/edit/${nextStep.to}`);
  },

  blockUntilAdsLoaded({ state }) {
    return new Promise((resolve) => {
      const interval = window.setInterval(() => {
        if (state.adsLoaded) {
          window.clearInterval(interval);
          return resolve(state.adsById);
        }
      }, 50);
    });
  },

  async loadAdvertisements({ commit }) {
    commit('set', { adsLoaded: false });
    const response = await this.$sdk.get(`/advertisements`);
    const responseJson = await response.json();
    commit('set', {
      ads: responseJson.ads,
      adsLoaded: true,
    });
  },

  async findCreatingAdForType({ state }, adType) {
    for (const ad of state.ads) {
      if (ad.ad_type === adType && ad.approval_status === 'creating') {
        return ad;
      }
    }
    return null;
  },

  async loadAdDetails({ state, getters, commit, dispatch }, routeId) {
    let id = routeId;
    if ((id + '').startsWith('new')) {
      let adType;
      switch (id) {
        case 'new-1':
          adType = '1';
          break;
        case 'new-2':
          adType = '2';
          break;
        case 'new-3':
          adType = '3';
          break;
        case 'new-4':
          adType = '4';
          break;
        case 'new-5':
          adType = '5';
          break;
      }

      commit('set', { adType });

      commit('setAdDetails', Object.assign({}, state.defaultAdDetails, {
        ad_type: adType,
        statement: getters.getAdStatements[adType - 1].statement,
      }));
      return;
    }
    const adDetails = getters.adsById[id];
    if (adDetails) {
      commit('updateSelectedAd', adDetails);
      return adDetails;
    }

    return await dispatch('reloadSelectedAd');
  },

  async cleanSelectedAd({ state, commit, rootGetters }, area = null) {
    const replaceFunc = rootGetters['main/restrictedWordsReplace'];
    let fields = [
      'title', 'description', 'button', 'statement',
    ];
    let multi_fields = [

    ];

    if (area !== null) {
      if (fields.includes(area)) {
        fields = [area];
        multi_fields = [];
      } else if (multi_fields.includes(area)) {
        fields = [];
        multi_fields = [area];
      }
    }

    for (const field of fields) {
      if (typeof state.selectedAd[field] === 'undefined') continue;
      const new_field = replaceFunc(state.selectedAd[field]);
      commit('set', {
        selectedAd: Object.assign({}, state.selectedAd, {
          [field]: new_field
        })
      });
    }
    for (const multiField of multi_fields) {
      if (typeof state.selectedAd[field] === 'undefined') continue;
      const new_fields = state.selectedAd[multiField].map(item => replaceFunc(item));
      commit('set', {
        selectedAd: Object.assign({}, state.selectedAd, {
          [multiField]: new_fields
        })
      });
    }

  },

  async saveAdChanges({ state, commit, dispatch }, payload = null) {
    const selectedAd = state.selectedAd;
    await dispatch('cleanSelectedAd');

    let request;
    if (!selectedAd.id) {
      request = this.$sdk.post(`/advertisements`, selectedAd);
    } else {
      request = this.$sdk.patch(`/advertisements/${selectedAd.id}`, selectedAd);
    }

    const response = await request;
    if (!response.ok) {
      commit('set', { error: `Could not save changes` });
      return;
    }
    if (!state.selectedAd.id) {
      const responseJson = await response.json();
      commit('setAdDetails', responseJson.ad);
    }
    if (payload?.skipReload) {
      return;
    }
    await dispatch('reloadSelectedAd');
  },

  async setImageFile({ state, commit, getters, dispatch }, file) {
    if (!file) {
      return;
    }

    if (file.size > 1024 * 1024 * 1) {
      throw new Error(`The file you selected exceeds maximum file size requirements.`);
    }

    // if (file.size > state.imageFileSizeMax) {
    //   window.alert(`The file you selected is too large!`);
    //   return;
    // }

    const base64 = await getImageBase64(file);
    const imageObject = await getImageObject(base64);
    const imageMinDimensions = getters.imageMinDimensions;
    if (
      imageObject.width < imageMinDimensions.width ||
      imageObject.height < imageMinDimensions.height
    ) {
      throw new Error(`The file you selected doesn't meet the minimum dimension requirements.`);
    }

    if (!state.selectedAd.id) {
      await dispatch('saveAdChanges');
    }

    const xhr = this.$sdk.getXhr({
      method: 'put',
      url: `/api/advertisements/${state.selectedAd.id}/image`,
    });

    const formData = new FormData();
    formData.append("file", file);

    commit('set', {
      uploadingImage: true,
      uploadingImageProgress: 0,
      uploadingImageFile: file,
    });

    xhr.upload.addEventListener(
      "progress",
      function (event) {
        if (!event.lengthComputable) {
          return;
        }
        const progress = event.loaded / event.total;
        commit('set', { uploadingImageProgress: progress });
      },
      false
    );

    const response = await xhr.sendAsync(formData);

    await dispatch('reloadSelectedAd');
    commit('set', {
      uploadingImage: false,
      uploadingImageProgress: 0,
      uploadingImageFile: null,
    });
  },

  // TODO TODO TODO refactor me (redundant to above)
  async setBusinessImageFile({ state, commit, getters, dispatch }, file) {
    if (!file) {
      return;
    }

    // if (file.size > state.imageFileSizeMax) {
    //   window.alert(`The file you selected is too large!`);
    //   return;
    // }

    const base64 = await getImageBase64(file);
    const imageObject = await getImageObject(base64);
    const businessImageMinDimensions = getters.businessImageMinDimensions;
    if (
      imageObject.width < businessImageMinDimensions.width ||
      imageObject.height < businessImageMinDimensions.height
    ) {
      throw new Error(`The file you selected doesn't meet the minimum dimension requirements.`);
    }

    if (!state.selectedAd.id) {
      await dispatch('saveAdChanges');
    }

    const xhr = this.$sdk.getXhr({
      method: 'put',
      url: `/api/advertisements/${state.selectedAd.id}/business-image`,
    });

    const formData = new FormData();
    formData.append("file", file);

    commit('set', {
      uploadingBusinessImage: true,
      uploadingBusinessImageProgress: 0,
      uploadingBusinessImageFile: file,
    });

    xhr.upload.addEventListener(
      "progress",
      function (event) {
        if (!event.lengthComputable) {
          return;
        }
        const progress = event.loaded / event.total;
        commit('set', { uploadingBusinessImageProgress: progress });
      },
      false
    );

    const response = await xhr.sendAsync(formData);

    await dispatch('reloadSelectedAd');

    commit('set', {
      uploadingBusinessImage: false,
      uploadingBusinessImageProgress: 0,
      uploadingBusinessImageFile: null,
    });
  },

  async deleteImageFile({ state, dispatch }) {
    const response = await this.$sdk.delete(`/advertisements/${state.selectedAd.id}/image`);
    await dispatch('reloadSelectedAd');
  },

  async deleteBusinessImageFile({ state, dispatch }) {
    const response = await this.$sdk.delete(`/advertisements/${state.selectedAd.id}/business-image`);
    await dispatch('reloadSelectedAd');
  },

  async reloadSelectedAd({ state, commit, dispatch }) {
    if (!state.selectedAd.id) {
      return;
    }
    const response = await this.$sdk.get(`/advertisements/${state.selectedAd.id}`);
    const responseJson = await response.json();
    commit('updateSelectedAd', responseJson.ad);
    return responseJson.ad;
  },

  async updateEstimates({ state, commit }) {
    const response = await this.$sdk.get(`/advertisements/estimate`, {
      spend_per_day: state.selectedAd.spend_per_day,
      run_days_total: state.selectedAd.run_days_total,
      ad_type: state.adType,
    });
    const responseJson = await response.json();
    commit('set', { estimates: responseJson });
  },

};
