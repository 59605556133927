import Vue from "vue";

import currencyFormatter from 'currency-formatter'
import { DateTime } from 'luxon';

export function filterPlural(value, number) {
  if (number === 1) {
    return `${value}`;
  }
  return `${value}s`;
}

Vue.filter('plural', filterPlural)

export function filterCurrency(value) {
  value = Number(value)
  const newVal = currencyFormatter.format(value, { code: 'USD' })
  console.log(12345, value, newVal)
  return newVal
}

Vue.filter('currency', filterCurrency)

export function filterCurrencyWhole(value) {
  value = currencyFormatter.format(value, {code: 'USD'});
  let returnVal = value.substring(0, value.indexOf('.'));
  return returnVal;
}

Vue.filter('currencyWhole', filterCurrencyWhole)

export function filterCurrencyCents(value) {
  return filterCurrency(value / 100);
}

Vue.filter('currencyCents', filterCurrencyCents)

export function filterCurrencyCentsMinZero(value) {
	if (value < 0) {
		return `$0.00`;
	}
	return filterCurrencyCents(value);
  }
  
  Vue.filter('currencyCentsMinZero', filterCurrencyCentsMinZero)

export function thousands(value) {
  if (value < 1000) {
    return value
  }
  return `${Math.floor(value / 1000).toFixed(0)}k`
}
Vue.filter('thousands', thousands)
function getUnitForNumber(num) {
  const units = [
    { min: 1000000000, unit: 'b' },
    { min: 1000000,    unit: 'm' },
    { min: 1000,       unit: 'k' },
  ];
  for (const level of units) {
    if (num >= level.min) {
      return level;
    }
  }
  return { min: 1, unit: '' };
}

function getUnitForFileSize(num) {
  const units = [
    { min: 1000000000, unit: 'GB' },
    { min: 1000000,    unit: 'MB' },
    { min: 1000,       unit: 'KB' },
    { min: 0,          unit: 'B' },
  ];
  for (const level of units) {
    if (num >= level.min) {
      return level;
    }
  }
}

function roundToDecimals(number, decimals) {
  const multiplier = Math.pow(10, decimals);
  const rounded = Math.round(number * multiplier) / multiplier;
  return rounded;
}

Vue.filter("decimals", roundToDecimals);

export function filterLargeNumberDisplay(num, decimals=null) {
  if (!num) {
    return '0';
  }
  const unit = getUnitForNumber(num);
  let multiple = num / unit.min;
  if (decimals !== null) {
    multiple = roundToDecimals(multiple, decimals);
  }

  let returnVal = `${multiple.toFixed(1)}${unit.unit}`;
  if (returnVal.includes('.0')) {
    returnVal = returnVal.replace('.0', '');
  } else if (returnVal.includes('.00')) {
    returnVal = returnVal.replace('.00', '');
  }
  return returnVal;
}

Vue.filter("largeNumberDisplay", filterLargeNumberDisplay);

export function filterLargeNumberDisplayInteger(num, decimals=null) {
  if (!num) {
    return '0';
  }
  const unit = getUnitForNumber(num);
  let multiple = num / unit.min;
  if (decimals !== null) {
    multiple = roundToDecimals(multiple, decimals);
  }
  return `${multiple.toFixed(0)}${unit.unit}`;
}

Vue.filter("largeNumberDisplayInteger", filterLargeNumberDisplayInteger);

export function filterFileSize(num, decimals=null) {
  const unit = getUnitForFileSize(num);
  let multiple = num / (unit.min || 1);
  if (decimals !== null) {
    multiple = roundToDecimals(multiple, decimals);
  }
  return `${multiple}${unit.unit}`;
}

Vue.filter("fileSize", filterFileSize);

export function filterDate(value, format = 'yyyy-MM-dd') {
  return DateTime.fromISO(value).toFormat(format);
}

Vue.filter('date', filterDate)

export function filterCapitalize(value) {
  return value[0].toUpperCase() + value.slice(1);
}

Vue.filter('capitalize', filterCapitalize)

export function filterPercentage(value, decimals=2) {
  const percValue = (value * 100);
  if (decimals === null) {
    return `${percValue}%`;
  }
  // const multiplier = Math.pow(10, decimals);
  // const rounded = Math.round(percValue * multiplier) / multiplier;

  const rounded = roundToDecimals(percValue, decimals);
  return `${rounded}%`;
}

Vue.filter('percentage', filterPercentage);
