import { createRipple } from "~/lib/ripple-effect";

export default (context, inject) => {
  const STOP_WORDS = [
    "a", "an", "and", "as", "at", "be", "by", "for", "from", "has", "he", "in", "is", "it", "its", "of", "on", "that", "the", "to", "was", "were", "will", "with",
  ]

  const sortByField = (arr, fieldName) => {
    return arr.sort((a, b) => {
      const fieldA = a[fieldName]
      const fieldB = b[fieldName]
      return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0
    });
  }

  const sortByDates = (arr, fieldName = 'updated_at', dir = 'desc') => {
    return arr.sort(function (a, b) {
      const dateA = new Date(a[fieldName]);
      const dateB = new Date(b[fieldName]);
      if (dir === 'asc') {
        return dateA - dateB;
      } else if (dir === 'desc') {
        return dateB - dateA;
      } else {
        return 0;
      }
    });
  }
  const sortByDownloads = (arr) => {
    return sortByField(arr, 'downloadCount');
  }
  
  const sortByFeatured = (arrToSort) => {
    const { featured, notFeatured } = arrToSort.reduce((prev, curr, indx, arr) => {
      if (curr.is_featured) {
        prev.featured.push(curr)
      } else {
        prev.notFeatured.push(curr)
      }
      if (indx === arr.length - 1) {
        prev.featured = sortByDates(prev.featured)
        prev.notFeatured = sortByDownloads(prev.notFeatured)
      }
      return prev;
    }, { featured: [], notFeatured: [] })
    return [...featured, ...notFeatured]
  }

  const chunkFilter = (arr, searchTerm, fieldsToSearch, blockStopWords = false) => {
    const term = searchTerm.toLowerCase();

    return arr.filter(x => {
      if (!x) {
        return false;
      }
      return (
        doAllFilterPartsMatch(x, term, fieldsToSearch, blockStopWords)
      );
    });
  }

  const doAllFilterPartsMatch = (object, chunksToSearch, fieldsToSearch, blockStopWords = false) => {
    const parts = chunksToSearch.split(' ');
    for (const part of parts) {
      if (blockStopWords) {
        if (STOP_WORDS.includes(part)) {
          continue;
        }
      }
      const results = fieldsToSearch.map(field => field in object && object[field] && object[field]?.toLowerCase().includes(part))
      // if no matches are true
      if (!results.some(r => r === true)) {
        return false
      }
    }
    return true;
  }

  inject('searchWizard', {
    chunkFilter,
    sortByField,
    sortByDates,
    sortByDownloads,
    sortByFeatured,
    STOP_WORDS
  });
};
