import Vue from 'vue'
import VueSanitize from "vue-sanitize";

const defaultOptions = {
    allowedTags: ['a', 'br'],
    allowedAttributes: {
      'a': [ 'href', 'target' ]
    }
};

Vue.use(VueSanitize, defaultOptions);