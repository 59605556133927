export default (context, inject) => {
  const SITE_NAME = `learningful`
  const SITE_URL = `https://www.${SITE_NAME}.com`;
  const BASE_URL = `${SITE_URL}/`
  const ORGANIZATION_URL = `${BASE_URL}#organization`
  const LOGO_URL = `${BASE_URL}#logo`
  const WEBSITE_URL = `${BASE_URL}#website`
  const WEBPAGE_URL = `${BASE_URL}#webpage`
  const LANGUAGE = "en-US"
  const escapeQuotes = (val) => {
    return val.replace(/"/g, "&quot;")
  }

  const getHeadObject = (titleToUse, descriptionToUse, typeToUse = 'website') => {
    const urlToUse = window.location.href;
    const escapedTitle = escapeQuotes(titleToUse);
    const meta = [
      {
        hid: "og:title",
        property: "og:title",
        content: escapedTitle,
      },
      {
        hid: "og:description",
        property: "og:description",
        content: descriptionToUse,
      },
      {
        hid: 'description',
        name: 'description',
        content: descriptionToUse,
      },
      {
        hid: "og:type",
        property: "og:type",
        content: typeToUse,
      },
      {
        hid: "og:url",
        property: "og:url",
        content: urlToUse,
      },
      {
        hid: 'og:image',
        name: 'og:image',
        content: `${SITE_URL}${context.$preloader.getDocumentImage('Open Graph Learningful Default Image')}`,
      }
    ]
    const link = [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: urlToUse
      }
    ]
    /*
    const structuredData = {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": `${ORGANIZATION_URL}`,
          "name": `${SITE_NAME}`,
          "legalName": `${SITE_NAME}`,
          "url": `${BASE_URL}`,
          "logo": {
            "@type": "ImageObject",
            "@id": `${LOGO_URL}`,
            "inLanguage": "en-GB",
            "url": `${SITE_URL}${context.$preloader.getDocumentImage('Open Graph Learningful Default Image')}`,
            "caption": `${SITE_NAME}`
          },
          "image": {
            "@id": `${LOGO_URL}`
          }
        },
        {
          "@type": "WebSite",
          "@id": `${WEBSITE_URL}`,
          "url": `${BASE_URL}`,
          "name": `${SITE_NAME}`,
          "description": context.$t('Meta Data Description For Homepage'),
          "publisher": {
            "@id": `${ORGANIZATION_URL}`
          },
          "potentialAction": [
            {
              "@type": "SearchAction",
              "target": `${SITE_URL}/?search={search_name}&grades={grades_input}&sortBy={sort_by_input}`,
              "query-input": "required search=search_name grades=grades_input sortby=sort_by_input"
            }
          ],
          "inLanguage": LANGUAGE
        },
        {
          "@type": typeToUse,
          "@id": `${WEBPAGE_URL}`,
          "name": titleToUse,
          "description": descriptionToUse,
          "image": `${SITE_URL}${context.$preloader.getDocumentImage('Open Graph Learningful Default Image')}`,
          "isPartOf": {
            "@id": `${WEBSITE_URL}`
          },
          "about": {
            "@id": `${ORGANIZATION_URL}`
          },
          "inLanguage": LANGUAGE,
          "potentialAction": [
            {
              "@type": "ReadAction",
              "target": [urlToUse]
            }
          ]
        }]
    }
    */
   const d = {
    title: titleToUse,
    meta,
    link,
    //__dangerouslyDisableSanitizers: ['script'],
    //script: [{ innerHTML: JSON.stringify(structuredData), type: 'application/ld+json' }]
  }
    return d
  }

  const parseValues = (initial, overrides) => {

    initial = overrides.reduce((init, curr, index) => {
      return init.replace(`{s${index + 1}}`, curr)
    }, initial)

    return initial
  }

  inject('h', { getHeadObject, parseValues });
};
