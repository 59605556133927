
import { modalBaseGetters } from '@/mixins/ModalStore';

const defaults = {
  bulkEmail: {
    totalSent: 0,
    totalUsers: 0,
    bulkSend: null,
  },
  loadingBulkSend: false,
  infoLineComponent: null,
  colorEditing: {},
  textEditing: {},
  colors: [],
  exceptionsUser: null
};

export const state = () => Object.assign({}, defaults);

export const getters = Object.assign({}, modalBaseGetters, {
});

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  updateColor(state, updatedColor) {
    for (let i = 0; i < state.colors.length; i++) {
      const color = state.colors[i];
      if (color.id === updatedColor.id) {
        state.colors[i].css = updatedColor.css;
        return;
      }
    }
  },

};

export const actions = {

  async loadBulkSend({ state, commit }) {
    if (state.loadingBulkSend) return;
    commit('set', {
      loadingBulkSend: true,
    });

    const response = await this.$sdk.get(`/users/bulk-email`);
    const responseJson = await response.json();
    commit('set', {
      bulkEmail: {
        bulkSend: responseJson.bulkSend,
        totalSent: responseJson.progress?.emailsSent || 0,
        totalUsers: responseJson.progress?.totalUsers || 0,
        totalActiveUsers: responseJson.progress?.totalActiveUsers || 0,
        totalUsersRemaining: responseJson.progress?.totalUsersRemaining || 0,
      },
      loadingBulkSend: false,
    });
  },

  setEditingColor({ state, commit }, color) {
    commit('set', {
      infoLineComponent: 'edit-color',
      colorEditing: color,
    });
  },

  setEditingText({ state, commit }, text) {
    commit('set', {
      infoLineComponent: 'edit-text',
      textEditing: text,
    })
  },

  cancelEditingColor({ state, commit }) {
    commit('set', {
      infoLineComponent: null,
      colorEditing: {},
    });
  },

  cancelEditingText({ state, commit }) {
    commit('set', {
      infoLineComponent: null,
      textEditing: {},
    })
  },

  async saveEditingColor({ state, commit, dispatch }) {
    const response = await this.$sdk.patch(`/colors/${state.colorEditing.name}`, state.colorEditing);

    commit('main/updateColor', state.colorEditing, { root: true })

    commit('updateColor', state.colorEditing);

    commit('set', {
      infoLineComponent: null,
      colorEditing: {},
    });
  },

  async loadColors({ commit }) {
    const response = await this.$sdk.get('/colors');
    const responseJson = await response.json();
    const colors = responseJson.colors;

    commit('set', {
      colors,
    });
  },

};
