// take array and randomise
function randomise(array) {
	var currentIndex = array.length, temporaryValue, randomIndex;
	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
}

const getPermissionFunction = (permissions, permissionName) => {
	const defaultFunctions = {
		"referral-prompt": () => true,
		// "first-tip-prompt": () => true,
		"support-request": () => true,
	};

	const permissionToKeyMap = {
		"publishing-prompt": { key: 'userHasNoResources' },
		"featuring-prompt": { key: 'userHasPublishedResources' },
		"advertising-prompt": { key: 'userHasNoAds' },
		"donation-prompt": { key: 'userHasNoDonationsFromPastYear' },
		"income-daily": { key: 'userHasDailyEarnings' },
		"income-monthly": { key: 'userHasMonthlyEarnings' },
		"unpaid-earnings": { key: 'userHasEligiblePayout' },
		"password-update-prompt": { key: 'userHasExpiredPassword' },
		"interest-category-suggestion": { key: 'userHasInterests' },
		"favorite-author": { key: 'userHasFavoriteAuthor' },
		"published-resources": { key: 'userHasPublishedResources' },
		"favorites-saved": { key: 'userHasFavorites' },
		"favorites-null": { key: 'userHasFavorites', invert: true },
		"two-factor-authentication-prompt": { key: 'userHasSecurityPattern', invert: true },
		"earnings-increase": { key: 'userHasEarningsIncrease' },
		"following": { key: 'userHasFollows' },
		"followers": { key: 'userHasFollowedBy' },
		"resources-downloaded": { key: 'userHasResourcesDownloadsThisMonth' },
		"downloads": { key: 'userHasResourcesThatHaveBeenDownloadedToday' },
		"all-time-earnings": { key: 'userHasAllTimeEarnings' },
		"epd": { key: 'userHasEpd' },
		"you-tipped": { key: 'userHasTips' },
		"tipped-none": { key: 'userHasTips', invert: true },
		"secondary-email": { key: 'userHasRecoveryEmail', invert: true },
		"ads-expired": { key: 'userHasExpiredAds' },
		"rolling-average": { key: 'userHasThirtyDayAverage' },
	};

	if (defaultFunctions[permissionName]) {
		return defaultFunctions[permissionName];
	}

	const permissionData = permissionToKeyMap[permissionName];

	if (permissionData) {
		return () => permissionData.invert ? !permissions[permissionData.key] : !!permissions[permissionData.key];
	}

	return () => false;
}

const defaultMessages = [
	'account-creation', // default
	'resources-count', // resource count
	'footer-notification-error', // error prompt
	'sign-in-prompt', // sign-in prompt
	'footer-notification-sign-out', // sign-out prompt
	'mail-verification',  // email verification prompt
]

const extraMessages = [
	"publishing-prompt",
	"referral-prompt",
	"featuring-prompt",
	"advertising-prompt",
	// "first-tip-prompt",
	"donation-prompt",
	// "income-daily",
	// "income-monthly",
	"unpaid-earnings",
	"password-update-prompt",
	"interest-category-suggestion",
	"favorite-author",
	"published-resources",
	"favorites-saved",
	"favorites-null",
	"two-factor-authentication-prompt",
	// "earnings-increase",
	"following",
	"followers",
	"resources-downloaded",
	"downloads",
	// "all-time-earnings",
	// "epd",
	"you-tipped",
	"tipped-none",
	"secondary-email",
	"ads-expired",
	// "rolling-average",
	"support-request",
	...Array(25).fill(1).map((x, i) => `flex-message-${i + 1}`)
]

export const state = () => (Object.assign({}, {
	order: [
		...defaultMessages,
		...randomise(extraMessages),
	],
	currentMessage: 'error',
	isFooterRotating: false,
	goToSearch: false,
	force: false,
	hasError: false,
	hasEmailError: false,
	justLoggedIn: false,
	justLoggedOut: false,
	needsMailVerification: false,
	totalLearningfulResources: null,
	permissions: {},
	userData: {},
	currentMessageFromSearch: '',
	firstVisit: false
}));


export const getters = {};

export const mutations = {

	set(state, values) {
		for (const [key, val] of Object.entries(values)) {
			state[key] = val;
		}
	},
	addPermissions(state, { permissionsDict }) {
		state.permissions = { ...state.permissions, ...permissionsDict }
	},
	addData(state, { data }) {
		state.userData = { ...state.userData, ...data }
	}
};

const getNextMessage = (permissions, messageList, currentMessage, t) => {
	const currentMessageId = messageList.indexOf(currentMessage)
	const nextMessageId = currentMessageId < defaultMessages.length ? defaultMessages.length : currentMessageId + 1;
	const nextMessage = messageList[nextMessageId]
	if (nextMessage && nextMessage.includes('flex-message-')) {
		const id = nextMessage.slice(-2, nextMessage.length);
		const idNum = parseInt(id.replace('-', ''))
		const m = t(`sidebar-footer-notification-flex-message-${idNum}`)
		if (m && m !== "Inactive") {
			return nextMessage
		} else {
			return getNextMessage(permissions, messageList, nextMessage, t)
		}
	} else {
		const permissionFn = getPermissionFunction(permissions, nextMessage)
		if (permissionFn()) {
			return nextMessage
		} else {
			return getNextMessage(permissions, messageList, nextMessage, t)
		}
	}
}

export const actions = {
	addPermissions({ commit, state }, { permissionsDict }) {
		commit('set', {
			permissions: { ...state.permissions, ...permissionsDict }
		});
	},
	rotate({ commit, state }, { newMessage }) {
		commit('set', {
			currentMessage: newMessage,
			isFooterRotating: true,
		});
		window.setTimeout(() => {
			commit('set', {
				isFooterRotating: false,
				force: false,
				hasError: false,
				hasEmailError: false,
				justLoggedIn: false,
				justLoggedOut: false,
				needsMailVerification: false
			});
		}, 2000);
	},
	rotateFooter({ commit, dispatch, state, rootState }) {
		let current = state.currentMessage;
		const index = state.order.indexOf(current);
		let newOrderIndex, nextMessage;
		if (state.justLoggedIn) {
			dispatch("rotate", { newMessage: 'sign-in-prompt' });
		} else if (state.justLoggedOut) {
			dispatch("rotate", { newMessage: 'sign-out' });
		} else if (state.goToSearch) {
			commit('set', {
				goToSearch: false,
				currentMessageFromSearch: current
			});
			dispatch("rotate", { newMessage: 'resources-count' });
		} else if (state.hasError) {
			dispatch("rotate", { newMessage: 'error' });
		} else if (state.hasEmailError) {
			dispatch("rotate", { newMessage: 'email-error' });
		} else if (state.needsMailVerification) {
			dispatch("rotate", { newMessage: 'mail-verification' });
		} else if (!rootState?.main?.userLoaded) {
			dispatch("rotate", { newMessage: 'account-creation' });
		} else {
			nextMessage = getNextMessage(state.permissions, state.order, current, this.app.$t)
			dispatch("rotate", { newMessage: nextMessage });
		}

	},
	rotateFooterFromSearch({ commit, dispatch, state, rootState }) {
		let current = state.currentMessage;
		if (state.hasError) {
			dispatch("rotate", { newMessage: 'error' });
		} else if (current === "resources-count") {
			if (rootState?.main?.userLoaded) {
				if (state.currentMessageFromSearch !== "") {
					current = state.currentMessageFromSearch
				}
				const nextMessage = getNextMessage(state.permissions, state.order, current, this.app.$t)
				dispatch("rotate", { newMessage: nextMessage });
			} else {
				dispatch("rotate", { newMessage: 'account-creation' });
			}
		}
	},
	rotateFooterToError({ dispatch }) {
		dispatch("rotate", { newMessage: 'error' });
	}
};
