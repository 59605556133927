export default ({ app }, inject) => {
  app.fakeLinks = () => {
    //handles clicks and keydowns on the link
    function navigateLink(e) {
      if (e.key === "Enter") {
        const ref = e.target ?? e.srcElement;
        if (ref) {
          ref.click();
        }
      }
    }

    const fakeLinks = document.querySelectorAll('[role="link"]:not([href])');
    const fakeButtons = document.querySelectorAll('[role="button"]:not(button)');
    
    [...fakeLinks, ...fakeButtons].forEach((fakeElement) => {
      fakeElement.setAttribute("tabindex", "0");
      fakeElement.addEventListener("keydown", navigateLink);
    })
  }
}