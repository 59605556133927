

const advertising = {
    images: [
        'Advertising Splash',
    ],
    children: {
        create: {
            images: [
                "Ad Creation File Deletion Icon",
                "Ad Creation Live Preview Icon (Ad Type 1)",
                "Ad Creation Live Preview Icon (Ad Type 2)",
                "Ad Creation Live Preview Icon (Ad Type 3)",
                "Ad Creation Live Preview Icon (Ad Type 4)",
                "Ad Creation Live Preview Icon (Ad Type 5)",
                "Ad Creation Edit Icon",
                "Ad Creation Section Completion Checkmark",
                "Ad Creation Section Completion Checkmark (Hover)",
                "Ad Creation Section Completion Checkmark (OnClick)",
                "Ad Creation Ad Spend Selection Button (Up)",
                "Ad Creation Ad Spend Selection Button (Down)",
                "Ad Creation Terms Accepted Checkmark",
            ]
        },
        manage: {
            images: [
                "Ad Management Ad Type 1 Icon",
                "Ad Management Ad Type 2 Icon",
                "Ad Management Ad Type 3 Icon",
                "Ad Management Ad Type 4 Icon",
                "Ad Management Ad Type 5 Icon",
                "Ad Management Search Icon",
                "Ad Management Advertiser Icon",
                "Ad Management Edit Icon",
                "Ad Management Edit Button",
                "Ad Management Edit Button (Inactive)",
                "Ad Management Up Navigation Button",
                "Ad Management Up Navigation Button (Inactive)",
                "Ad Management Down Navigation Button",
                "Ad Management Up Navigation Button (Inactive)",

            ]
        },
        edit: {
            images: [
                'Ad Creation Ad Spend Selection Button Up',
                'Ad Creation Ad Spend Selection Button Down',
                'Ad Creation File Deletion Icon',
                'Ad Creation Edit Icon',
                'Ad Creation Live Preview Icon (Ad Type 1)',
                'Ad Creation Live Preview Icon (Ad Type 2)',
                'Ad Creation Live Preview Icon (Ad Type 3)',
                'Ad Creation Live Preview Icon (Ad Type 4)',
                'Ad Creation Live Preview Icon (Ad Type 5)',
                'Ad Creation Section Completion Checkmark',
                'Ad Creation Section Completion Checkmark (Hover)',
                'Ad Creation Section Completion Checkmark (OnClick)',
                'Upload Section Complete Icon',
                'Upload Section Complete Icon (Hover)',
                'Upload Section Complete Icon (OnClick)',
                'Upload Section Dditing Icon',
                'Upload File Selection Up Button',
                'Upload File Selection Up Button (Inactive)',
                'Upload File Selection Down Button',
                'Upload File Selection Down Button (Inactive)',
                'Ad Creation Free User Restriction Checkmark'

            ]
        },
        id: {
            images: [],
            children: {
                submitted: {
                    images: [
                        'Ad Submission Success Background'
                    ]
                }
            }
        }
    }
}

module.exports = advertising;