import { orderFormBaseState, orderFormBaseGetters } from '@/mixins/OrderFormStore';
import { modalBaseGetters } from '@/mixins/ModalStore';

export const state = () => (Object.assign({}, orderFormBaseState, {
  amount: 0,
  error: null,

  // confirmingDeletion: false,
  cancelReturnUrl: null,
}));


export const getters = Object.assign({}, modalBaseGetters, orderFormBaseGetters, {

  modalCloseWarning() {
    return true
  },

});

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },


};

export const actions = {
};
