const defaultPaypalPaymentsState = {
  loading: false,
  paymentSource: 'credit-card',
  refCode: null,
  isGift: false,
  amount: 0,
  error: false,
  canceled: false,
  saleId: null,
  salePrice: 0,
  lock: false,
}

export const state = () => defaultPaypalPaymentsState;


export const getters = {
  completionState(state) {
    if (state.error) {
      return 'error';
    }
    if (state.canceled) {
      return 'canceled';
    }
    return 'success';
  },

};

export const mutations = {

  set(state, values) {
    if (!state.lock) {
      for (const [key, val] of Object.entries(values)) {
        state[key] = val;
      }
    }
  },

  unlock(state) {
    state.lock = false;
  },

  resetMutation(state) {
    state.loading = false;
    state.paymentSource = 'credit-card';
    state.refCode = null;
    state.isGift = false;
    state.amount = 0;
    state.error = false;
    state.canceled = false;
    state.saleId = null;
    state.salePrice = 0;
    state.lock = false;
  },

  startLoading(state) {
    state.loading = true;
  },

  stopLoading(state) {
    state.loading = false;
  },


};

export const actions = {
  reset(ctx) {
    ctx.commit('set', {
      loading: false,
      paymentSource: 'credit-card',
      refCode: null,
      isGift: false,
      amount: 0,
      error: false,
      canceled: false,
      saleId: null,
      salePrice: 0,
      lock: false,
    })
    this.dispatch('paypal-payments/reset')
  },
  async handlePurchaseRedirect(ctx, {url}) {
    // console.log(url, ctx.state)
    this.$router.push(url)
    ctx.commit('unlock')
  },
  async finishPurchase(ctx, {
    url,
    amount = 0,
    referral = false,
    error = false,
    canceled = false,
    saleId = null,
  }) {
    console.log("FINISH PURCHASE", ctx, this)
    ctx.commit('set', {
      ...defaultPaypalPaymentsState,
      saleId,
      amount,
      error,
      canceled,
      refCode: referral ? referral.code : null,
      isGift: referral ? true : false,
      lock: true
    })
    ctx.dispatch('handlePurchaseRedirect',{url});
  },
  printReceipt(ctx) {
      
    // const correctOrder = this.order.users_transactions_models.length === 1 ? this.order.users_transactions_models[0] : this.order.users_transactions_models.find(r => r.users_id === this.order.users_id) ?? this.order.users_transactions_models[0]
    const txId = ctx.state.saleId ? ctx.state.saleId : null; // correctOrder?.id;
    if (txId) {
      window.open(`/api/activity/transactions/${txId}/receipt#page=1&zoom=page-fit`, '_blank');
    }
  }
};
