import { createRipple } from "~/lib/ripple-effect";

export default (context, inject) => {
  const parseAdUrl = (url) => {
    if (url.includes("learningful.com")) {
      url = url.replace('https://', 'http://')
        .replace('http://', '')
        .replace('www.', '')
        .replace('learningful.com', '')
    }
    return url

  }
  const clickAd = async (componentThis, adId, adUrl, silent = false, needsView= false, force=false) => {
    const go = (url) => {
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
          window.location.href = url;
        } else {
          window.open(url, '_blank');
        }
    }
    const push = (url) => {
      componentThis.$router.push(url);
    }
    await componentThis.$sdk.post(`/advertisements/${adId}/click`);
    if (!silent) {
      let url = parseAdUrl(adUrl)
      if (url.includes("http")) {
        go(url)
      } else {
        if (adUrl.includes("/resources/") && needsView) {
          url = `${url}/view`
        }
        if (force && adUrl.includes("/resources/")) {
          url = adUrl
          go(url)
        } else {
          push(url)

        }

        console.log(url)
      }
    }
  }


  inject('adClicker', {
    clickAd
  });
};
