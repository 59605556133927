const defaultPaypalPaymentsState = {
  loading: false,
  paymentSource: 'credit-card',
  refCode: null,
  isGift: false,
  amount: 0,
  error: false,
  canceled: false,
  saleId: null,
  salePrice: 0,

  cardName: '',
  cardNumber: '',
  cardExp: '',
  cardCvv: '',
  billingStreetAddress: '',
  billingCity: '',
  billingState: '',
  billingZipCode: '',
}

export const state = () => defaultPaypalPaymentsState;


export const getters = {
  isPaymentInfoCompleted(state, getters, externalState) {
    const inQuickMode = externalState.main.siteSettings?.quickCheckout;
    let required = ['cardName', 'cardNumber', 'cardExp', 'cardCvv'];
    if (!inQuickMode) {
      required = required.concat(['billingZipCode', 'billingStreetAddress', 'billingCity', 'billingState']);
    }
    if (state.paymentSource === 'credit-card') {
      for (const requiredField of required) {
        if (!state[requiredField]) {
          return false;
        }
      }
    }
    return true;
  },

};

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  reset(state) {
    for (const [key, val] of Object.entries(defaultPaypalPaymentsState)) {
      state[key] = val;
    }
  },

  startLoading(state) {
    state.loading = true;
  },

  stopLoading(state) {
    state.loading = false;
  },


};

export const actions = {
  reset(ctx) {
    console.log("RESETTING")
    ctx.commit('set', {
      loading: false,
      paymentSource: 'credit-card',
      refCode: null,
      isGift: false,
      amount: 0,
      error: false,
      canceled: false,
      saleId: null,
      salePrice: 0,

      cardName: '',
      cardNumber: '',
      cardExp: '',
      cardCvv: '',
      billingStreetAddress: '',
      billingCity: '',
      billingState: '',
      billingZipCode: '',
    })
  },
  async doDonation({ state, getters, commit, dispatch }, { amount, placement = "prompt" }) {
    const url = '/transactions/donate/purchase';
    const response = await this.$sdk.post(url, {
      amount,
      placement,
      cardName: state.cardName,
      cardNumber: state.cardNumber,
      cardExp: state.cardExp,
      cardCvv: state.cardCvv,
      billingStreetAddress: state.billingStreetAddress,
      billingCity: state.billingCity,
      billingState: state.billingState,
      billingZipCode: state.billingZipCode,
    })
    const responseJson = await response.json();
    return responseJson;
  },
  async doPurchaseResource({ state, getters, commit, dispatch }, { resourceId, amount, referral }) {
    const url = referral ? `/transactions/resource/purchase/gift/${referral.code}` : '/transactions/resource/purchase';
    const response = await this.$sdk.post(url, {
      resourceId,
      amount,
      cardName: state.cardName,
      cardNumber: state.cardNumber,
      cardExp: state.cardExp,
      cardCvv: state.cardCvv,
      billingStreetAddress: state.billingStreetAddress,
      billingCity: state.billingCity,
      billingState: state.billingState,
      billingZipCode: state.billingZipCode,
    })
    const responseJson = await response.json();
    return responseJson;
  },
  async doTipUser({ state, getters, commit, dispatch }, { recipientId, amount, tipNote }) {
    const url = `/transactions/tip/purchase/${recipientId}`;
    const response = await this.$sdk.post(url, {
      recipientId,
      tipNote,
      amount,
      cardName: state.cardName,
      cardNumber: state.cardNumber,
      cardExp: state.cardExp,
      cardCvv: state.cardCvv,
      billingStreetAddress: state.billingStreetAddress,
      billingCity: state.billingCity,
      billingState: state.billingState,
      billingZipCode: state.billingZipCode,
    })
    const responseJson = await response.json();
    return responseJson;
  },
  async doMultiTip({ state, getters, commit, dispatch }, { recipientIds, amount }) {
    const url = `/transactions/tip/multiple`;
    const response = await this.$sdk.post(url, {
      recipientIds,
      amount,
      cardName: state.cardName,
      cardNumber: state.cardNumber,
      cardExp: state.cardExp,
      cardCvv: state.cardCvv,
      billingStreetAddress: state.billingStreetAddress,
      billingCity: state.billingCity,
      billingState: state.billingState,
      billingZipCode: state.billingZipCode,
    })
    const responseJson = await response.json();
    return responseJson;
  }
};
