const { MODAL_CLOSE_X } = require('../common')


const admin = require('./admin')
const advertising = require('./advertising')
const donate = require('./donate')
const donation = require('./donation')
const earnings = require('./earnings')
const firstTip = require('./first-tip')
const helpbook = require('./helpbook')
const login = require('./login')
const profile = require('./profile')
const publishing = require('./publishing')
const resources = require('./resources')
const signUp = require('./sign-up')


const imagePageKeys = {
    index: {
        images: [
            "Sidebar Logo",
            "Sidebar Logo (Hover)",
            "Sidebar Logo (OnClick)",
            "Sidebar Menu Button 1",
            "Sidebar Menu Button 2",
            "Sidebar Menu Button 3",
            "Sidebar Menu Button 4",
            "Sidebar Menu Button 5",
            "Sidebar Menu Button 6",
            "Sidebar Menu Button 7",
            "Sidebar Menu Button 8",
            "Sidebar Menu Button 9",
            // "Sidebar Letter A Avatar",
            // "Sidebar Letter B Avatar",
            // "Sidebar Letter C Avatar",
            // "Sidebar Letter D Avatar",
            // "Sidebar Letter E Avatar",
            // "Sidebar Letter F Avatar",
            // "Sidebar Letter G Avatar",
            // "Sidebar Letter H Avatar",
            // "Sidebar Letter I Avatar",
            // "Sidebar Letter J Avatar",
            // "Sidebar Letter K Avatar",
            // "Sidebar Letter L Avatar",
            // "Sidebar Letter M Avatar",
            // "Sidebar Letter N Avatar",
            // "Sidebar Letter O Avatar",
            // "Sidebar Letter P Avatar",
            // "Sidebar Letter Q Avatar",
            // "Sidebar Letter R Avatar",
            // "Sidebar Letter S Avatar",
            // "Sidebar Letter T Avatar",
            // "Sidebar Letter U Avatar",
            // "Sidebar Letter V Avatar",
            // "Sidebar Letter W Avatar",
            // "Sidebar Letter X Avatar",
            // "Sidebar Letter Y Avatar",
            // "Sidebar Letter Z Avatar",
            "Sidebar Footer Message Icon",
            "Array Entry Subject Banner",
            "Array Entry Favorite Button (Inactive)",
            "Array Entry Favorite Button (Active)",
            "Array Entry Edit Button",
            "Array Entry Data Button",
            "Ad Type 2 Icon",
            "Ad Type 2 Icon (Hover)",
            "Ad Type 2 Icon (OnClick)",
            "Image: pattern1",
            "Image: pattern2",
            "Image: pattern3",
            "Image: pattern4",
            "Image: pattern5",
            "Image: pattern6",
            "Image: pattern7",
            "Image: pattern8",
            "Image: pattern9",
            "Image: pattern10",
            "Image: pattern11",
            "Image: pattern12",
            "Image: pattern13",
            "Image: pattern14",
            "Image: pattern15",
            "Mobile Device Restriction Notice Logo",
            "Base Modal X Button Icon",
            "Open Graph Learningful Default Image",
        ],
        children: {
            admin,
            advertising,
            donate,
            donation,
            earnings: {...earnings},
            favorites: { images: ['Array Favorites Null Notification'] },
            "first-tip": firstTip,
            following: {
                images: [
                    "Array Following Notification Button (Enabled)",
                    "Array Following Notification Button (Disabled)",
                    "Array Following Null Notification",
                ]
            },
            help: { images: ['Help Splash'] },
            helpbook,
            login,
            logout: { images: ['Session Status Modal Background'] },
            new: { images: [] },
            offers: { images: [] },
            portfolio: { images: [] },
            profile,
            publishing,
            "resource-not-found": { images: ['Resource Not Found Splash', 'Gift Purchase Not Found Background'] },
            resources,
            "sign-up": signUp,
            support: {
                images: [
                    "Support Request System Avatar",
                    "Support Request File Attachment Button Icon",
                    "Support Request Attached File Icon",
                    "Support Request Speech Bubble Background",
                    "Support Request Message Sent Checkmark",
                ]
            },
            "terms-accepted": {
                images: [
                    'Helpbook Terms Accepted Splash'
                ]
            },
            welcome: {
                images: [
                    'Sign-up Email Verification Splash',
                ]
            },
        },

    }
}

module.exports = imagePageKeys