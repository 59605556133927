import ResourceValidator from "./validators/resource.validator";


class Validator {

    constructor() {
        this.resourceValidator = new ResourceValidator();
    }
}


export default (context, inject) => {
    const validator = new Validator()

    inject('validator', validator);
};
