
const mixin = {
  data() {
    return {
      refsLoaded: {},
      refsTracking: [],
      refsTrackingInterval: null,
    };
  },
  computed: {
  },
  methods: {
    trackRefs(refNames) {
      for (const ref of refNames) {
        this.refsTracking.push(ref);
      }
      this.initRefsTrackingInterval();
    },
    checkRefsTracking() {
      const refLoadStatus = {};
      let allLoaded = true;
      for (const refName of this.refsTracking) {
        refLoadStatus[refName] = !!this.$refs[refName];
        if (!refLoadStatus[refName]) {
          allLoaded = false;
        }
      }
      this.refsLoaded = Object.assign({}, refLoadStatus);
      if (allLoaded) {
        this.clearRefsTrackingInterval();
      }
    },
    clearRefsTrackingInterval() {
      if (this.refsTrackingInterval === null) {
        return;
      }
      window.clearInterval(this.refsTrackingInterval);
    },
    initRefsTrackingInterval() {
      this.clearRefsTrackingInterval();
      this.refsTrackingInterval = window.setInterval(this.checkRefsTracking, 50);
    },
  },
  beforeDestroy() {
    this.clearRefsTrackingInterval();
  },
};

export default mixin;
