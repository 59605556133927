const profile = {
    images: [
        "Public Profile Menu Button 1 (Inactive)",
        "Public Profile Menu Button 1 (Active)",
        "Public Profile Menu Button 1 (Hover)",
        "Public Profile Menu Button 1 (OnClick)",
        "Public Profile Menu Button 2 (Inactive)",
        "Public Profile Menu Button 2 (Active)",
        "Public Profile Menu Button 2 (Hover)",
        "Public Profile Menu Button 2 (OnClick)",
        "Public Profile Menu Button 3 (Inactive)",
        "Public Profile Menu Button 3 (Active)",
        "Public Profile Menu Button 3 (Hover)",
        "Public Profile Menu Button 3 (Share)",
        "Public Profile Menu Button 4 (Inactive)",
        "Public Profile Menu Button 4 (Active)",
        "Public Profile Menu Button 4 (Hover)",
        "Public Profile Menu Button 4 (OnClick)",
        "Public Profile Menu Button 5 (Inactive)",
        "Public Profile Menu Button 5 (Active)",
        "Public Profile Menu Button 5 (Hover)",
        "Public Profile Menu Button 5 (OnClick)",
        "User Account Menu Button 1 (Inactive)",
        "User Account Menu Button 1 (Active)",
        "User Account Menu Button 1 (Hover)",
        "User Account Menu Button 1 (OnClick)",
        "User Account Menu Button 2 (Inactive)",
        "User Account Menu Button 2 (Active)",
        "User Account Menu Button 2 (Hover)",
        "User Account Menu Button 2 (OnClick)",
        "User Account Menu Button 3 (Inactive)",
        "User Account Menu Button 3 (Active)",
        "User Account Menu Button 3 (Hover)",
        "User Account Menu Button 3 (Share)",
        "User Account Menu Button 4 (Inactive)",
        "User Account Menu Button 4 (Active)",
        "User Account Menu Button 4 (Hover)",
        "User Account Menu Button 4 (OnClick)",
        "User Account Menu Button 5 (Inactive)",
        "User Account Menu Button 5 (Active)",
        "User Account Menu Button 5 (Hover)",
        "User Account Menu Button 5 (OnClick)",
        'Public Profile About Section Background',
        'User Account About Section Background',
    ],
    children: {
        id: {
            images: [],
            children: {
                about: {
                    image: [
                        'Public Profile About Section Background',
                        'User Account About Section Background',
                    ],
                },
                messaage: {
                    image: [
                        'Public Profile Message Sent Checkmark',
                    ],
                },
                resources: {
                    images: [
                        "Public Profile Portfolio Search Icon",
                        "Public Profile Portfolio Search No Results Section Background",
                        "Public Profile Portfolio Left Navigation Button",
                        "Public Profile Portfolio Left Navigation Button (Hover)",
                        "Public Profile Portfolio Left Navigation Button (OnClick)",
                        "Public Profile Portfolio Right Navigation Button",
                        "Public Profile Portfolio Right Navigation Button (Hover)",
                        "Public Profile Portfolio Right Navigation Button (OnClick)",
                        "User Account Portfolio Search Icon",
                        "User Account Portfolio Search No Results Section Background",
                        "User Account Portfolio Left Navigation Button",
                        "User Account Portfolio Left Navigation Button (Hover)",
                        "User Account Portfolio Left Navigation Button (OnClick)",
                        "User Account Portfolio Right Navigation Button",
                        "User Account Portfolio Right Navigation Button (Hover)",
                        "User Account Portfolio Right Navigation Button (OnClick)",
                    ]
                },
                share: {
                    images: [
                        'Public Profile Portfolio Share Link Generated Checkmark',
                        'User Account Portfolio Share Link Generated Checkmark',
                    ]
                },
                settings: {
                    image: [
                        "User Account Settings Menu Navigation Up Button",
                        "User Account Settings Menu Navigation Down Button",
                        "User Account Settings Update Checkmark",
                        "User Account Setting Checkmark",
                        "User Account System Avatar: system_avatar_1.svg",
                        "User Account System Avatar: system_avatar_2.svg",
                        "User Account System Avatar: system_avatar_3.svg",
                        "User Account System Avatar: system_avatar_4.svg",
                        "User Account System Avatar: system_avatar_5.svg",
                        "User Account System Avatar: system_avatar_6.svg",
                        "User Account System Avatar: system_avatar_7.svg",
                        "User Account System Avatar: system_avatar_8.svg",
                        "User Account System Avatar: system_avatar_9.svg",
                        "User Account System Avatar: system_avatar_10.svg",
                        "User Account System Avatar: system_avatar_11.svg",
                        "User Account System Avatar: system_avatar_12.svg",
                        "User Account System Avatar: system_avatar_13.svg",
                        "User Account System Avatar: system_avatar_14.svg",
                        "User Account System Avatar: system_avatar_15.svg",
                        "User Account System Avatar: system_avatar_16.svg",
                        "User Account System Avatar: system_avatar_17.svg",
                        "User Account System Avatar: system_avatar_18.svg",
                        "User Account System Avatar: system_avatar_19.svg",
                        "User Account System Avatar: system_avatar_20.svg",
                        "User Account System Avatar: system_avatar_21.svg",
                        "User Account System Avatar: system_avatar_22.svg",
                        "User Account System Avatar: system_avatar_23.svg",
                        "User Account System Avatar: system_avatar_24.svg",
                        "User Account System Avatar: system_avatar_25.svg",
                        "User Account System Avatar: system_avatar_26.svg",
                        "User Account System Avatar: system_avatar_27.svg",
                        "User Account System Avatar: system_avatar_28.svg",
                        "User Account System Avatar: system_avatar_29.svg",
                        "User Account System Avatar: system_avatar_30.svg",
                        "User Account System Avatar: system_avatar_31.svg",
                        "User Account System Avatar: system_avatar_32.svg",
                        "User Account System Avatar: system_avatar_33.svg",
                        "User Account System Avatar: system_avatar_34.svg",
                        "User Account System Avatar: system_avatar_35.svg",
                        "User Account System Avatar: system_avatar_36.svg",
                    ],
                },
                tip: {
                    image: [
                        'Public Profile Tipping Navigation Up Button',
                        'Public Profile Tipping Navigation Down Button',
                        'Public Profile Tipping Navigation Up Button (Inactive)',
                        'Public Profile Tipping Navigation Down Button (Inactive)',
                    ],
                },
            }
        },
        tipsummary: {
            images: [

            ]
        },
        tipcompleted: {
            images: [
                'Checkout Tipping Payment Background (Thank You)',
                'Checkout Individual Tipping Payment Background',
                'Checkout Tipping Prompt Payment Background'
            ]
        }
    }
}

module.exports = profile