
export const orderFormBaseState = {
  loading: false,

  paymentSource  : 'credit-card',
  secureTokenId  : null,
  secureToken    : null,

  // cardNumber    : '2221000000000009',
  // cardExp       : '1025',
  // cardCvv       : '123',
  cardNumber     : '',
  cardExp        : '',
  cardCvv        : '',
  cardName       : '',
  billingZipCode : '',
  billingStreetAddress: '',
  billingCity: '',
  billingState: '',

};

export const orderFormBaseGetters = {
  isPaymentInfoCompleted(state, getters, externalState) {
    const inQuickMode = externalState.main.siteSettings?.quickCheckout;
    let required = ['cardName', 'cardNumber', 'cardExp', 'cardCvv'];
    if (!inQuickMode) {
      required = required.concat(['billingZipCode', 'billingStreetAddress', 'billingCity', 'billingState']);
    }
    if (state.paymentSource === 'credit-card') {
      for (const requiredField of required) {
        if (!state[requiredField]) {
          return false;
        }
      }
    }
    return true;
  },
};
