

const donate = {
    images: [
        "Checkout Donation Download Limited Payment Background",
        "Checkout Donation User Selected Payment Background"
    ],
    children: {
        completed: {
            images: [
                'Checkout Donation Payment Background (Thank you)'
            ]
        }
    }
}

module.exports = donate;