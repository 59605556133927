import { createRipple } from "~/lib/ripple-effect";

export default (context, inject) => {

  const doRipple = (event, callback = false) => {
    createRipple(event)
    if (callback && typeof callback === "function") {
      callback()
    }
  }

  const doAsyncRipple = async (event) => {
    await new Promise(resolve => resolve(createRipple(event)))
    return await new Promise(resolve => resolve(setTimeout(resolve, 500)))
    // callback() 
  }

  inject('ripple', doRipple);
  inject('asyncRipple', doAsyncRipple);
};
