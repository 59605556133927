
import { orderFormBaseState, orderFormBaseGetters } from '@/mixins/OrderFormStore';
import { modalBaseGetters } from '@/mixins/ModalStore';

const B = 1;
const KB = B * 1000;
const MB = KB * 1000;

export const state = () => (Object.assign({}, orderFormBaseState, {
  precheckComplete: false,
  amount: 3,
  placement: 'prompt',
  error: null,
  titleError: false,
  titleApproved: false,
  isResourceNew: null,
  previewIndex: 0,
  selectedResource: {},
  defaultResourceDetails: {
    title: '',
    display: '',
    subtitle: '',
    subject_area: '',
    description: '',
    keywords: '',
    skills: '',
    skillsArray: [],
    grade: '',
    standards: '',
    standardsArray: [],
    reading_levels: '',
    keywords: '',
    keywordsArray: [],
  },
  justFavorited: false,
  justUnfavorited: false,
  shareUrl: '',
  giftUrl: '',
  favoriteLimitExceeded: false,
  files: [],
  fileSizeError: false,
  // imageMinWidth  : 680,
  // imageMinHeight : 880,
  imageMinWidth: 346 * 2,
  imageMinHeight: 448 * 2,
  imageFileSizeMax: 1000 * 1000, // 1MB
  acceptedImageFileExtensions: [
    'jpg',
    'jpeg',
    'png',
    'bmp',
    'gif',
  ],
  previewImages: [],
  generatingPreviews: false,

  deletionCancelReturnUrl: null,

  infoAd: null,
  downloadAd: null,
  downloadsFromCreator: 0,
  tippingBackgroundKey: 'Checkout Individual Tipping Payment Background',
  priceError: null,
  salePriceError: null,
  saleEndError: null,
  priceErrors: {
    isDateInvalid: false,
    isDateNotInFuture: false,
    isDatePastThirtyDays: false,
    isPriceTooHigh: false,
    isSalePriceTooHigh: false,
    isSalePriceHigherThanPrice: false,
  }
}));

export const allSteps = [
  // TODO TODO TODO include in text editor
  { step: 0, label: 'Pre-check', to: 'pre-check' },

  { step: 1, label: 'Title', name: 'title', to: 'title', is_required: true, ariaLabel: 'alt-publishing-title' },
  { step: 2, label: 'Categories', name: 'categories', to: 'categories', is_required: true, ariaLabel: 'alt-publishing-resource-type' },
  // { step: 3, label: 'Subject Area', name: 'subject_area', to: 'subject-area', is_required: true, ariaLabel: 'alt-publishing-subject-area' },
  { step: 3, label: 'Description', name: 'description', to: 'description', is_required: true, ariaLabel: 'alt-publishing-description' },
  { step: 4, label: 'Grade', name: 'grade', to: 'grade', is_required: true, ariaLabel: 'alt-publishing-grade' },
  { step: 5, label: 'Skills', name: 'skills', to: 'skills', is_required: false, ariaLabel: 'alt-publishing-skills' },
  { step: 6, label: 'Standards', name: 'standards', to: 'standards', is_required: false, ariaLabel: 'alt-publishing-standards' },
  { step: 7, label: 'Reading Level', name: 'reading_levels', to: 'reading-level', is_required: false, ariaLabel: 'alt-publishing-reading-level' },
  { step: 8, label: 'Keywords', name: 'keywords', to: 'keywords', is_required: false, ariaLabel: 'alt-publishing-keywords' },
  { step: 9, label: 'Files', name: 'files', to: 'files', is_required: true, ariaLabel: 'alt-publishing-files' },
  { step: 10, label: 'Images', name: 'images', to: 'images', is_required: true, ariaLabel: 'alt-publishing-images' },
  { step: 11, label: 'Price', name: 'price', to: 'price', is_required: true, ariaLabel: 'alt-publishing-price' },
  { step: 12, label: 'Review', name: 'review', to: 'review', is_required: false, ariaLabel: 'alt-publishing-review' },
  { step: 12, label: 'Status', name: 'status', to: 'status', is_required: false, ariaLabel: 'alt-publishing-status' },

  { step: 99, label: 'Delete', to: 'delete' },
];

export const getters = Object.assign({}, modalBaseGetters, orderFormBaseGetters, {
  modalCloseWarning() {
    if ($nuxt.$route.name?.startsWith('index-resources-id-edit')
      || $nuxt.$route.name?.startsWith('index-resources-id-feature')
      || $nuxt.$route.name?.startsWith('index-resources-id-success')
      // || $nuxt.$route.name?.startsWith('index-resources-id-donation')
      ) {
      return true;
    }
    return false;
  },
  modalCloseEnabled(state) {
    if ($nuxt.$route.name?.startsWith('index-resources-id-donation')) {
      return false;
    }
    return true;
  },

  statusList(_state, _getters, externalState, _externalGetters) {
    const statusList = {
      inactive: {
        title: externalState.main.texts['resources--title step 12 inactive'],
        subtitle: externalState.main.texts['resources--subtitle get it approved'],
        description: externalState.main.texts['resources--instructional text inactive']
      },
      inReview: {
        title: externalState.main.texts['resources--title step 12 in review'],
        subtitle: externalState.main.texts['resources--subtitle resource submitted'],
        description: externalState.main.texts['resources--instructional text in review']
      },
      active: {
        title: externalState.main.texts['resources--title step 12 active'],
        subtitle: externalState.main.texts['resources--subtitle successfully published'],
        description: externalState.main.texts['resources--instructional text active']
      },
      'admin-active': {
        title: externalState.main.texts['resources--title step 12 active'],
        subtitle: externalState.main.texts['resources--subtitle successfully published'],
        description: externalState.main.texts['resources--instructional text admin active']
      },
      'admin-deactivated': {
        title: externalState.main.texts['resources--title step 12 inactive'],
        subtitle: externalState.main.texts['resources--subtitle review and publish'],
        description: externalState.main.texts['resources--instructional text admin manual deactivation']
      },
      // Shadow the deactivated state
      'admin-inactive': {
        title: externalState.main.texts['resources--title step 12 inactive'],
        subtitle: externalState.main.texts['resources--subtitle review and publish'],
        description: externalState.main.texts['resources--instructional text admin manual deactivation']
      },
      rejected: {
        title: externalState.main.texts['resources--title step 12 inactive'],
        subtitle: externalState.main.texts['resources--subtitle pending correction'],
        description: externalState.main.texts['resources--instructional text pending correction']
      },
      incomplete: {
        title: externalState.main.texts['resources--title step 12 incomplete'],
        subtitle: externalState.main.texts['resources--subtitle correction required'],
        description: externalState.main.texts['resources--instructional text incomplete']
      },
      deactivated: {
        title: externalState.main.texts['resources--title step 12 inactive'],
        subtitle: externalState.main.texts['resources--subtitle not showing'],
        description: externalState.main.texts['resources--instructional text manual deactivation']
      },
    };

    return statusList;
  },

  stepList(_state, _getters, externalState, externalGetters) {
    const stepList = allSteps.slice(1, 12);

    for (const step of stepList) {
      step.label = externalState.main.texts['resources--menu step button ' + step.step.toString()];
    }

    if (externalGetters['main/isAdmin']) {
      stepList.push({ step: 12, label: externalState.main.texts['resources--menu step button 12 admin'], to: 'review' });
    } else {
      stepList.push({ step: 12, label: externalState.main.texts['resources--menu step button 12'], to: 'status' });
    }

    return stepList;
  },

  currentStep(state, getters) {
    const path = $nuxt.$route.path;
    const pathParts = path.split('/');
    const pathBaseName = pathParts[pathParts.length - 1];

    return getters.stepList.find(step => step.to === pathBaseName);
  },

  combinedFileSize(state) {
    return state.files.reduce((prev, curr) => {
      if (curr.resourceFile.is_premium_file) {
        return prev;
      }
      return prev + (curr.resourceFile.size_bytes)
    }, 0);
  },

  combinedFileSizeLimit(state, getters, externalState) {
    const limit = externalState.main.siteSettings.maxCombinedFileSize;
    return limit * MB;
  },

  coverImage(_state, getters) {
    const previews = getters.activePreviewImages.slice(0).filter(image => image.selected);
    previews.sort((a, b) => {
      if (a.resourcePreview.order_index > b.resourcePreview.order_index) { return 1; }
      if (a.resourcePreview.order_index < b.resourcePreview.order_index) { return -1; }
      return 0;
    });
    const firstPreview = previews[0];
    return firstPreview || null;
  },

  previewIndex(state) {
    return state.previewIndex
  },

  activePreviewImages(state) {
    return state.previewImages.filter(x => !x.isDeleted);
  },

  previewImagesByLocalId(state) {
    const images = {};
    for (const image of state.previewImages) {
      images[image.localId] = image;
    }
    return images;
  },

  previewImagesSorted(state) {
    const images = state.previewImages.slice(0);
    images.sort((a, b) => {
      if (a.resourcePreview.order_index > b.resourcePreview.order_index) { return 1; }
      if (a.resourcePreview.order_index < b.resourcePreview.order_index) { return -1; }
      return 0;
    });
    return images;
  },

  previewImageFileItems(state, _getters) {
    const items = [];
    for (let i = 0; i < 3; i++) {
      items.push({ isPlaceholder: true, targetOrderIndex: i });
    }

    for (const image of state.previewImages) {
      const targetOrderIndex = image.resourcePreview.order_index;
      if (targetOrderIndex <= 2) {
        items[targetOrderIndex] = image;
      } else {
        items.push(image);
      }
    }

    return items;
  },

  areAllRequiredFieldsComplete(_state, getters) {

    const requiredFields = [
      'title',
      'description',
      'categories',
      'grade',
      'files',
      'images',
      'price',
      'sale_price',
      'sale_end'
    ];

    const completionState = getters.fieldCompletionStates;
    for (const field of requiredFields) {
      if (!completionState[field]) {
        return false;
      }
    }

    return true;
  },

  textFieldRequirements(_state) {
    return {
      title: {
        minChars: 1,
        maxChars: 32,
      },
      display: {
        minChars: 1,
        maxChars: 32,
      },
      subtitle: {
        minChars: 1,
        maxChars: 25,
      },
      subject_area: {
        minChars: 4,
        maxChars: 15,
      },
      description: {
        minChars: 340,
        maxChars: 380,
      },
      reading_levels: {
        minChars: 1,
        maxChars: 40,
      },
      grade: {
        minChars: 1,
        maxChars: Infinity,
      },
      skills: {
        minChars: 1,
        maxChars: 20,
      },
      standards: {
        minChars: 1,
        maxChars: 20,
      },
      keywords: {
        minChars: 1,
        maxChars: 20,
      },
      price: {
        minChars: 1,
        maxChars: 100,
      },
    };
  },

  fieldCompletionStates(state, getters) {
    const states = {};
    const textFields = [
      'title',
      "display",
      'subtitle',
      'subject_area',
      'description',
      'reading_levels',
      'grade'
    ];
    const textFieldRequirements = getters.textFieldRequirements;
    for (const textField of textFields) {
      const value = state.selectedResource[textField] || '';
      const minChars = textFieldRequirements[textField].minChars;
      let maxChars = textFieldRequirements[textField].maxChars;
      let textFieldToUse = ["subtitle", "subject_area"].includes(textField) ? "categories" : textField;
      if (textFieldToUse === "title") {
        const display = state.selectedResource.display || '';
        if (display.length > 0) {
          maxChars = 48;
        }
      }
      states[textFieldToUse] = (value.length >= minChars && value.length <= maxChars);
      states[textField] = (value.length >= minChars && value.length <= maxChars);
    }

    if (!state.titleApproved) {
      states.title = false;
    }

    const uploadedFiles = state.files.filter(file => file.resourceFile.id);
    states.files = uploadedFiles.length >= 1;

    const uploadedPreviews = state.previewImages.filter(image => image.resourcePreview.id);
    states.images = uploadedPreviews.length >= 1;

    const price = parseFloat(state.selectedResource['price']) || 0;
    const premium = uploadedFiles ? uploadedFiles.find(file => file.resourceFile.is_premium_file) : false;

    states['price'] = state.priceError === null;
    states['sale_price'] = state.salePriceError === null;
    states['sale_end'] = state.saleEndError === null;

    if (price > 0) {
      if (uploadedPreviews.length < 3) {
        states.images = false;
      }
      if (!premium) {
        states.files = false;
      }
    } else {
      if (premium) {
        states['price'] = false
      }
    }
    // states['sale_price'] = true;
    // states['sale_end'] = true;
    // if (price > 0) {
    //   if (price < 1) {
    //     states['price'] = false;
    //   } else if (price > 100) {
    //     states['price'] = false;
    //   } else {
    //     
    //     const salePrice = parseFloat(state.selectedResource['sale_price']) || 0;
    //     if (salePrice > 0) {
    //       if (salePrice < 1) {
    //         states['sale_price'] = false;
    //       } else if (salePrice > 100) {
    //         states['sale_price'] = false;
    //       } else {
    //         if (state.selectedResource['stalking'] === 0) {
    //           const date = new Date(state.selectedResource['sale_end']);
    //           //  iff date is in the furture but within 30 days true, else false
    //           if (date > new Date()) {
    //             const diff = date.getTime() - new Date().getTime();
    //             const days = diff / (1000 * 3600 * 24);
    //             if (days > 30) {
    //               states['sale_end'] = false;
    //             }
    //           } else {
    //             states['sale_end'] = false;
    //           }
    //         }
    //       }
    //     }
    //   }

    //   if (states['sale_price'] === true || states['sale_end'] === true) {
    //     states['price'] = true;
    //   }
    // }

    const arrayFields = ['skills', 'standards', 'keywords']
    for (const arrayField of arrayFields) {
      const key = `${arrayField}Array`;
      const values = state.selectedResource[key];
      let allValid = true;

      const minChars = textFieldRequirements[arrayField].minChars;
      const maxChars = textFieldRequirements[arrayField].maxChars;

      for (const value of values) {
        const valid = (value.length >= minChars && value.length <= maxChars);
        if (!valid) {
          allValid = false;
          break;
        }
      }
      states[arrayField] = values.length >= 1 && allValid;
    }
    return states;
  },

  currentStatus(state, getters) {
    const isComplete = getters.areAllRequiredFieldsComplete;
    if (!isComplete) {
      return 'incomplete';
    }

    const resource = state.selectedResource;

    if (resource.approval_status === 'awaiting_approval') {
      return 'inReview';
    }

    if (resource.approval_status === 'rejected') {
      return 'rejected';
    }

    if (resource.approval_status === 'approved' && resource.status === 'active') {
      return 'active';
    }

    if (resource.approval_status === 'approved' && !resource.requires_approval && resource.status === 'inactive') {
      return 'deactivated';
    }

    return 'inactive';
  },

  isFeaturedResource(state) {
    return state.selectedResource.is_featured;
  },

  canEdit(_state, getters) {
    const disabledStates = new Set(['active', 'inReview']);
    if (disabledStates.has(getters.currentStatus)) {
      return false;
    }
    return true;
  },

  isFavorited(state) {
    return state.selectedResource?.isFavorited;
  },

  isCreatorFollowed(state) {
    return state.selectedResource?.isCreatorFollowed;
  },


});

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;

      // if ([
      //   'price',
      //   'sale_price',
      //   'sale_end'
      // ].includes(key)) {
      //   switch (key) {
      //     case 'price':
      //       if (val > 100) {
      //         state.priceErrors.isPriceTooHigh = true;
      //       }
      //       break;
      //     case 'sale_price':
      //       if (val > 100) {
      //         state.priceErrors.isSalePriceTooHigh = true;
      //         break
      //       }
      //       if (val > state.selectedResource.price) {
      //         state.priceErrors.isSalePriceHigherThanPrice = true;
      //         break
      //       }
      //       break;
      //     case 'sale_end':
      //       if (state.selectedResource.stalking > 0) {
      //         break;
      //       } else {
      //         const dateSplit = val.split('/');
      //         if (dateSplit.length !== 3) {
      //           state.priceErrors.isDateInvalid = true
      //           break
      //         }
      //         const [month, day, year] = dateSplit;
      //         const now = new Date();
      //         const date = new Date(year, parseInt(month) - 1, day);

      //         if (date.toString() === "Invalid Date") {
      //           state.priceErrors.isDateInvalid = true
      //           break
      //         }

      //         if (date < now) {
      //           state.priceErrors.isDateNotInFuture = true
      //           break
      //         }

      //         date.setDate(date.getDate() + 30)

      //         if (date > now) {
      //           state.priceErrors.isDatePastThirtyDays = true
      //           break
      //         }
      //       }
      //     default:
      //       break;
      //   }
      // }
    }
  },

  setResourceDetails(state, details) {
    state.selectedResource = details;
  },

  addFile(state, fileData) {
    state.files.push(fileData);
  },

  setFileUploadProgress(state, { tempId, progress }) {
    const fileIndex = state.files.findIndex(f => f.tempId === tempId);
    if (fileIndex === -1) { return; }
    state.files[fileIndex].uploadProgress = progress;
  },

  finishFileUpload(state, { tempId, resourceFile }) {
    const fileIndex = state.files.findIndex(f => f.tempId === tempId);
    if (fileIndex === -1) { return; }
    state.files[fileIndex].uploadProgress = 1;
    state.files[fileIndex].resourceFile = resourceFile;
  },

  deleteFile(state, fileId) {
    const fileIndex = state.files.findIndex(f => f.resourceFile.id === fileId);
    if (fileIndex === -1) { return; }
    state.files.splice(fileIndex, 1);
  },

  deletePreview(state, previewId) {
    const index = state.previewImages.findIndex(f => f.resourcePreview.id === previewId);
    if (index === -1) { return; }
    state.previewImages.splice(index, 1);
  },

  hideFileSizeLimitError(state) {
    state.fileSizeError = false;
  },

  showFileSizeLimitError(state) {
    state.fileSizeError = true;
  },

  setSelectedResource(state, resource) {
    if (resource === null) {
      state.selectedResource = state.defaultResourceDetails;
      state.previewImages = [];
      state.files = [];
      return;
    }

    state.selectedResource = resource;
    if (resource.resources_files_models) {
      state.files = resource.resources_files_models.map(fileModel => ({
        status: 1,
        uploadProgress: 1,
        resourceFile: fileModel,
      }));
    }

    if (resource.resources_previews_models) {
      state.previewImages = resource.resources_previews_models.map(previewModel => ({
        localId: ('' + Math.random()).slice(2),
        selected: true,
        pendingAction: null,
        resourcePreview: previewModel,
      }));
    }
  },

  clearSelectedResource(state) {
    state.selectedResource = state.defaultResourceDetails;
    state.previewImages = [];
    state.files = [];
  },

  addPreviewImage(state, image) {
    state.previewImages.push(image);
  },

  togglePreviewImage(state, index) {
    const image = state.previewImages[index];
    const selecting = !image.selected;

    state.previewImages[index].selected = selecting;
    if (!selecting) {
      state.previewImages[index].resourcePreview.order_index = 999;
    }

    // already uploaded
    if (image.resourcePreview.id && selecting) {
      state.previewImages[index].pendingAction = 'reorder';
      return;
    }

    if (image.resourcePreview.id && !selecting) {
      state.previewImages[index].pendingAction = 'delete';
      return;
    }

    // not uploaded yet
    if (!image.resourcePreview.id && selecting) {
      state.previewImages[index].pendingAction = 'upload';
      return;
    }

    if (!image.resourcePreview.id && !selecting) {
      state.previewImages[index].pendingAction = null;
      return;
    }
  },

  normalizeOrderIndices(state) {
    const previews = state.previewImages.slice(0);
    previews.sort((a, b) => {
      if (a.resourcePreview.order_index > b.resourcePreview.order_index) { return 1; }
      if (a.resourcePreview.order_index < b.resourcePreview.order_index) { return -1; }
      return 0;
    });

    for (let index = 0; index < previews.length; index++) {
      const preview = previews[index];
      preview.resourcePreview.order_index = index;
    }
    // commit('setOrderIndices', orderIndexForLocalId);
  },

  setOrderIndex(state, { localId, orderIndex }) {
    const image = state.previewImages.find(image => image.localId === localId);
    if (!image) { return; }
    image.resourcePreview.order_index = orderIndex;
  },

  finishImageUpload(state, { localId, resourcePreview }) {
    const preview = state.previewImages.find(image => image.localId === localId);
    if (!preview) { return; }

    preview.resourcePreview = resourcePreview;
    preview.pendingAction = null;
  },

  deletePreviewImage(state, localId) {
    const index = state.previewImages.findIndex(image => image.localId === localId);
    if (index === -1) { return; }
    state.previewImages.splice(index, 1);
  },
  setPriceError(state, error) {
    state.priceError = error;
  },
  setSalePriceError(state, error) {
    state.salePriceError = error;
  },
  setSaleEndError(state, error) {
    state.saleEndError = error;
  },

  setPrice(state, priceToSet) {
    // if (
    //   priceToSet === null
    //   || priceToSet > 100
    //   || (priceToSet > 0 && priceToSet < 1)
    // ) {
    //   state.priceError = "invalid_price"
    // }

    state.selectedResource.price = parseFloat(priceToSet);
  },
  setSalePrice(state, salePriceToSet) {
    // if (
    //   salePriceToSet === null
    //   || salePriceToSet > 100
    //   || (salePriceToSet > 0 && salePriceToSet < 1)
    // ) {
    //   state.priceError = "invalid_sale_price"
    // }

    // if (salePriceToSet > state.selectedResource.price) {
    //   state.priceError = "invalid_sale_price_high"
    // }

    state.selectedResource.sale_price = parseFloat(salePriceToSet);
  }

};

export const actions = {

  async nextPage({ getters, dispatch }) {
    const currStepNumber = getters.currentStep.step;
    const nextStepNumber = currStepNumber + 1;

    const nextStep = getters.stepList.find(step => step.step === nextStepNumber);

    await dispatch('saveIfEditable');

    $nuxt.$router.push(`./${nextStep.to}`);
  },

  async saveIfEditable({ getters, dispatch }) {
    if (getters.canEdit) {
      await dispatch('saveResourceChanges');
    }
  },

  async loadResourceDetails({ state, commit, dispatch }, routeId) {
    let id = routeId;
    let isNameMode = false;
    if (routeId.hasOwnProperty('name')) {
      id = routeId.name;
      isNameMode = true;
    }
    if (id === 'new') {
      commit('set', {
        precheckComplete: false,
        isResourceNew: true,
      });
      commit('setSelectedResource', null);
      return;
    }

    const response = await this.$sdk.get(`/resources/${id}`, {
      name: isNameMode,
    });
    const responseJson = await response.json();
    if (responseJson.resource) {
      commit('set', {
        precheckComplete: true,
        titleError: false,
        titleApproved: true,
      });
      commit('setSelectedResource', responseJson.resource);
      if (state.isResourceNew === null) {
        commit('set', { isResourceNew: false });
      }

      await dispatch('resource-grid/updateResourceData', { resource: responseJson.resource }, { root: true });
      return responseJson.resource;
    }
    commit('set', {
      precheckComplete: false,
      isResourceNew: true,
    });
    return false

  },

  async setIsFeaturedResource({ dispatch }) {
    return await dispatch('saveResourceChanges');
  },

  async updateResourceTitle({ state, commit, dispatch }) {
    if (state.selectedResource?.title.length === 0) {
      return false;
    }

    const success = await dispatch('saveResourceChanges');
    if (success) {
      commit('set', {
        titleApproved: true,
        titleError: false,
      });
    }
    else {
      commit('set', {
        titleApproved: false,
        titleError: true,
      });
    }
    return success;
  },

  async loadResourceAds({ commit }, previewData = null) {
    const promises = [
      previewData?.infoAdId
        ? this.$sdk.get(`/advertisements/${previewData.infoAdId}`)
        : this.$sdk.get('/advertisements/display', { type: '3' }),
      previewData?.downloadAdId
        ? this.$sdk.get(`/advertisements/${previewData.downloadAdId}`)
        : this.$sdk.get('/advertisements/display', { type: '4' })
    ];

    const [response1, response2] = await Promise.all(promises);

    const response1Json = await response1.json();
    const response2Json = await response2.json();
    const data = {
      infoAd: response1Json.ad,
      downloadAd: response2Json.ad
    }
    commit('set', data);
    return data
  },

  async saveResourceChanges({ state, commit, dispatch }) {
    if (!state.isUpdating) {
      const selectedResource = state.selectedResource;

      if (selectedResource?.title.length === 0) {
        return;
      }

      let request;
      if (!selectedResource.id) {
        request = this.$sdk.post(`/resources`, selectedResource);
      } else {
        request = this.$sdk.patch(`/resources/${selectedResource.id}`, selectedResource);
      }

      const response = await request;
      if (!response.ok) {
        commit('set', { error: `Could not save changes` });
        return false;
      }
      const responseJson = await response.json();
      commit('set', { titleError: false });
      await dispatch('loadResourceDetails', responseJson.resource.id);

      if (
        !selectedResource.id &&
        $nuxt.$store.state['resource-grid'].gridBaseRoute === "/portfolio"
      ) {
        dispatch('resource-grid/clearResources', null, { root: true }).then(() => {
          dispatch('resource-grid/loadPortfolioResources', null, { root: true });
        });
        return true;
      }

      await dispatch('resource-grid/updateResourceData', { resource: responseJson.resource }, { root: true });
    }
    return true;
  },

  async addFile({ state, getters, commit, dispatch }, file) {
    if (!file.isPremium) {
      const combinedFileSize = getters.combinedFileSize + file.size;
      if (combinedFileSize > getters.combinedFileSizeLimit) {
        commit('showFileSizeLimitError');
        return;
      }
    }

    commit('hideFileSizeLimitError')

    const tempId = file.isPremium ? 'premium' : Math.random();
    commit('addFile', {
      tempId: tempId,
      uploadProgress: 0,
      upload: file,
      resourceFile: {
        name: file.name,
        size_bytes: file.size,
        is_premium_file: file.isPremium
      },
    });
    const formData = new FormData();
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    xhr.open('POST', `/api/resources/${state.selectedResource.id}/files${file?.isPremium ? '?demo=true' : ''}`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${this.$sdk.accessToken}`);

    xhr.upload.addEventListener(
      "progress",
      function (event) {
        if (!event.lengthComputable) {
          return;
        }
        const progress = event.loaded / event.total;
        commit('setFileUploadProgress', { tempId, progress });
      },
      false
    );

    xhr.onreadystatechange = async function () {
      if (xhr.readyState == 4) {
        await dispatch('loadResourceDetails', state.selectedResource.id);
      }
    };
    xhr.send(formData);

  },

  async deleteFile({ state, commit, dispatch }, fileId) {
    const response = await this.$sdk.delete(`/resources/files/${fileId}`);
    if (!response.ok) {
      window.alert(`Could not delete file!`);
      return;
    }
    commit('deleteFile', fileId);
    await dispatch('loadResourceDetails', state.selectedResource.id);
  },

  async getImageBase64({ }, file) {
    try {
      const base64 = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = async () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(file);
      })
      return base64;
    }
    catch (e) {
      return null;
    }
  },

  getImageObject({ }, base64) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = function () {
        return resolve(image);
      };
      image.src = base64;
    });
  },

  async addPreviewImage({ state, commit, dispatch }, { file, orderIndex }) {
    if (!file) {
      return;
    }

    if (file.size > state.imageFileSizeMax) {
      throw new Error('too_large');
    }

    const base64 = await dispatch('getImageBase64', file);
    const imageObject = await dispatch('getImageObject', base64);
    if (
      imageObject.width < state.imageMinWidth ||
      imageObject.height < state.imageMinHeight
    ) {
      throw new Error('insufficient_dimensions');
    }

    const localId = ('' + Math.random()).slice(2);

    const image = {
      localId,
      file,
      resourcePreview: {
        order_index: orderIndex,
        size_bytes: file.size,
      },
      base64,
      pendingAction: 'upload',
      selected: true,
    };

    commit('addPreviewImage', image);

    await dispatch('uploadPreviewImage', image);
  },

  async togglePreviewImage({ state, getters, commit, dispatch }, index) {
    const coverImage = getters.coverImage;
    const image = state.previewImages[index];
    const willSelect = !image.selected;

    commit('togglePreviewImage', index);

    if (!coverImage && willSelect) {
      await dispatch('setCoverImage', image.localId);
      return;
    }

    commit('normalizeOrderIndices');
  },

  async processPreviewImages({ state, dispatch }) {
    const toProcess = state.previewImages.filter(image => image.pendingAction);

    const promises = [];
    for (const image of toProcess) {
      if (image.pendingAction === 'upload') {
        promises.push(dispatch('uploadPreviewImage', image));
      }
      else if (image.pendingAction === 'reorder') {
        promises.push(dispatch('updatePreviewImage', image));
      }
      else if (image.pendingAction === 'delete') {
        promises.push(dispatch('deletePreviewImage', image));
      }
    }

    await Promise.all(promises);
  },

  async uploadPreviewImage({ state, dispatch }, image) {
    return await new Promise((resolve, reject) => {
      const formData = new FormData();
      if (image.file) {
        formData.append("file", image.file);
      }
      else if (image.generatedPreviewName) {
        formData.append("generatedPreviewName", image.generatedPreviewName);
      }
      formData.append("orderIndex", image.resourcePreview.order_index);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `/api/resources/${state.selectedResource.id}/previews`, true);
      xhr.setRequestHeader('Authorization', `Bearer ${this.$sdk.accessToken}`);

      // xhr.onprogress = function(event) {
      //   const progress = event.loaded / event.total;
      //   commit('setFileUploadProgress', { tempId, progress });
      // };

      xhr.onreadystatechange = async function () {
        if (xhr.readyState == 4) {
          const response = JSON.parse(xhr.response);
          // commit('setSelectedResource', response.resource);
          await dispatch('loadResourceDetails', response?.resource?.id ? response.resource.id : state.selectedResource.id);
          // commit('finishImageUpload', { localId: image.localId, resourcePreview: response.resourcePreview });
          resolve(response);
          return;
        }
      };

      // xhr.onload = function(e) {};
      xhr.send(formData);
    });
  },

  async updatePreviewImage(_, image) {
    return await this.$sdk.patch(`/resources/previews/${image.resourcePreview.id}`, image.resourcePreview);
  },

  async deletePreviewImage({ state, commit, dispatch }, image) {
    await this.$sdk.delete(`/resources/previews/${image.resourcePreview.id}`);

    commit('deletePreview', image.resourcePreview.id);
    await dispatch('loadResourceDetails', state.selectedResource.id);
  },

  async setCoverImage({ state, getters, commit }, localId) {
    commit('setOrderIndex', { localId, orderIndex: -1 });
    commit('normalizeOrderIndices');
  },

  /**
   * @deprecated
   */
  async populateGeneratedPreviews({ state, commit }) {
    commit('set', { generatingPreviews: true });
    const response = await this.$sdk.get(`/resources/${state.selectedResource.id}/previews/generated`);
    commit('set', { generatingPreviews: false });
    const responseJson = await response.json();

    const previewImages = responseJson.previews.map(preview => ({
      localId: ('' + Math.random()).slice(2),
      selected: false,
      pendingAction: null,
      resourcePreview: {
        order_index: 999,
      },
      locator: preview.locator,
      generated: true,
      generatedPreviewName: preview.name,
    }));

    commit('set', { previewImages });
  },
  async validateAllPrice({dispatch}) {
    await dispatch('validatePrice')
    await dispatch('validateSalePrice')
    await dispatch('validateSaleEnd')
    return true
  },
  async validatePrice({state, commit}, value) {
    const d = {...state.selectedResource}
    if (value) {
      d.price = value
    }
    const r = this.$validator.resourceValidator.validatePrice(d)
    if (r === 1) {
      commit('setPriceError',  "invalid_price")
    } else {
      commit('setPriceError',  null)
    }

    return r
  },
  async validateSalePrice({state, commit}, value) {
    const d = {...state.selectedResource}
    if (value) {
      d.sale_price = value
    }

    const r = this.$validator.resourceValidator.validateSalePrice(d)
    if (r === 0) {
      commit('setSalePriceError',  null)
    } else if (r === 1) {
      commit('setSalePriceError',  "invalid_sale_price")
    } else if (r === 2) {
      commit('setSalePriceError',  "invalid_sale_price_high")
    } else if (r === 3) {
      commit('setSalePriceError',  "sale_price_min_with_date")
    } else {
      commit('setSalePriceError',  null)
    }

    return r
  },
  async validateSaleEnd({state, commit}, value) {
    const d = {...state.selectedResource}
    if (value) {
      d.sale_end = value
    }
    const r = this.$validator.resourceValidator.validateSaleEnd(d)

    if (r === 0) {
      commit('setSaleEndError',  null)
    } else if (r === 1) {
      commit('setSaleEndError',  "sale_end_invalid")
    } else if (r === 2) {
      commit('setSaleEndError',  "sale_end_in_past")
    } else if (r === 3) {
      commit('setSaleEndError',  "sale_end_not_within_thirty_days")
    } else if (r === 4) {
      commit('setSaleEndError',  "sale_end_empty")
    } else if (r === 5) {
      commit('setSaleEndError',  "sale_price_min_with_date")
    } else {
      commit('setSaleEndError',  null)
    }

    return r
  },

  async cleanSelectedResource({ state, commit, rootGetters }, area = null) {
    const replaceFunc = rootGetters['main/restrictedWordsReplace'];
    let fields = [
      'description', 'subject_area', 'subtitle', 'reading_levels',
    ];
    let multi_fields = [
      'keywordsArray', 'skillsArray', 'standardsArray',
    ];

    if (area !== null) {
      if (fields.includes(area)) {
        fields = [area];
        multi_fields = [];
      } else if (multi_fields.includes(area)) {
        fields = [];
        multi_fields = [area];
      }
    }

    for (const field of fields) {
      if (typeof state.selectedResource[field] === 'undefined') continue;
      const new_field = replaceFunc(state.selectedResource[field]);
      commit('set', {
        selectedResource: Object.assign({}, state.selectedResource, {
          [field]: new_field
        })
      });
    }
    for (const multiField of multi_fields) {
      if (typeof state.selectedResource[multiField] === 'undefined') continue;
      const new_fields = state.selectedResource[multiField].map(item => replaceFunc(item));
      commit('set', {
        selectedResource: Object.assign({}, state.selectedResource, {
          [multiField]: new_fields
        })
      });
    }

  },

  async submitApprovalRequest({ state, commit, dispatch }) {
    await dispatch('cleanSelectedResource');

    await this.$sdk.post(`/resources/${state.selectedResource.id}/approval-request`);
    await dispatch('loadResourceDetails', state.selectedResource.id);
  },

  async cancelApprovalRequest({ state, commit, dispatch }) {
    await this.$sdk.delete(`/resources/${state.selectedResource.id}/approval-request`);
    await dispatch('loadResourceDetails', state.selectedResource.id);
  },

  async deactivate({ state, commit, dispatch }) {
    await this.$sdk.post(`/resources/${state.selectedResource.id}/deactivate`);
    await dispatch('loadResourceDetails', state.selectedResource.id);
  },

  async reactivate({ state, commit, dispatch }) {
    await this.$sdk.post(`/resources/${state.selectedResource.id}/reactivate`);
    await dispatch('loadResourceDetails', state.selectedResource.id);
  },

  async routeToFurthestPage({ state, getters }) {
    const baseRoute = `/resources/${state.selectedResource.name}/edit`;

    if (getters.areAllRequiredFieldsComplete) {
      const lastPage = getters.stepList[getters.stepList.length - 1];
      return $nuxt.$router.replace(`${baseRoute}/${lastPage.to}`);
    }

    if (state.files.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/images`);
    }

    if (state.selectedResource.keywordsArray.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/files`);
    }

    if (state.selectedResource.reading_levels?.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/keywords`);
    }

    if (state.selectedResource.standardsArray.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/reading-level`);
    }

    if (state.selectedResource.skillsArray.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/standards`);
    }

    if (state.selectedResource.grade?.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/skills`);
    }

    if (state.selectedResource.description?.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/grade`);
    }

    if (state.selectedResource.subject_area?.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/description`);
    }

    if (state.selectedResource.subtitle?.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/subject-area`);
    }

    if (state.selectedResource.title?.length > 0) {
      return $nuxt.$router.replace(`${baseRoute}/categories`);
    }

    return $nuxt.$router.replace(`${baseRoute}/title`);
  },

  async favoriteResource({ state, getters, commit }) {
    if (getters.isFavorited) {
      await this.$sdk.delete(`/users/self/resources/favorites/${state.selectedResource.id}`);
      commit('set', {
        selectedResource: Object.assign({}, state.selectedResource, {
          isFavorited: false,
        }),
      });
      commit('set', { justUnfavorited: true });
      window.setTimeout(() => {
        commit('set', { justUnfavorited: false });
      }, 3000);
      return;
    }

    const response = await this.$sdk.put(`/users/self/resources/favorites/${state.selectedResource.id}`);
    if (!response.ok) {

      const body = await response.json();
      const { reason } = body;
      if (reason && reason === 'favorite_limit_exceeded') {
        commit('set', { favoriteLimitExceeded: true });
        window.setTimeout(() => {
          commit('set', { favoriteLimitExceeded: false });
        }, 3000);
      }
      return;
    }



    commit('set', {
      selectedResource: Object.assign({}, state.selectedResource, {
        isFavorited: true,
      }),
    });
    commit('set', { justFavorited: true });
    window.setTimeout(() => {
      commit('set', { justFavorited: false });
    }, 3000);
  },

  async createShareLink({ state, commit }) {
    const response = await this.$sdk.put(`/referrals`, {
      uri: `/resources/${state.selectedResource.name}`,
    });
    if (!response.ok) { return; }
    const responseJson = await response.json();
    commit('set', {
      shareUrl: responseJson.referral.fullUri,
    });
  },

  async createGiftLink({ state, commit }) {
    const response = await this.$sdk.put(`/referrals`, {
      uri: `/resources/${state.selectedResource.name}/purchase`,
      is_gift: true,
    });
    if (!response.ok) { return; }
    const responseJson = await response.json();
    commit('set', {
      giftUrl: responseJson.referral.fullUri,
    });
  },

  async followResourceCreator({ state, commit }) {
    const creatorId = state.selectedResource.users_model.id;
    const response = await this.$sdk.put(`/users/following/${creatorId}`);
    if (!response.ok) { return; }
    if (state.selectedResource) {
      commit('set', { selectedResource: Object.assign({}, state.selectedResource, { isCreatorFollowed: true }) });
    }
  },

  async unfollowResourceCreator({ state, commit }) {
    const creatorId = state.selectedResource.users_model.id;
    const response = await this.$sdk.delete(`/users/following/${creatorId}`);
    if (!response.ok) { return; }
    if (state.selectedResource) {
      commit('set', { selectedResource: Object.assign({}, state.selectedResource, { isCreatorFollowed: false }) });
    }
  },

};
