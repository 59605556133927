
// TODO: redundant
function randomShuffle(array) {
  const shuffledArray = [];
  array = array.slice();
  while (array.length) {
    const index = Math.floor(Math.random() * array.length);
    const value = array[index];
    shuffledArray.push(value);
    array.splice(index, 1);
  }
  return shuffledArray;
}

const defaults = {
  resources: [],
  bufferedResourcePromises: [],
  usingResourceBuffer: false,
  bufferedResourceIndex: null,
  clearingResources: false,
  gridBaseRoute: '/',
  sidebarShowingMobile: false,
  browserSize: {
    width: 0,
    height: 0,
  },
  hasReachedEnd: false,

  // @deprecated
  colorPool: [
    { name: 'red', code: 'R', colors: [{ color: '#fc2960', type: 'dark' }, { color: '#ffbdef', type: 'light' }] },
    { name: 'red-orange', code: 'RO', colors: [{ color: '#ff5b57', type: 'dark' }, { color: '#ffaeae', type: 'light' }] },
    { name: 'orange', code: 'O', colors: [{ color: '#ff8352', type: 'dark' }, { color: '#fecdbd', type: 'light' }] },
    { name: 'orange-yellow', code: 'OY', colors: [{ color: '#ffaf57', type: 'dark' }, { color: '#f7e3aa', type: 'light' }] },
    { name: 'yellow', code: 'Y', colors: [{ color: '#ffd554', type: 'dark' }, { color: '#ede664', type: 'light' }] },
    { name: 'yellow-green', code: 'YG', colors: [{ color: '#a0f07a', type: 'dark' }, { color: '#ccf2b8', type: 'light' }] },
    { name: 'green', code: 'G', colors: [{ color: '#32d09a', type: 'dark' }, { color: '#9df4d1', type: 'light' }] },
    { name: 'blue-green', code: 'BG', colors: [{ color: '#3fc9e0', type: 'dark' }, { color: '#bef8fc', type: 'light' }] },
    { name: 'blue', code: 'B', colors: [{ color: '#57a4ff', type: 'dark' }, { color: '#a4d6ff', type: 'light' }] },
    { name: 'blue-violet', code: 'BV', colors: [{ color: '#617fff', type: 'dark' }, { color: '#a4c0ff', type: 'light' }] },
    { name: 'violet', code: 'V', colors: [{ color: '#877bff', type: 'dark' }, { color: '#b9b7f9', type: 'light' }] },
    { name: 'red-violet', code: 'RV', colors: [{ color: '#dc5ee6', type: 'dark' }, { color: '#f4a4ff', type: 'light' }] },
    { name: 'gray', code: 'GR', colors: [{ color: '#a6adbd', type: 'dark' }, { color: '#c4c9d6', type: 'light' }] },
  ],

  fullColorPool: randomShuffle([
    'var(--acol-array-slot-hover-background-1)',
    'var(--acol-array-slot-hover-background-2)',
    'var(--acol-array-slot-hover-background-3)',
    'var(--acol-array-slot-hover-background-4)',
    'var(--acol-array-slot-hover-background-5)',
    'var(--acol-array-slot-hover-background-6)',
    'var(--acol-array-slot-hover-background-7)',
    'var(--acol-array-slot-hover-background-8)',
    'var(--acol-array-slot-hover-background-9)',
    'var(--acol-array-slot-hover-background-10)',
    'var(--acol-array-slot-hover-background-11)',
    'var(--acol-array-slot-hover-background-12)',
  ]),

  grayPool: randomShuffle([
    'var(--acol-array-slot-background-1)',
    'var(--acol-array-slot-background-2)',
  ]),

  // @deprecated
  // backgroundGrayPool: randomShuffle([
  //   '#a7a9ad', '#bec9da', '#6e7784', '#72757c',
  //   '#c1c4c9', '#d6e0e5', '#aebbd3', '#a6adbc',
  //   '#ced7e3', '#979ba5', '#a1a6b7', '#818999',
  // ]),

  patternPool: randomShuffle([
    'pattern1.svg', 'pattern2.svg', 'pattern3.svg', 'pattern4.svg',
    'pattern5.svg', 'pattern6.svg', 'pattern7.svg', 'pattern8.svg',
    'pattern9.svg', 'pattern10.svg', 'pattern11.svg', 'pattern12.svg',
    'pattern13.svg', 'pattern14.svg', //'pattern15.svg', 'pattern16.svg',
    // 'pattern17.svg', 'pattern18.svg', 'pattern19.svg', 'pattern20.svg',
    // 'pattern21.svg', 'pattern22.svg', 'pattern23.svg', 'pattern24.svg',
  ]),

};

if (typeof window !== 'undefined') {
  // safe to use window here
  defaults.browserSize.width = window.innerWidth;
  defaults.browserSize.height = window.innerHeight;
}

function getCellsPerRow(windowWidth) {
  return windowWidth < 500 ? 0 : windowWidth >= 1180 && windowWidth <= 1380 ? 3 : 4;

  // if (windowWidth >= 1400 && windowWidth <= 1500) {
  //   return 4;
  // }

  if (windowWidth >= 1180 && windowWidth <= 1400) {
    return 3;
  }

  // if (state.browserSize.width >= 1400) {
  // TODO: change back to 1400 if it doesn't look good

  if (windowWidth > 1400) {
    return 4;
  }

  // if (state.browserSize.width >= 600) {
  //   return 2;
  // }

  return 3;
}

export const state = () => Object.assign({}, defaults);

export const getters = {

  resourcesDeduped(state) {
    const resourceIdsUsed = {};
    const resources = [];
    for (const resource of state.resources) {
      if (!resourceIdsUsed[resource.id] || resource.id === null || resource.isAdvertisement) {
        resources.push(resource);
        resourceIdsUsed[resource.id] = true;
      }
    }
    return resources;
  },

  resourceCellsPerRow(state) {
    return getCellsPerRow(state.browserSize.width);
  },

  singlePageGridSize(state, getters) {
    const cellsPerRow = getters.resourceCellsPerRow;
    if (cellsPerRow === 3) {
      return cellsPerRow * 4;
    }
    return cellsPerRow * 3;
  },

};

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  pushResources(state, { resources, silently = false }) {
    // first, remove all placeholders
    const oldPlaceholderIndexes = state.resources.filter(r => r.type === 'placeholder').map(r => r.id);


    for (let i = 0; i < state.resources.length; i++) {
      const resource = state.resources[i];
      if (resource.type === 'placeholder') {
        state.resources.splice(i, 1);
        i -= 1;
      }
    }

    let placeholderIdx = 0;
    for (const resource of resources) {
      if (silently && resource.type === 'placeholder' && placeholderIdx < oldPlaceholderIndexes.length) {
        state.resources.push(Object.assign({}, resource, {
          id: oldPlaceholderIndexes[placeholderIdx],
        }));
        placeholderIdx++;
        continue;
      }
      state.resources.push(resource);
    }
  },

  setResourceFavorited(state, { resourceId, isFavorited }) {
    for (const resource of state.resources) {
      if (resource.id === resourceId) {
        resource.isFavorited = isFavorited;
        return;
      }
    }
  },

  setCreatorFollowed(state, { id, isNotified }) {
    // This should only be used in the /following view.
    for (const resource of state.resources) {
      if (resource?.creator?.id === id) {
        resource.creator.isNotified = isNotified;
        return;
      }
    }
  },

  updateResourceData(state, { resource }) {
    for (const gridResource of state.resources) {
      if (gridResource.id === resource.id) {
        for (const [key, value] of Object.entries(resource)) {
          if (gridResource[key] !== undefined) {
            gridResource[key] = value;
          }
        }
        // might be duplicates that are filtered out with the getter... so
        // wouldn't necessarily be wise to return immediately
        // return;
      }
    }
  },

  deleteResource(state, resourceId) {
    state.resources = state.resources.filter(r => r.id !== resourceId);
  },

};

export const actions = {

  async updateWindowSize({ state, commit, dispatch }, size) {
    const prevCellsPerRow = getCellsPerRow(state.browserSize.width);
    const nextCellsPerRow = getCellsPerRow(size.width);
    const prevHeight = state.browserSize.height;
    commit('set', {
      browserSize: size,
    });
    const bothSmall = (prevHeight <= 815) && (size.height <= 815);
    const bothLarge = (prevHeight > 815) && (size.height > 815);
    if (prevCellsPerRow !== nextCellsPerRow) {
      await dispatch('fillPlaceholderResources', { silently: false });
    } else if (!bothSmall && !bothLarge) {
      await dispatch('fillPlaceholderResources', { silently: true });
    }
  },

  async clearResources({ commit, state }) {
    if (state.resources.length === 0) {
      return;
    }

    commit('set', {
      clearingResources: true,
    });

    await new Promise(resolve => window.setTimeout(resolve, 700));

    commit('set', {
      resources: [],
      clearingResources: false,
      hasReachedEnd: false,
    });
  },

  async beginBufferResources({ commit, dispatch }, { resources, makePromises, numExtraLoads }) {
    const promises = await makePromises(resources);
    commit('set', {
      bufferedResourcePromises: promises,
      usingResourceBuffer: true,
      bufferedResourceIndex: 0,
    });
    for (let i = 0; i < numExtraLoads; i++) {
      await dispatch('loadNextBufferedPage');
    }
  },

  async loadNextBufferedPage({ state, commit, dispatch }) {
    if (!state.usingResourceBuffer) {
      return;
    }

    if (state.bufferedResourceIndex >= state.bufferedResourcePromises.length) {
      commit('set', {
        hasReachedEnd: true,
      });
      return;
    }

    const nextPage = state.bufferedResourcePromises[state.bufferedResourceIndex];

    const nextResources = await Promise.all(nextPage);
    let nextResourcesArr = [];
    for (const r of nextResources) {
      nextResourcesArr.push(r);
    }

    commit('set', {
      bufferedResourceIndex: state.bufferedResourceIndex + 1,
    });

    dispatch('pushResources', { resources: nextResourcesArr });
  },

  async pushResources({ commit, dispatch }, { resources, silently = false }) {
    resources = resources.map((resource, index) => {
      resource.pageIndex = index;
      return resource;
    });

    commit('pushResources', { resources, silently: false });

    await dispatch('fillPlaceholderResources', { silently });
  },

  async fillPlaceholderResources({ state, commit, getters, dispatch }, { silently = false }) {
    const resources = getters.resourcesDeduped.slice(0);

    const numNonPlaceholderItems = resources.filter(resource => resource.type !== 'placeholder').length;

    let placeholdersNeeded = 0;
    const rowSize = getters.resourceCellsPerRow;
    const needsExtraRow = state.browserSize.height > 815 && numNonPlaceholderItems <= getters.singlePageGridSize && rowSize === 4;
    if (numNonPlaceholderItems < getters.singlePageGridSize) {
      placeholdersNeeded = getters.singlePageGridSize - numNonPlaceholderItems;
      if (needsExtraRow) {
        placeholdersNeeded += rowSize;
      }
    } else {
      placeholdersNeeded = (getters.resourceCellsPerRow - (numNonPlaceholderItems % getters.resourceCellsPerRow)) % getters.resourceCellsPerRow;
      if (needsExtraRow) {
        placeholdersNeeded += rowSize;
      }
    }

    // NOTE: we DO want to call injectPlaceholders if there are none
    // needed, because this function will also remove unnecessary ones (e.g.
    // in the event of a window resize)
    await dispatch('injectPlaceholders', { amount: placeholdersNeeded, silently });
  },

  injectPlaceholders({ getters, commit }, { amount, silently = false }) {
    const placeholders = [];
    const pImages = this.$preloader.getPatterns()
    const patternImages = randomShuffle(pImages.map(x => x.variation.image_locator))
    for (let i = 0; i < amount; i++) {
      const styleIndex = i;
      const pattern = patternImages[styleIndex % patternImages.length];

      placeholders.push({
        id: Math.random(),
        type: 'placeholder',
        backgroundImage: `https://learningful.org${pattern}`,
        // backgroundColor: state.backgroundGrayPool[ styleIndex % state.backgroundGrayPool.length ],
        pageIndex: getters.resourcesDeduped.length + i,
      });
    }

    commit('pushResources', { resources: placeholders, silently });
  },

  // @deprecated
  getColorCode(context, color, type) {
    const baseCode = color.code;
    let typeCode = 'L';
    if (type === 'dark') {
      typeCode = 'D';
    }
    return `${baseCode}${typeCode}`;
  },

  // @deprecated
  async parseExcludeCodes({ dispatch }, codeString) {
    const codesArr = codeString.split(',')

    const excludedCodes = new Set();
    for (const code of codesArr) {
      const codeNormed = code.toUpperCase().trim();
      const permutations = await dispatch('getColorCodePermutations', codeNormed);
      for (const p of permutations) {
        excludedCodes.add(p);
      }
    }

    return excludedCodes;
  },

  // @deprecated
  getColorCodePermutations(context, code) {
    const codes = new Set([code]);
    if (code.length === 3) {
      codes.add(`${code[0]}${code[2]}${code[1]}`);
    }
    return codes;
  },

  // @deprecated
  async injectColorsForResources({ commit, state, dispatch }, resources) {
    let typesPool = [];
    for (let i = 0; i < 6; i++) {
      typesPool.push('light');
      typesPool.push('dark');
    }
    typesPool = randomShuffle(typesPool);
    const currentColorPool = randomShuffle(state.colorPool.slice(0, 12));
    const resourcePool = resources.slice(0);

    for (let c = 0; c < 12; c++) {
      const color = currentColorPool[c];
      const type = typesPool[c];
      const colorCode = await dispatch('getColorCode', color, type);
      const colorTypeIndex = type === 'dark' ? 0 : 1;
      const colorCss = color.colors[colorTypeIndex].color;

      for (let r = 0; r < resourcePool.length; r++) {
        const resource = resourcePool[r];
        // skip if it already has a background color
        if (resource.backgroundColor) {
          continue;
        }
        const excludedColors = await dispatch('parseExcludeCodes', resource.excluded_colors || '');
        if (excludedColors.has(colorCode)) {
          continue;
        }

        resource.backgroundColor = colorCss;
        resourcePool.splice(r, 1);
        break;
      }
    }

    //
    // set gray to a random resource
    //

    const resourceGrayPool = randomShuffle(resources.slice(0));
    const grayColor = state.colorPool[12];
    const grayType = Math.random() > 0.5 ? 'dark' : 'light';
    const grayColorCode = await dispatch('getColorCode', grayColor, grayType);
    const grayColorTypeIndex = grayType === 'dark' ? 0 : 1;
    const grayColorCss = grayColor.colors[grayColorTypeIndex].color;
    for (const resource of resourceGrayPool) {
      if (resource.type === 'message') {
        continue;
      }

      const excludedColors = await dispatch('parseExcludeCodes', resource.excluded_colors || '');
      if (excludedColors.has(grayColorCode)) {
        continue;
      }
      resource.backgroundColor = grayColorCss;
      break;
    }

    return resources;
  },

  async returnToGridBase({ state, dispatch }) {
    await dispatch('main/closeModal', {}, { root: true });
    $nuxt.$router.push(state.gridBaseRoute);
  },

  scrollToBottomEvent() { },

  updateResourceData({ commit, state, dispatch }, { resource }) {
    const res = Object.assign({}, resource);
    if (res.resources_previews_models) {
      res.previews = res.resources_previews_models;
    }
    commit('updateResourceData', { resource: res });
  },

  async deleteResource({ commit, dispatch }, resourceId) {
    commit('deleteResource', resourceId);
    await dispatch('fillPlaceholderResources', { silently: true });
  },

  async loadPortfolioResources({ dispatch, rootState, rootGetters }) {
    const response = await this.$sdk.get(`/users/self/resources`);
    const responseJson = await response.json();
    let { initResources, extraResources } = responseJson;


    if (rootState.main.siteSettings.showAds?.portfolio && rootGetters['main/shouldDisplayAds']) {
      const adJson = await dispatch('adcontrol/getDisplayAds', { type: '1' }, { root: true });
      if (adJson?.ad) {
        initResources.unshift({
          isAdvertisement: true,
          ad: adJson.ad,
        });
      }


      if ((initResources.length > 20 || extraResources) && adJson.ad) {
        // const adJson2 = await adResponse2.json();
        const adJson2 = await dispatch('adcontrol/getDisplayAds', {
          type: '1',
          exceptions: adJson.ad.id,
        }, { root: true });
        if (extraResources) {
          extraResources.push({
            isAdvertisement: true,
            ad: adJson2.ad,
          })
        } else {
          initResources.push({
            isAdvertisement: true,
            ad: adJson2.ad,
          });
        }
      }
    }

    let betweenResources = null;
    if (initResources.length > 24) {
      betweenResources = initResources.slice(24);
      initResources = initResources.slice(0, 24);
    }

    if (betweenResources && !extraResources) {
      extraResources = betweenResources;
    } else if (betweenResources) {
      extraResources = betweenResources.concat(extraResources);
    }

    await dispatch('pushResources', { resources: initResources, silently: false });

    const getResource = async (resourceId) => {
      const res = await this.$sdk.get(`/resources/${resourceId}`);
      const respJson = await res.json();
      return respJson.resource;
    }

    const makePromises = async (resources) => {
      let parts = [];
      const numSections = Math.ceil(resources.length / 12);
      for (let i = 0; i < numSections; i++) {
        let page = resources.slice(12 * i, 12 * (i + 1));
        for (let j = 0; j < page.length; j++) {
          if (page[j].type === 'resource') {
            page[j] = getResource(page[j].id);
          } else {
            page[j] = new Promise(resolve => resolve(resources[12 * i + j]));
          }
        }
        parts.push(page);
      }
      return parts;
    }


    if (extraResources) {
      await dispatch('beginBufferResources', {
        resources: extraResources,
        makePromises,
        numExtraLoads: 0,
      })
    }

    return initResources;
  },

};
