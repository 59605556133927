
import { debounce } from '@/lib/power-tools';

async function initIdleTimeout(context) {
  let idleTimeoutSeconds = context.store.state.main.siteSettings.idleTimeoutSeconds;

  if (idleTimeoutSeconds === undefined) {
    await context.store.dispatch('main/loadSiteSettings'); //IdleTimeout');
    idleTimeoutSeconds = context.store.state.main.siteSettings.idleTimeoutSeconds;
  }

  if (!idleTimeoutSeconds) {
    return;
  }

  const debounceShowOffers = debounce(() => {
    if (context.route.name?.startsWith('index-offers')) {
      return;
    }

    const isAuthenticated = context.store.getters['main/isAuthenticated'];
    if (isAuthenticated) {
      if (context.store.getters['main/shouldDisplayAds']) {
        context.app.router.push('/offers');
      } else {
        const hasDonated = context.store.state.main?.user?.donation_date
        if (!hasDonated) {
          context.app.router.push('/donation');
        }
        //  else {
        //   context.app.router.push('/first-tip');
        // }
      }
    }
  }, idleTimeoutSeconds * 1000);

  debounceShowOffers();
  if (process.client) {
    document.addEventListener('mousemove', debounceShowOffers);
  }
}

async function initResizePlaceholderFiller(context) {
  if (process.client) {
    window.addEventListener('resize', async (event) => {
      const size = {
        width: event.target.innerWidth,
        height: event.target.innerHeight,
      };
      await context.store.dispatch('resource-grid/updateWindowSize', size);
    });
  }
}

export default async (context, inject) => {
  await initResizePlaceholderFiller(context);
  await initIdleTimeout(context);
};
