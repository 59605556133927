
function randomChoice(arr) {
  const index = Math.floor(Math.random() * arr.length);
  return arr[index];
}

function randomShuffle(array) {
  const shuffledArray = [];
  array = array.slice();
  while (array.length) {
    const index = Math.floor(Math.random() * array.length);
    const value = array[index];
    shuffledArray.push(value);
    array.splice(index, 1);
  }
  return shuffledArray;
}
const loadImage = (url) => new Promise((resolve, reject) => {
  const img = new Image();
  img.addEventListener('load', () => resolve(img));
  img.addEventListener('error', (err) => console.error(err));
  img.src = url;
});

function preloadImageUrls(urls) {
  return true;
}

async function preloadImageUrlsV2(urls) {
  return true;
  const promises = []
  for (const url of urls) {
    if (url) {
      console.info("preloading image", url)
      const p = loadImage(url)
      promises.push(p)
    }
  }

  return await Promise.all(promises)
}


async function getImageBase64(file) {
  try {
    const base64 = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = async () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    })
    return base64;
  }
  catch (e) {
    return null;
  }
}

function getImageObject(base64) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = function () {
      return resolve(image);
    };
    image.src = base64;
  });
}

function waitForImageLoaded(image) {
  return new Promise((resolve) => {
    if (image.naturalWidth) {
      return resolve(image);
    }
    const previousOnLoad = image.onload;
    image.onload = function () {
      if (previousOnLoad) {
        previousOnLoad();
      }
      resolve(image);
    };
  });
}

function buildUrl(parts) {
  const body = new URLSearchParams();

  if (Array.isArray(parts)) {
    for (const part of parts) {
      body.append(part[0], part[1]);
    }
  }

  else {
    for (const fieldName of Object.keys(fields)) {
      body.append(fieldName, fields[fieldName]);
    }
  }

  return body.toString();
}

function debounce(func, delay) {
  let timeout = null;
  return function () {
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      func(...arguments);
    }, delay);
  }
}

function getResizedLocator(locator, size) {
  // TODO apply on Chrome only
  if (locator && locator.length > 0) {
    const width = size[0] * 2;
    const height = size[1] * 2;
    locator = `${locator.includes("http") ? "" : "https://learningful.org"}${locator[0] === '/' ? '' : '/'}${locator}?w=${width}&h=${height}`;
  }
  return locator;
}

function isNumber(evt, justReturn = false) {
  evt = (evt) ? evt : window.event;
  var charCode = (evt.which) ? evt.which : evt.keyCode;
  if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
    if (justReturn) {
      return false;
    } else {
      evt.preventDefault();
    }
  } else {
    return true;
  }
}

function isNumberBasic(charToCheck) {
  return [0, "0", 1, "1", 2, "2", 3, "3", 4, "4", 5, "5", 6, "6", 7, "7", 8, "8", 9, "9"].includes(charToCheck)
}

function isBadCharacterPunctuation(charString) {
  return /[\u2000-\u206F\u2E00-\u2E7F\\!"#$%&()*+,.:;<=>?@\[\]^_`{|}]/.test(charString);
}

function toStyleString(obj) {
  return Object.entries(obj).reduce((acc, curr, arr) => acc + `${curr[0]}: ${curr[1]};`, "")
}

function getImageFromStore(store, imageName) {
  const img = store.state["site-images"].siteImages.find(image => image.title === imageName)
  return img?.variation?.image_locator ?? ""
}

function getSiteImage(store, imageVarName, imageName) {
  return {
    [imageVarName]: {
      get() {
        return getImageFromStore(store, imageName)
      },
    },
  }
}

const lowercaseLetters = new Set('abcdefghijklmnopqrstuvwxyz');
const uppercaseLetters = new Set('ABCDEFGHIJKLMNOPQRSTUVWXYZ');
const getAutoCapitalizeFunction = () => {
  const ignoreIndexes = new Set();

  const transform = (text, selectionStart, selectionEnd, keyVal) => {
    const anchor = text.slice(0, selectionStart);
    const extra = text.slice(selectionEnd, text.length);
    return anchor + keyVal + extra;
  }

  const resetIndices = () => {
    ignoreIndexes.clear();
  }

  const keydownEvent = (evt, options) => {
    if (typeof options.model === 'undefined') {
      throw new Error("Must provide model option to keydown event");
    }

    const selectionStart = evt.target.selectionStart;
    const selectionEnd = evt.target.selectionEnd;
    if (evt.ctrlKey) {
      return;
    }

    if (evt.keyCode === 8) {
      ignoreIndexes.add(selectionStart - 1);
      return;
    }

    if (ignoreIndexes.has(selectionStart)) {
      return;
    }

    const prevChar = (selectionStart !== 0) ? evt.target.value[selectionStart - 1] : null;

    if (selectionStart === 0 || prevChar === ' ' || prevChar === '/') {
      if (lowercaseLetters.has(evt.key)) {
        const val = transform(options.model, selectionStart, selectionEnd, evt.key.toUpperCase());
        options.model = val;
        evt.preventDefault();
        evt.stopPropagation();
        return;
      }
    } else if (uppercaseLetters.has(evt.key)) {
      const val = transform(options.model, selectionStart, selectionEnd, evt.key.toLowerCase());
      options.model = val;
      evt.preventDefault();
      evt.stopPropagation();
      return;
    }
  }

  return { keydownEvent, resetIndices };
}

function numberToWord(num) {
  const numToWordMap = {
    "1": "one",
    "2": "two",
    "3": "three",
    "4": "four",
    "5": "five"
  };
  
  return numToWordMap[`${num}`] || "";
}


module.exports = {
  randomChoice,
  randomShuffle,
  preloadImageUrls,
  preloadImageUrlsV2,
  getImageBase64,
  getImageObject,
  waitForImageLoaded,
  buildUrl,
  debounce,
  getResizedLocator,
  numberToWord,
  isNumber,
  isNumberBasic,
  isBadCharacterPunctuation,
  toStyleString,
  getImageFromStore,
  getSiteImage,
  getAutoCapitalizeFunction,
};
