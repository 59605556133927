const middleware = {}

middleware['apply-ref-code'] = require('../middleware/apply-ref-code.js')
middleware['apply-ref-code'] = middleware['apply-ref-code'].default || middleware['apply-ref-code']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['load-config'] = require('../middleware/load-config.js')
middleware['load-config'] = middleware['load-config'].default || middleware['load-config']

middleware['loader'] = require('../middleware/loader.js')
middleware['loader'] = middleware['loader'].default || middleware['loader']

middleware['offline-mode'] = require('../middleware/offline-mode.js')
middleware['offline-mode'] = middleware['offline-mode'].default || middleware['offline-mode']

middleware['user-permissions'] = require('../middleware/user-permissions.js')
middleware['user-permissions'] = middleware['user-permissions'].default || middleware['user-permissions']

export default middleware
