export default async (context) => {
    const { route, $preloader, store } = context
    if (!!(store.getters['main/isAuthenticated'] && store.state?.main?.user?.id)) {
        await $preloader.preloadUser(document, store.state.main.user)
    }
    const loaderSettings = route.meta.find(settings => settings.loader)
    if (loaderSettings && loaderSettings.loader && loaderSettings.loader.key) {
        
        return await $preloader.beforeMount(document, loaderSettings.loader.key)
    }

}