
const defaults = {
  isSearchMode: false,
  isSearchActive: false,
  hasTriggeredSearch: false,
  resultsCount: 0,
  onlyFreeResources: false,
};

export const state = () => Object.assign({}, defaults);

export const getters = {
};

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

};

export const actions = {

};
