
function createRipple(event) {
  const button = event.currentTarget;
  if (!button.classList.contains('pulse-button')) {
    button.classList.add ('pulse-button')
  }
  // button.style.overflow = "hidden"
  // button.style.position = "relative"
  const circle = document.createElement("span");
  const diameter = Math.max(button.clientWidth, button.clientHeight);
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;

  const clientRect = button.getBoundingClientRect();
  const x = event.clientX - (clientRect.left + radius);
  const y = event.clientY - (clientRect.top  + radius);

  circle.style.left = `${x}px`;
  circle.style.top  = `${y}px`;
  circle.classList.add("ripple");

  const ripple = button.getElementsByClassName("ripple")[0];

  if (ripple) {
    ripple.remove();
  }

  button.appendChild(circle);
}

module.exports = {
  createRipple,
};
