const privateRoutes = [
  "index-resources-id-purchase"
]


const guestRoutes = [
  '^/$',
  '^/login',
  '^/sign-up',
  '^/token-login',
  '^/new$',
  '^/featured$',
  '^/helpbook',
  '^/help',
  '^/resources',
  '^/offers',
  '^/offline',
  '^/ref',
  '^/first-tip',
  // '^/portfolio/\@',
  '^/profile',
  '^/portfolio/',
  '^/terms-accepted',
  '^/resource-not-found',
  // '^/test/colors',

  // '/test/homepage-layout',
  // '/test/captcha',
  // '/test/upload',
  '/test/pattern',
];

function doesPathMatchGuestRoute(path) {
  for (const re of guestRoutes) {
    if (path.match(re)) {
      return true;
    }
  }
  return false;
}

export default async function (context) {
  const { $sdk, route, redirect, from } = context;
	if (route?.name === 'index-all') {
		return redirect('/?hasError=true');
	}
	
  if (from.path.startsWith('/resources/') && route.path.startsWith('/login')) {
    const resourceId = from.params.id;
    if (resourceId) {
      window.localStorage.setItem('post-login-resource-id', resourceId);
    }
  }

  const user = context.store.state.main.user;
  if (user.is_deactivated) {
    if (route.name == 'index-offers') {
      return true;
    }
    if (route.name === 'index-profile-id-status') {
      // already redirecting-- ignore
      return;
    }
    redirect(`/profile/@${user.username}/status`);
    return;
  }
  const applicapleNext = [
    'advertising',
    'favorites',
    'following',
    'publishing',
    'earnings',
    'support'
  ]
  if (!$sdk.isAuthenticated()) {
    for (const item of applicapleNext) {
      if (route.name?.startsWith(`index-${item}`)) {
        return redirect(`/login?next=${item}`);
      }
    }
  } else {
    // if index-sign-up, redirect to index
    if (route.name?.startsWith('index-sign-up')) {
      return redirect('/');
    }
  }
  if (route.name.startsWith('index-resources-id-purchase')) {
    if (!$sdk.isAuthenticated()) {
      if (route.fullPath.includes('refCode')) {
        window.localStorage.setItem('post-sign-up-ref', route.fullPath)
        return redirect('/sign-up?from=gift');
      }
    }
  }
  for (const name of privateRoutes) {
    if (route.name?.startsWith(name)) {
      if (!$sdk.isAuthenticated()) {
        $sdk.setPostAuthRedirect(route.fullPath);
        return redirect('/login');
      }
    }
  }


  if (route.name?.startsWith('index-profile-id')) {
    if (!$sdk.isAuthenticated() && ['index-profile-id-settings', 'index-profile-id-status', 'index-profile-id-message', 'index-profile-id-tip', 'index-profile-id-share'].includes(route.name)) {
      $sdk.setPostAuthRedirect(route.path);
      return redirect('/login');
    }
  }

  if (doesPathMatchGuestRoute(route.path)) {
    return;
  }


  if (route.name?.startsWith('index-admin')) {
    if (!($sdk.isAuthenticated() && context.store.getters['main/isAdmin'])) {
      console.info(`Trying to access admin route-- redirecting.`);
      return redirect('/login');
    }
  }

  if ($sdk.isAuthenticated()) {
    return true;
  }

  let redirectUrl = route.path;
  // if (route.path === from.path) {
  //   redirectUrl = '/';
  // }

  if (route.path !== from.path) {
    $sdk.setPostAuthRedirect(redirectUrl);
  }
  
  if (route.path === '/support') {
    $sdk.setPostAuthRedirect('/support');
  }

  return redirect('/login');
}
