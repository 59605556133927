const defaults = {
  adsImpressedOn: []
};

export const state = () => ({ ...defaults });

export const getters = {
  hasAdBeenImpressedOn: (state) => (adId) => {
    return state.adsImpressedOn.length !== 0 && state.adsImpressedOn.includes(adId);
  }
};

export const mutations = {
  resetAdIdImpressions(state) {
    state.adsImpressedOn = [];
  },

  impressAdId(state, adId) {
    state.adsImpressedOn.push(adId);
  },

  removedAdIdImpression(state, adId) {
    state.adsImpressedOn = state.adsImpressedOn.filter((id) => id !== adId);
  }

};

export const actions = {
  async postImpression({ commit, getters }, adId) {
    if (getters.hasAdBeenImpressedOn(adId)) {
      return Promise.resolve()
    } else {
      commit('impressAdId', adId);
      return await this.$sdk.post(`/advertisements/${adId}/impression`);
    }
  },

  async getDisplayAds({ commit }, { __needsJson = true, ...options }) {
    const r = await this.$sdk.get('/advertisements/display', options);
    const adJson = await r.json();
    if (adJson.ad) {
      commit('removedAdIdImpression', adJson.ad.id);
      return __needsJson ? adJson : r
    }
  }
}
