
const defaults = {
  page: -1,
  ad2Offset: 0,
  seenAds: [],
  loadingNextPage: false,
  initAdId: null,
  hasReachedEnd: false,
};

export const state = () => Object.assign({}, defaults);

export const getters = {
};

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

};

export const actions = {

};
