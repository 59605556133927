import { modalBaseGetters } from '@/mixins/ModalStore';

const defaults = {
  loading: false,
  invalidCredentials: false,
  successfulLogin: false,
  isLocked: false,
  isEmailLocked: false,
  accentColor: '#000000',
  email: '',
  proTip: {},
};

export const state = () => Object.assign({}, defaults);

export const getters = Object.assign({}, modalBaseGetters, {
  modalCloseButton(state) {
    return false;
  },
  modalDefaultClose(state) {
    return false;
  },
});

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  reset(state) {
    for (const [key, value] of Object.entries(defaults)) {
      state[key] = value;
    }
  },

};

export const actions = {

  async setAccent({ }, color) {
    if (color?.color_hex) {
      document.documentElement.style.setProperty('--col-accent', color.color_hex);
    }
  },

};
