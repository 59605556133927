
const defaults = {
  success: false,
  checking: false,
  checked: false,
  isShowing: false,
  error: false,
  patternInputReset: null,

  maxTries: 3,
  recentTries: 0,
  tryCountEnglish: ['No tries', 'One try', 'Two tries', 'Three tries', 'Four tries', 'Five tries'],
  state: null,
};

export const state = () => Object.assign({}, defaults);

export const getters = {

  triesRemaining(state) {
    return state.maxTries - state.recentTries;
  },

  triesRemainingEnglish(state, getters) {
    return state.tryCountEnglish[getters.triesRemaining];
  }

};

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  reset(state) {
    for (const [key, value] of Object.entries(defaults)) {
      state[key] = value;
    }
  },

  setChecking(state, checking) {
    state.checking = checking;
  },

  setSuccess(state, success) {
    state.checked = true;
    state.success = success;
  },

  clearSuccess(state) {
    state.checked = false;
    state.success = false;
  },

};

export const actions = {

  async animateError({ commit, state }, file) {
    commit('set', { error: true });
    await new Promise(resolve => window.setTimeout(resolve, 1000));
    commit('set', { error: false });
    state.patternInputReset();
  },

};
