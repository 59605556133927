import { DateTime } from "luxon";

export const state = () => ({
  supportThreads: [],
  supportThreadIndex: 0,

  supportMessageIndexAdmin: 0,
  supportMessageIndexTeacher: 0,

  responseBoxShowing: false,
  responseBody: '',

  users: [],
  userCommsShowing: false,

  payouts: [],
  totalEligiblePayouts: 0,

  advertisements: [],
  advertisementIndex: 0,
  adCommentBody: '',

  resources: [],
  resourcesIndex: 0,
  resourcesCommentBody: '',

  editingPayoutDate: false,
  payoutDate: DateTime.now().toISO(),
});

export const getters = {
  usersById(state) {
    const usersById = {};
    for (const user of state.users) {
      usersById[user.id] = user;
    }
    return usersById;
  },

  resourcesQueue(state) {
    return state.resources.filter(x => x.status !== 'skipped');
  },

  selectedResource(state, getters) {
    return getters.resourcesQueue[state.resourcesIndex];
  },
};

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  clearSelectedThread(state) {
    state.supportThreads.splice(state.supportThreadIndex, 1);
  },

  skipSupportThread(state) {
    state.supportThreads.splice(state.supportThreadIndex, 1);
  },

  clearSelectedAdvertisement(state) {
    state.advertisements.splice(state.advertisementIndex, 1);
    state.adCommentBody = '';
  },

  clearSelectedResource(state) {
    state.resources.splice(state.resourcesIndex, 1);
    state.resourceCommentBody = '';
  },

  skipResource(state, realIndex) {
    state.resources.splice(realIndex, 1, Object.assign({},
      state.resources[realIndex],
      { status: 'skipped' }
    ));
  },

  setSelectedResourceCuratorScore(state, score) {
    state.resources[state.resourcesIndex].curator_score = score;
  },

  cacheUsers(state, users) {
    for (const user of users) {
      state.users.push(user);
    }
  },

};

export const actions = {

  skipSelectedResource({ state, getters, commit }) {
    const realIndex = state.resources.findIndex((res) => res.id === getters.selectedResource.id);
    commit('skipResource', realIndex);
  },

}
