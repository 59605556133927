const admin = {
    images: [
        "Admin Account Menu Button 1",
        "Admin Account Menu Button 1 (Hover)",
        "Admin Account Menu Button 1 (OnClick)",
        "Admin Account Menu Button 2",
        "Admin Account Menu Button 2 (Hover)",
        "Admin Account Menu Button 2 (OnClick)",
        "Admin Account Menu Button 3",
        "Admin Account Menu Button 3 (Hover)",
        "Admin Account Menu Button 3 (OnClick)",
        "Admin Account Menu Button 4",
        "Admin Account Menu Button 4 (Hover)",
        "Admin Account Menu Button 4 (OnClick)",
        "Admin Account Menu Button 5",
        "Admin Account Menu Button 5 (Hover)",
        "Admin Account Menu Button 5 (OnClick)",
        "Admin Account Cancel Button",
        "Admin Account Save Button",
        "Admin Account User Communication Hyperlink Button Icon",
        "Admin Account User Communication File Attachment Button Icon",
        "Admin Account User Communication File Attachment Icon",
    ],
    children: {
        accounting: {
            images: [
                "Admin Account Daily Accounting Deleted Users Avatar",

            ]
        },
        email: {
            images: [
                "Admin Account Email Database Composition Button Bold",
                "Admin Account Email Database Composition Button Color",
                "Admin Account Email Userbase File Attachment Icon",
                "Admin Account Email Userbase File Attachment Button Icon",
                "Admin Account Email Userbase Hyperlink Button Icon",
            ]
        },
        exceptions: {
            images: [
                "Admin Account Exceptions Approved Notification Checkmark",
                "Admin Account Exceptions Declined Notification Checkmark",
                "Admin Account Exceptions Placeholder Background",

            ],
            children: {
                advertising: {
                    images: [
                        "Admin Account Exceptions Ad Type 1 Icon",
                        "Admin Account Exceptions Ad Type 2 Icon",
                        "Admin Account Exceptions Ad Type 3 Icon",
                        "Admin Account Exceptions Ad Type 4 Icon",
                        "Admin Account Exceptions Ad Type 5 Icon",

                    ]
                },
                payouts: {
                    images: [
                    ]
                },
                publishing: {
                    images: [
                    ]
                },
                support: {
                    images: [
                        "Admin Account Exceptions Support Navigation Up Button",
                        "Admin Account Exceptions Support Navigation Down Button",
                        "Admin Account Exceptions Support File Attachment Icon",
                        "Admin Account Exceptions Support File Attachment Button Icon",
                        "Admin Account Exceptions Support Hyperlink Button Icon",
                        "Admin Account Exceptions Speech Bubble",
                        "Admin Account Exceptions Support Avatar",
                        "User Communication Message Queued Checkmark",
                    ]
                }
            }
        },
        settings: {
            images: [
                "Admin Account Settings Navigation Button Up",
                "Admin Account Settings Navigation Button Down",
                "Admin Account Settings Checkmark",
                "Admin Account Setting Updated Checkmark",

            ]
        },
        statistics: {
            images: [
                "Admin Account Site Statistics Menu Button 1",
                "Admin Account Site Statistics Menu Button 1 (Active)",
                "Admin Account Site Statistics Menu Button 2",
                "Admin Account Site Statistics Menu Button 2 (Active)",
                "Admin Account Site Statistics Menu Button 3",
                "Admin Account Site Statistics Menu Button 3 (Active)",
                "Admin Account Site Statistics Menu Button 4",
                "Admin Account Site Statistics Menu Button 4 (Active)",
                "Admin Account Site Statistics Menu Button 5",
                "Admin Account Site Statistics Menu Button 5 (Active)",
                "Admin Account Site Statistics Menu Button 6",
                "Admin Account Site Statistics Menu Button 6 (Active)",
                "Admin Account Site Statistics Menu Button 7",
                "Admin Account Site Statistics Menu Button 7 (Active)",
                "Admin Account Site Statistics Menu Button 8",
                "Admin Account Site Statistics Menu Button 8 (Active)",
                "Admin Account Site Statistics Menu Button 9",
                "Admin Account Site Statistics Menu Button 9 (Active)",
                "Admin Account Site Statistics Menu Button 10",
                "Admin Account Site Statistics Menu Button 10 (Active)",
                "Admin Account Site Statistics Menu Button 11",
                "Admin Account Site Statistics Menu Button 11 (Active)",
                "Admin Account Site Statistics Menu Button 12",
                "Admin Account Site Statistics Menu Button 12 (Active)",
                "Admin Account Site Statistics Menu Button 13",
                "Admin Account Site Statistics Menu Button 13 (Active)",
                "Admin Account Site Statistics Menu Button 14",
                "Admin Account Site Statistics Menu Button 14 (Active)",
                "Admin Account Site Statistics Menu Button 15",
                "Admin Account Site Statistics Menu Button 15 (Active)",

            ]
        },
        users: {
            images: [
                "Admin Account User Database Menu Button 1",
                "Admin Account User Database Menu Button 1 (Active)",
                "Admin Account User Database Menu Button 2",
                "Admin Account User Database Menu Button 2 (Active)",
                "Admin Account User Database Menu Button 3",
                "Admin Account User Database Menu Button 3 (Active)",
                "Admin Account User Database Menu Button 4",
                "Admin Account User Database Menu Button 4 (Active)",
                "Admin Account User Database Navigation Up Button",
                "Admin Account User Database Navigation Down Button",

            ]
        }
    }
}

module.exports = admin;