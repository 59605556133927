
import { modalBaseGetters } from '@/mixins/ModalStore';

const defaults = {
  balance: 0,
  estimatedBalance: 0,
  eligibleBalance: 0,
  eligiblePayout: 0,
  pendingPayout: null,
  hasTakenPayout: null,
  navPage: '',
};

export const state = () => Object.assign({}, defaults);

export const getters = Object.assign({}, modalBaseGetters, {
});

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

};

const ifZeroThenFloat = value => value === 0 ? 0.00 : value;

export const actions = {

  async loadBalance({ commit }) {
    const response = await this.$sdk.get(`/earnings/self/balance`);
    const responseJson = await response.json();
    commit('set', {
      balance: responseJson.balance,
      estimatedBalance: ifZeroThenFloat(responseJson.estimatedBalance),
      eligibleBalance: responseJson.eligibleBalance,
      eligiblePayout: responseJson.eligiblePayout,
      pendingPayout: responseJson.pendingPayout,
      hasTakenPayout: responseJson.hasTakenPayout,
    });
  },

};
