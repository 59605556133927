const { getResizedLocator } = require("../../lib/power-tools");
const imagePageKeys = require("./pages/index");

class ImageManager {
    constructor(allimages, context) {
        this.allSiteImages = allimages
        this.app = context.app
        this.$sdk = context.$sdk;
        this.loadedImages = new Set();
    }

    getPreloader = (imageSrc) => {
        return new Promise((resolve, reject) => {
            if (this.loadedImages.has(imageSrc)) {
                resolve(imageSrc)
            } else {
                const image = new Image();
                image.src = imageSrc.includes("http") ? imageSrc : `https://learningful.org${imageSrc}`;
                image.onload = () => {
                    resolve(image);
                    this.loadedImages.add(imageSrc);
                }
                image.onerror = () => {
                    reject(imageSrc);
                }
            }
        })
    }

    preloadeSingle = async (document, imageSrc) => {
        return await this.addImages(imageSrc, document)
    }

    preloadImages = async (images) => {
        return await Promise.all(images.map(this.getPreloader));
    }

    addImages = async (images, document = false) => {
        if (!Array.isArray(images)) {
            images = [images];
        }
        const data = await this.preloadImages(images)

        if (document) {
            for (let index = 0; index < data.length; index++) {
                const imageSrc = data[index];
                if (typeof imageSrc !== 'string') {
                    // imageSrc.style.opacity = 0;
                    // imageSrc.style.height = 0;
                    // imageSrc.style.width = 0;
                    // imageSrc.style.position = "absolute";
                    // imageSrc.style.top = 0;
                    // imageSrc.style.left = 0;
                    // imageSrc.style.zIndex = -1;
                    imageSrc.style.display = "none";
                    document.body.appendChild(imageSrc);
                }
            }
        }
        return data;
    }

    generatePaths(string) {
        const paths = [];
        const components = string.split(":");
        for (let i = 1; i <= components.length; i++) {
            const path = components.slice(0, i).join(":");
            paths.push(path);
        }
        return paths;
    }

    getImagesByKey = (keys) => {
        const result = [];
        let itemToCheck = imagePageKeys
        for (const key of keys) {
            if (itemToCheck && itemToCheck[key] && itemToCheck[key].images && itemToCheck[key].images.length > 0) {
                result.push(...itemToCheck[key].images);
            }
            if (itemToCheck && itemToCheck[key] && itemToCheck[key].children) {
                itemToCheck = itemToCheck[key].children;
            } else {
                break;
            }
        }

        return result;
    };

    getImagesForPage = (pageKey) => {
        return this.getImagesByKey(pageKey.split(":"));
    }

    toVarString = (str, isUrl = false) => {
        const formattedStr = str.toLowerCase().replaceAll(/[()]/gmi, "").replace(/( ){2,}/gmi, " ").replaceAll(" ", "-")
        return `--${formattedStr}${isUrl ? '-url' : ''}`
    }

    addColors = (imageManager) => {
        const rules = []
        for (let index = 0; index < this.allSiteImages.length; index++) {
            const element = this.allSiteImages[index];
            if (element.variation !== false) {
                rules.push(`${this.toVarString(element.title)}: 'https://learningful.org${element.variation.image_locator}';`);
                if (element?.variation?.alt && (element.variation.alt !== '' || element.variation.alt !== null)) {
                    rules.push(`${this.toVarString(element.title)}-alt: "${element.variation.alt}";`);
                }
                rules.push(`${this.toVarString(element.title, true)}: url('https://learningful.org${element.variation.image_locator}');`);
                const colorsToUse = typeof element.variation.colors === "string"? JSON.parse(element.variation.colors) : element.variation.colors
                if (Object.keys(colorsToUse).length > 0) {
                    for (let colorIndex = 0; colorIndex < Object.keys(colorsToUse).length; colorIndex++) {
                        const key = Object.keys(colorsToUse)[colorIndex];
                        const elementColor = element.variation.colors[key];
                        let imageKey;
                        try {
                            if (typeof element.image_field_names === "string") {
                                imageKey = JSON.parse(element.image_field_names)[elementColor.css_var_name].database_color_name
                            } else {
                                imageKey = element.image_field_names[elementColor.css_var_name].database_color_name
                            }
                            rules.push(`--acol-${imageKey}: ${elementColor.color_hex} !important;`);
                        } catch { }
                    }
                }
            }
        }
        const cssVariables = `
            :root {
                ${rules.join('\n')}
            }
        `;

        this.app.head.style.push({
            type: 'text/css',
            cssText: cssVariables,
        });

    }

    findImage = (imageKey) => this.allSiteImages.find(image => image.title === imageKey)

    getDocumentImage = (imageKey, isForCss = false, justData = false) => {
        if (!imageKey) {
            return imageKey

        }
        const image = this.findImage(imageKey)
        if (!image) {
            return imageKey
        }

        if (justData) {
            return image
        }

        const value = image?.variation?.image_locator
        let src = value ? `${value.includes('http') ? '' : 'https://learningful.org'}${value}` : ""
        // if (image.variation.version) {
        //     src += `?v=${image.variation.version}`
        // }
        return isForCss ? `url(${src})` : src
    }

    getManyDocumentImages = (imageKeys) => {
        return imageKeys.map(imageKey => this.getDocumentImage(imageKey))
    }

    getDocumentImageAttributes = (imageKey, isForCss = false) => {
        const image = this.findImage(imageKey)
        if (!image) {
            return {
                src: "",
                alt: alt || `bad-alt ${imageKey}`
            }
        }
        const value = image?.variation?.image_locator
        let src = value ? `${value.includes('http') ? '' : 'https://learningful.org'}${value}` : ""
        // if (image.variation.version) {
        //     src += `?v=${image.variation.version}`
        // }
        const alt = image?.variation?.alt
        return {
            src,
            alt: alt || `bad-alt ${imageKey}`
        }
    }

    beforeMount = async (document, pageKey) => {
        if (document) {
            const d = await this.addImages(this.getManyDocumentImages(this.getImagesForPage(pageKey)).filter(path => !!path && path.includes('https')), document)
            return d
        }
        return true;
    }

    preloadUser = async (document, user) => {
        const userImage = `Sidebar Letter ${user.first_name.slice(0, 1).toUpperCase()} Avatar`
        const image = this.findImage(userImage)
        const images = [
            image.variation.image_locator,
            user.avatar_locator,
            getResizedLocator(user.avatar_locator, [50, 50]),
            getResizedLocator(user.avatar_locator, [135, 135]),
        ]
        this.addImages(images, document)
    }

    getPatterns = () => {
        return this.allSiteImages.filter(image => image.variation && image.title.includes('Array Pattern Overlay'))
    }

    getAndLoadAvatars = async () => {
        const image = this.findImage('User Account System Avatar')
        if (!image) {
            return []
        }
        return image.variations;
    }

    update = async () => {
        const response = await this.$sdk.get('/site-images/all-settings?force=true')
        const responseJson = await response.json();
        const { allSiteImages } = responseJson
        this.allSiteImages = allSiteImages
        return Promise.resolve(true);
    }
}

export default async (context, inject) => {
    const response = await context.$sdk.get('/site-images/all-settings')
    const responseJson = await response.json();
    const { allSiteImages } = responseJson

    const imageManager = new ImageManager(allSiteImages, context);
    imageManager.addColors(context.$imageManager)

    inject('preloader', imageManager);
};
