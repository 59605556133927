
import { orderFormBaseState, orderFormBaseGetters } from '@/mixins/OrderFormStore';
import { modalBaseGetters } from '@/mixins/ModalStore';

const defaults = Object.assign({}, orderFormBaseState, {
  placement: 'prompt',
  amount: 0,
  hasUserInteracted: false,
  extraUsers: [],
  backgroundKey: 'Checkout Donation User Selected Payment Background',
});

export const state = () => Object.assign({}, defaults);

export const getters = Object.assign({}, modalBaseGetters, orderFormBaseGetters, {
  modalCloseWarning(state) {
    if ($nuxt.$route.name?.startsWith('index-donation-payment')
      || $nuxt.$route.name?.startsWith('index-donate-completed')
      || $nuxt.$route.name?.startsWith('index-first-tip-payment')
      || $nuxt.$route.name?.startsWith('index-donate-payment')
    ) {
      return true;
    }
    return state.hasUserInteracted;
  },
});

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

};

export const actions = {

};
