
export default async function (context) {
  const { $sdk, route, redirect, from } = context;

  let permissions = context.store.state.main.userPermissions;
  if (!permissions?.id && $sdk.isAuthenticated()) {
    await context.store.dispatch('main/refreshUserPermissions');
    permissions = context.store.state.main.userPermissions;
  }

  if (route.name === 'index-profile-id-settings') {
    if (!permissions?.controls) {
      console.info(`Route blocked due to insufficient permission: controls`);
      redirect(`/profile/${route.params.id}/resources`);
      return;
    }
  }

  if (route.name === 'index-profile-id-tip') {
    if (!permissions?.payments) {
      console.info(`Route blocked due to insufficient permission: payments`);
      redirect(`/profile/${route.params.id}/resources`);
      return;
    }
  }

  if (route.name === 'index-profile-id-message') {
    if (!permissions?.messages) {
      console.info(`Route blocked due to insufficient permission: messages`);
      redirect(`/profile/${route.params.id}/resources`);
      return;
    }
  }


  if (route.name?.startsWith('index-resources-id-edit')) {
    if (!permissions?.upload) {
      console.info(`Route blocked due to insufficient permission: upload`);
      redirect(`/`);
      return;
    }
    if (!permissions?.editing) {
      console.info(`Route blocked due to insufficient permission: editing`);
      redirect(`/`);
      return;
    }
  }

  if (route.name?.startsWith('index-advertising')) {
    if (!permissions?.advertise) {
      console.info(`Route blocked due to insufficient permission: advertising`);
      redirect(`/`);
      return;
    }
  }

}
