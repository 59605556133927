import { modalBaseGetters } from '@/mixins/ModalStore';

const defaults = {
  offers: [],
  offersOrder: {}
};

export const state = () => Object.assign({}, defaults);

export const getters = Object.assign({}, modalBaseGetters, {});

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  randomizeOffers(state) {
    const offers = state.offers.slice();
    offers.sort((a, b) => Math.random() - Math.random());
    state.offers = offers;
  },

};

export const actions = {

  async loadOffers({ state, commit, dispatch }) {
    const response = await this.$sdk.get(`/advertisements/offers`);
    const responseJson = await response.json();

    commit('set', {
      offers: responseJson.offers,
    });

    return;
  },

  async ensureOffersExistsAndRandomiseOnce({ state, commit, dispatch }) {
    let shouldFetch = true;
    let randomize = true;
    if (state.offers.length > 0) {
      shouldFetch = false;
    }
    if (shouldFetch) {
      await dispatch('loadOffers')
    }
    if (randomize) {
      commit('randomizeOffers')
    }
  }
};
