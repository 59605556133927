
import { modalBaseGetters } from '@/mixins/ModalStore';

const defaults = {
  signUpImage: '',
  signUpImageTimeout: null,
  name: '',
  email: '',
  password: '',
  imageAlts: {
    "Sign Up Left Panel Background (Default)": "Your Learningful account lets you download, share, and publish free teaching materials.",
    "Sign Up Left Panel Background (Account Creation Success)": "You did it! You're officially the newest member of learningful. Woot! How cool is that?",
    "Sign Up Left Panel Background (Existing Account)": "An account already exists for this email address. You may only have one Learningful account.",
    "Sign Up Left Panel Background (Email)": "Your email address will be validated, and also functions as your Learningful username.",
    "Sign Up Left Panel Background (Email Maximum Character Limitation)": "The email address associated with your account may be a maximum of 64 characters.",
    "Sign Up Left Panel Background (Email Invalid)": "To sign up for a Learningful account, you must provide a valid, functional email address.",
    "Sign Up Left Panel Background (Name)": "The name associated with your account is case sensitive and used on Learningful to identify you.",
    "Sign Up Left Panel Background (Name Minimum Characters)": "Each segment of the name associated with your account must consist of at least 2 characters.",
    "Sign Up Left Panel Background (Name Maximum Characters)": "The name associated with your Learningful account may be a maximum of 32 characters.",
    "Sign Up Left Panel Background (Name Disallowed Character Warning)": "Your name may only consist of letters and non-consectutive spaces, dashes, and apostrophes.",
    "Sign Up Left Panel Background (Name Incomplete)": "To create your Learningful account, type both a first and last name, seperated by a space.",
    "Sign Up Left Panel Background (Name Space Limitation)": "The name associated with your Learningful account may contain a maxium of 3 spaces.",
    "Sign Up Left Panel Background (Name Special Character Limitation)": "The name associated with your Learningful account may only have 1 apostrophe or dash.",
    "Sign Up Left Panel Background (Name Bookend Character Warning)": "The name associated with your Learningful account must begin, and end, with a letter.",
    "Sign Up Left Panel Background (Name Honorific Title)": "The name associated with your Learningful account may not include an honorific title.",
    "Sign Up Left Panel Background (Name Uppercase Character)": "At least one segment of your last name must begin with an uppercase character.",
    "Sign Up Left Panel Background (Name Uppercase Character Limitation)": "Each segment of your name may contain a maximum of 2 uppercase characters.",
    "Sign Up Left Panel Background (Name Multipart Correction)": "To continue, please type a backslash / between your first and last name, as a demarcation.",
    "Sign Up Left Panel Background (Password)": "Your Learningful password is case sensitive and should be one you don't use elsewhere.",
    "Sign Up Left Panel Background (Password Maximum Character Warning)": "The password associated with your Learningful account may be a maximum of 24 characters.",
    "Sign Up Left Panel Background (Email Character Type Limitation)": "Your email address may include letters, numbers, underscores, periods, hyphens and the @ symbol.",
    "Sign Up Left Panel Background (Email Format Issues)": "Your email address doesn't look right. Are you certain it has been formatted properly?",
    "Sign Up Left Panel Background (Password Minimum Character Warning)": "Your Learningful password must have at least 1 number and be 8 characters or more.",
    "Sign Up Left Panel Background (Password Character Limitation)": "Your Learningful password may consist of letters, numbers, and $ ~ _ + ! # % ^ ? : - . & = [ ] ( ) / \\ ; @ *."
  }
}
export const state = () => {
  return { ...defaults };
};

export const getters = Object.assign({}, modalBaseGetters, {
  modalCloseWarning(_state) {
    return true;
  },
  modalCloseButton(_state) {
    return false;
  },
  signUpImageAlt(state) {
    return state.imageAlts[state.signUpImage]
  }
});

export const mutations = {

  clear(state) {
    for (const [key, val] of Object.entries(defaults)) {
      state[key] = val;
    }
  },

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  updateName(state, name) {
    state.name = name;
  },

  updateEmail(state, email) {
    state.email = email;
  },

  updatePassword(state, password) {
    state.password = password;
  },

};

export const actions = {

};
