
import { DateTime } from 'luxon';

export default async function (context) {
  const { $sdk, route, store, redirect, from } = context;

  if (route.query.refCode) {
    const dt = DateTime.now().toUTC().plus({ hours: 5 });
    const cookieExp = dt.toHTTP();
    if (document) {
      document.cookie = `refCode=${route.query.refCode}; path=/; expires=${cookieExp};`
    }

    if ($sdk.isAuthenticated()) {
      await $sdk.post(`/auth/refCode`, {
        refCode: route.query.refCode,
      });
    } else {
      store.commit('main/set', {
        bufferedRefCode: route.query.refCode,
      });
    }


  }

  return true;
}
