const { MODAL_CLOSE_X } = require('../common')

const resources = {
    images: [
        'Resource Preview Subject Banner',
        'Resource Preview Subject Banner (Hover)',
        'Resource Preview Subject Banner (OnClick)',
        'Resource Preview Share Link Icon (Active)',
        'Resource Preview Share Link Icon (Inactive)',
        'Resource Preview Favorite Icon (Active)',
        'Resource Preview Favorite Icon (Inactive)',
        'Upload Section Complete Icon',
        'Upload Section Complete Icon (Hover)',
        'Upload Section Complete Icon (OnClick)',
        MODAL_CLOSE_X,
        'Resource Preview Cover Image Placeholder',
        "Array Entry Subject Banner",
        "Resource Preview Downloaded Checkmark",
        'Resource Preview Gift Purchase Link Icon',
        'Resource Preview Gift Purchase Link Icon (Hover)',
        'Resource Preview Gift Purchase Link Icon (OnClick)',
        'Resource Preview Step Backward Button',
        'Resource Preview Step Backward Button (Hover)',
        'Resource Preview Step Backward Button (OnClick)',
    ],
    children: {
        id: {
            images: [
                'Resource Preview Downloaded Checkmark',
                'Resource Preview Ownership Notification Icon',
                'Resource Preview Gift Purchase Link Icon',
                'Resource Preview Gift Purchase Link Icon (Hover)',
                'Resource Preview Gift Purchase Link Icon (OnClick)',
                'Resource Preview Step Backward Button',
                'Resource Preview Step Backward Button (Hover)',
                'Resource Preview Step Backward Button (OnClick)',
            ],
            children: {
                downloaded: {
                    images: [
                        "Resource Download Confirmation Favorite Button",
                        "Resource Download Confirmation Favorite Button Active",
                        "Resource Download Confirmation Share Button",
                        "Resource Download Confirmation Share Button Active",
                        "Resource Download Confirmation Most Downloaded Up Arrow (Inactive)",
                        "Resource Download Confirmation Most Downloaded Up Arrow",
                        "Resource Download Confirmation Most Downloaded Down Arrow (Inactive)",
                        "Resource Download Confirmation Most Downloaded Down Arrow",
                        "Resource Download Confirmation Most Downloaded Background",
                    ]
                },
                donation: {
                    images: [
                        'Donation Interstitial Background'
                    ],
                    children: {}
                },
                success: {
                    images: [
                        'Publishing Upload Success Splash'
                    ],
                    children: {}
                },
                edit: {
                    images: [
                        "Upload Precheck Checkmark",
                        "Upload Section Complete Icon",
                        "Upload Section Complete Icon (Hover)",
                        "Upload Section Complete Icon (OnClick)",
                        "Upload Section Editing Icon",
                        "Upload Resource Feature Icon",
                        "Upload File Section Up Button",
                        "Upload File Section Up Button (Inactive)",
                        "Upload File Section Down Button",
                        "Upload File Section Down Button (Inactive)",
                        "Upload File Selection Delete Icon",
                        "Synthetic Persona Profile Navigation Button (Active)",
                        "Synthetic Persona Profile Navigation Button (Hover)",
                        "Synthetic Persona Profile Navigation Button (OnClick)",
                        "Synthetic Persona Profile Navigation Button (Inactive)",
                        "Upload Featured Resource Enabled Checkmark",
                        "Resource Preview Cover Image Placeholder",
                    ]
                }

            }
        }
    }
}

module.exports = resources