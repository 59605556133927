
const jwtDecode = require('jwt-decode');
import { DateTime } from 'luxon';

const requestLimitDefault = {
  isExceeded: false,
  earliestRetry: null,
  isLocked: false,
  url: null,
  secondsRemaining: 0,
  secondsRemainingInterval: 0,
};

export const state = () => (Object.assign({}, {
  postAuthRedirect: null,
  postAuthReload: false,
  requestLimit: Object.assign({}, requestLimitDefault),
  texts: {},
  colors: [],
  siteSettings: {},
  adStyleOverrides: '',
  pageTitle: '',
  promptDonation: false,
  detectedAdBlock: null,
  subfooterMessageType: 'total_resources',
  user: {},
  userPermissions: {},
  userLoaded: false,
  bufferedRefCode: '',
  accessToken: null,
  postLoginEvent: null,
  justLoggedOut: false,
  isModalShowing: false,
  modalPropsStoreName: null,
  successEmail: '',
  goToUserSettings: false
}));
// }, modalDefaults));

export const getters = {
  getUser: state => state.user,

  restrictedWordsLookup(state) {
    const restrictedWordsArr = state.siteSettings.restrictedWords || [];
    return restrictedWordsArr;
  },

  restrictedWordsRegexesLookup(state, getters) {
    const regexes = [];
    for (const group of getters.restrictedWordsLookup) {
      const reg = new RegExp(String.raw`${group[0]}`, 'i');
      regexes.push([reg, group[1]]);
    }
    return regexes;
  },

  restrictedWordsReplace(state, getters) {
    return (value) => {
      if (typeof value === 'undefined' || value === null) return value;
      let new_value = value;
      for (const lookup of getters.restrictedWordsRegexesLookup) {
        for (; ;) {
          const idx = new_value.search(lookup[0]);
          if (idx < 0) break;
          const is_upper = new_value.charAt(idx) === new_value.charAt(idx).toUpperCase();

          const phrase_words = lookup[1].split(' ');
          const cased_phrase_words = phrase_words.map(word => {
            const first_letter = (is_upper ? word[0].toUpperCase() : word[0].toLowerCase());
            const rest = word.substring(1);
            return `${first_letter}${rest}`
          });
          new_value = new_value.replace(lookup[0], cased_phrase_words.join(' '));
        }
      }
      return new_value;
    }
  },

  shouldDisplayAds(state, getters, rootState) {
    if (state.detectedAdBlock) {
      return false;
    }

    if (state.siteSettings?.donatedAdFreeMode) {
      if (state.user?.donation_date) {
        return false;
      }
    }

    return true;
  },

  colorsByName(state) {
    const colorsByName = {};
    for (const color of state.colors) {
      colorsByName[color.name] = color;
    }
    return colorsByName;
  },

  accessTokenDecoded(state) {
    let tokenObject = {};
    try {
      tokenObject = jwtDecode.default(state.accessToken);
    } catch (e) { }
    return tokenObject;
  },

  isAdmin(state, getters) {
    return getters.accessTokenDecoded.scopes?.includes('admin');
  },

  isAuthenticated(state, getters) {
    if (!state.accessToken) {
      return false;
    }
    const expTime = (getters.accessTokenDecoded?.exp || 0) * 1000;
    const timeUntilExp = expTime - Date.now();
    // const hoursUntilExp = timeUntilExp / (60 * 60 * 1000);
    if (timeUntilExp >= 0) {
      return true;
    }
    return false;
  },

  setJustLoggedOut(state) {
    return state.justLoggedOut;
  },

};

export const mutations = {

  set(state, values) {
    for (const [key, val] of Object.entries(values)) {
      state[key] = val;
    }
  },

  setUser(state, value) {
    state.user = value;
  },

  setPageTitle(state, title) {
    state.pageTitle = title;
  },

  setPromptDonation(state, shouldPropmt) {
    state.promptDonation = shouldPropmt;
  },

  setSubfooterMessageType(state, messageType) {
    state.subfooterMessageType = messageType;
  },

  updateText(state, { name, value }) {
    state.texts[name] = value;
  },

  updateColor(state, updatedColor) {
    if (process.client) {
      for (let i = 0; i < state.colors.length; i++) {
        const color = state.colors[i];
        if (color.name === updatedColor.name) {
          state.colors[i].css = updatedColor.css;
          document.documentElement.style.setProperty(color.cssVarName, updatedColor.css);
          return;
        }
      }
    }

  },

  loadFromLocalStorage(state) {
    if (process.client) {
      const jsonDataString = window.localStorage.getItem('store/main');
      if (!jsonDataString) {
        return;
      }
      const jsonData = JSON.parse(jsonDataString);
      state.accessToken = jsonData.accessToken;
      state.postAuthRedirect = jsonData.postAuthRedirect;
      state.user = jsonData.user;
    }
  },

  saveToLocalStorage(state) {
    const jsonDataString = JSON.stringify(state);
    if (process.client) {
      window.localStorage.setItem('store/main', jsonDataString);
    }
    // window.localStorage.setItem('store/main', jsonDataString);
  },

  setAccessToken(state, token) {
    state.accessToken = token;
    if (process.client && document) {
      document.cookie = `accessToken=${token}; path=/;`;
    }
  },

  setRequestLimit(state, limit) {
    state.requestLimit = limit;
  },

  setPostAuthRedirect(state, redirect) {
    state.postAuthRedirect = redirect;
  },

  setJustLoggedOut(state, hasJustLoggedOut) {
    state.justLoggedOut = hasJustLoggedOut;
  },

  clearAuthentication(state) {
    state.user = {};
    state.userPermissions = {};
    state.userLoaded = false;
    state.accessToken = null;
    state.bufferedRefCode = '';

    // clear ref code
    if (process.client && document) {
      document.cookie = `refCode=none; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    }
  },

};

function getSecondsRemaining(date) {
  return parseInt(DateTime.fromISO(date)
    .diff(DateTime.now().setZone("UTC-6"))
    .as('seconds'))
}

export const actions = {

  showModal(context, props = {}) {
    if (process.client) {
      window.setTimeout(() => {
        // context.commit('set', Object.assign({},
        //   modalDefaults,
        //   props,
        //   { isModalShowing: true }
        // ));
        context.commit('set', {
          isModalShowing: true,
          modalPropsStoreName: props.store,
        });
      }, 100);
    }
  },

  async closeModal(context) {
    context.commit('set', { isModalShowing: false, successEmail: '' });
    if (process.client) {
      await new Promise(resolve => window.setTimeout(resolve, 100));
    }
  },

  resetModalProperties(context) {
    context.commit('set', Object.assign({}, modalDefaults));
  },

  setRequestLimitExceeded(context, limit) {
    if (process.client) {
      window.clearInterval(context.state.requestLimit.secondsRemainingInterval);
    }
    const requestLimit = Object.assign({}, limit);
    context.commit('setRequestLimit', requestLimit);

    if (requestLimit.isLocked) {
      return;
    }
    if (process.client) {
      requestLimit.secondsRemainingInterval = window.setInterval(
        () => {
          const secondsRemaining = getSecondsRemaining(requestLimit.earliestRetry);

          if (secondsRemaining <= 0) {
            context.dispatch('clearRequestLimitExceeded');
            return;
          }

          context.commit('setRequestLimit', Object.assign({}, context.state.requestLimit, { secondsRemaining }));
        },
        1000
      );
    }

    const secondsRemaining = getSecondsRemaining(requestLimit.earliestRetry);
    context.commit('setRequestLimit', Object.assign({}, requestLimit, { secondsRemaining }));
  },

  clearRequestLimitExceeded(context) {
    if (process.client) {
      window.clearInterval(context.state.requestLimit.secondsRemainingInterval);
    }
    context.commit('setRequestLimit', Object.assign({}, requestLimitDefault));
  },

  async finishAuthentication(context, shouldRedirect = true) {
    if (!context.getters['shouldDisplayAds']) {
      context.commit('resource-grid/set', {
        resources: context.rootState['resource-grid'].resources.filter(r => !r.isAdvertisement),
      }, { root: true });
    }

    const giftRef = localStorage.getItem('post-sign-up-ref');
    window.localStorage.setItem('post-sign-up-ref', "");

    if (giftRef && giftRef!== "") {
      $nuxt.$router.push(giftRef);
      return
    }


    if (context.state.postAuthReload) {
      // reload
      $nuxt.$router.go();
      return;
    }

    if (context.state.bufferedRefCode) {
      await $sdk.post(`/auth/refCode`, {
        refCode: context.state.bufferedRefCode,
      });
      context.commit('set', {
        bufferedRefCode: '',
      });
    }
    if (shouldRedirect) {
      let redirect = context.state.postAuthRedirect // || '/first-tip'; 
      // if (context.state.promptDonation) {
      const amount = process.client ? window.localStorage.getItem('redirectToDonate') : "";
      if (amount && amount !== "") {
        context.commit('donate/set', {
          amount
        });
        window.localStorage.removeItem('redirectToDonate')
        $nuxt.$router.push('/donation/payment');
        return;
      }
      if (context.state.promptDonation) {
        if (context.state.user.is_master_account) {
          redirect = '/admin/users';
        }
        else if (context.state.user.donation_date === null) {
          redirect = '/donation';
        } else {
          if (context.getters['shouldDisplayAds']) {
            redirect = '/offers';
          } else {
            redirect = '/donation';
          }
        }
      }
      if (context.state.goToUserSettings) {
        redirect = `/profile/@${context.state.user.username}/settings`
        if (context.state.user.role === 'admin') {
          redirect = '/admin/settings';
        }
      }
      $nuxt.$router.push(redirect);
    } else {
      return;
    }


    context.commit('set', {
      postAuthRedirect: null,
      postAuthReload: false,
      promptDonation: false,
      goToUserSettings: false
    });
  },

  async refreshUserData({ state, commit, dispatch }) {
    if (!this.$sdk.isAuthenticated()) {
      return;
    }

    commit('set', { userLoaded: false });
    let response = await this.$sdk.get(`/users/self`);
    let responseJson = await response.json();

    commit('set', {
      user: responseJson.user || {},
    });

    await dispatch('refreshUserPermissions');
  },

  async refreshUserPermissions({ commit }) {
    const response = await this.$sdk.get(`/users/self/permissions`);
    if (!response || !response.ok) {
      commit('setAccessToken', "");
      commit('set', {
        user: {},
        // accessToken: null,
        userLoaded: true,
      });
      commit('saveToLocalStorage');

      return;
    }
    const responseJson = await response.json();
    commit('set', {
      userPermissions: responseJson.permissions,
      userLoaded: true,
    });
  },

  async loadSiteSettingsOffline({ state, commit }) {
    const response = await this.$sdk.get(`/site-settings/front-end-offline`);
    const responseJson = await response.json();

    commit('set', {
      siteSettings: { ...state.siteSettings, offlineMode: responseJson.offlineMode },
    });
  },

  async loadSiteSettingsCssOverride({ state, commit }) {
    const response = await this.$sdk.get(`/site-settings/front-end-css-override`);
    const responseJson = await response.json();

    commit('set', {
      siteSettings: { ...state.siteSettings, cssOverride: responseJson.settings.cssOverride },
    });
  },

  async loadSiteSettingsIdleTimeout({ state, commit }) {
    const response = await this.$sdk.get(`/site-settings/front-end-idle-timeout`);
    const responseJson = await response.json();

    commit('set', {
      siteSettings: { ...state.siteSettings, idleTimeoutSeconds: responseJson.idleTimeoutSeconds },
    });
  },

  async loadSiteSettings({ state, commit }) {
    const response = await this.$sdk.get(`/site-settings/front-end`);
    const responseJson = await response.json();

    commit('set', {
      siteSettings: responseJson.settings,
    });
  },

  async loadAdStyleOverrides({ state, commit }) {
    const response = await this.$sdk.get(`/advertisements/overrides`);
    const responseJson = await response.json();

    commit('set', {
      adStyleOverrides: responseJson.ad_overrides
    });
  },

  // async loadUserPermissions({ state, commit }) {
  //   if (state.userPermissions) {
  //     commit('set', { userLoaded: true });
  //     return;
  //   }
  //   commit('set', { userLoaded: false });
  // },

  blockUntilUserLoaded({ state }) {
    return new Promise((resolve) => {
      if (process.client) {
        const interval = window.setInterval(() => {
          if (state.userLoaded) {
            window.clearInterval(interval);
            return resolve();
          }
        }, 50);
      } else {
        return resolve()
      }
    });
  },

  async loadTexts({ state, commit }) {
    const response = await this.$sdk.get('/texts/values');
    const responseJson = await response.json();

    // TODO TODO TODO
    responseJson.texts['portfolio tip--infoline 1'] = `Select an amount to <b>Leave a Tip</b> for `;
    responseJson.texts['portfolio tip--send a note?'] = `Care to send a note to this contributor, along with your tip? Write one above.`;
    responseJson.texts['portfolio tip--already tipped'] = `You may only tip this contributor once per day.`;
    responseJson.texts['portfolio tip--consider tipping'] = `
      Do you appreciate the use of this contributor's learning
      resources? If so, please consider leaving him or her a small tip,
      to show your support. When you do so, you're not only rewarding
      the author for publishing superb resources in the past, but
      also encouraging the creation of even better materials in the
      future. To leave your tip, enter a brief message (optional), and select
      the amount you'd like to provide. Thank you for supporting Learningful
      contributors!`;

    responseJson.texts['advertising manage basic--paragraph'] = `
        Learningful contributors are supported, in part, by five unique types of
        non-intrusive ads that show at different times and places on the
        platform. If you have an education, learning, family, or kids-related
        product or service to promote, you may run any number of ads, and ad
        types, for as little as $1 per ad, per day. To do so, please select the
        ad type below that you would like to create.
      `;

    responseJson.texts['advertising manage all--title'] = `
        Learningful Ad Management: <b>Monitor or Modify Existing Ads</b>
      `;


    responseJson.texts['admin email all--message sent'] = "Message Sent";

    commit('set', {
      texts: responseJson.texts,
    });
  },

  async loadColors({ state, commit }) {
    const response = await this.$sdk.get('/colors/values');
    const responseJson = await response.json();
    commit('set', {
      colors: responseJson.colors,
    });
    if (process.client) {
      for (const color of responseJson.colors) {
        document.documentElement.style.setProperty(color.cssVarName, color.css);
      }
    }
  },
  async markUserActive({getters}) {
    if (getters["isAuthenticated"]) {
      await this.$sdk.post(`/users/self/mark-active`);
    }
  }
};
