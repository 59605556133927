const {MODAL_CLOSE_X} = require('../common')

const login = {
    images: [
        MODAL_CLOSE_X,
        'Login Left Panel Image',
    ],
    children: {}
}

module.exports = login