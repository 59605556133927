const signUp = {
    images: [
        "Sign Up Left Panel Background (Default)",
        "Sign Up Left Panel Background (Email)",
        "Sign Up Left Panel Background (Email Character Type Limitation)",
        "Sign Up Left Panel Background (Existing Account)",
        "Sign Up Left Panel Background (Email Format Issues)",
        "Sign Up Left Panel Background (Email Maximum Character Limitation)",
        "Sign Up Left Panel Background (Email Invalid)",
        "Sign Up Left Panel Background (Name)",
        "Sign Up Left Panel Background (Name Bookend Character Warning)",
        "Sign Up Left Panel Background (Name Disallowed Character Warning)",
        "Sign Up Left Panel Background (Name Uppercase Character)",
        "Sign Up Left Panel Background (Naem Honorific Title)",
        "Sign Up Left Panel Background (Name Incomplete)",
        "Sign Up Left Panel Background (Name Last Uppercase Character)",
        "Sign Up Left Panel Background (Name Maximum Characters)",
        "Sign Up Left Panel Background (Name Minimum Characters)",
        "Sign Up Left Panel Background (Name Multipart Correction)",
        "Sign Up Left Panel Background (Name Space Limitation)",
        "Sign Up Left Panel Background (Name Special Character Limitation)",
        "Sign Up Left Panel Background (Name Uppercase Character Limitation)",
        "Sign Up Left Panel Background (Intermediary)",
        "Sign Up Left Panel Background (Password)",
        "Sign Up Left Panel Background (Password Character Limitation)",
        "Sign Up Left Panel Background (Password Maximum Character Warning)",
        "Sign Up Left Panel Background (Password Minimum Character Warning)",
        "Sign Up Left Panel Background (Account Creation Success)",
        "Sign Up Left Panel Background (Captcha)",
        "Sign Up Submit Button Icon",
        "Sign Up Submit Button Icon (Active)",
        "Sign Up Submit Button Icon (Inactive)",
        "Sign Up Submit Button Icon (Postclick)",
    ],
    children: {
        captcha: {
            images: [
                "Captcha Letter A",
                "Captcha Letter B",
                "Captcha Letter C",
                "Captcha Letter D",
                "Captcha Letter E",
                "Captcha Letter F",
                "Captcha Letter G",
                "Captcha Letter H",
                "Captcha Letter I",
                "Captcha Letter J",
                "Captcha Letter K",
                "Captcha Letter L",
                "Captcha Letter M",
                "Captcha Letter N",
                "Captcha Letter O",
                "Captcha Letter P",
                "Captcha Letter Q",
                "Captcha Letter R",
                "Captcha Letter S",
                "Captcha Letter T",
                "Captcha Letter U",
                "Captcha Letter V",
                "Captcha Letter W",
                "Captcha Letter X",
                "Captcha Letter Y",
                "Captcha Letter Z",
            ]
        },
        success: {
            images: [
                'Sign Up Left Panel Background (Account Creation Success)'
            ]
        }
    }
}

module.exports = signUp;