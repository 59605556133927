
export default async function (context) {
  const { $sdk, route, redirect, from } = context;

  // XXX XXX XXX this is a quick workaround to prevent the production site
  // from being visible before the initial release
  // const devSecret = window.localStorage.getItem('dev-secret');
  // if (!devSecret) {
  //   if (route.name === 'offline') {
  //     return;
  //   }
  //   const response = window.prompt("Authentication required");
  //   if (response === 'let me in') {
  //     window.localStorage.setItem('dev-secret', 'okay');
  //   } else {
  //     return redirect('/offline');
  //   }
  // }
  ///////////////////////////////

  if (context.isHMR) {
    return;
  }

  if (route.name === 'offline' || route.name === 'index-login') {
    return;
  }

  const isAdmin = context.store.getters['main/isAdmin'];
  if (isAdmin) {
    return;
  }

  let offlineMode = context.store.state.main.siteSettings.offlineMode;

  console.log(offlineMode)

  if (offlineMode === undefined) {
    await context.store.dispatch('main/loadSiteSettingsOffline');
    offlineMode = context.store.state.main.siteSettings.offlineMode;
  }

  if (offlineMode) {
    return redirect('/offline');
  }

  return true;
}
