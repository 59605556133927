

const earnings = {
    images: [
        "Earnings Splash",
        "Earnings Splash Button 1",
        "Earnings Splash Button 1 Active",
        "Earnings Splash Button 2",
        "Earnings Splash Button 2 Active",
        "Earnings Splash Button 3",
        "Earnings Splash Button 3 Active",
        "Earnings Splash Button 4",
        "Earnings Splash Button 4 Active",

    ],
    children: {
        activity: {
            images: [
                "Earnings Activity Navigation Button Up",
                "Earnings Activity Navigation Button Down",
            ]
        },
        graph: {
            images: [
                "Earnings Graph Left Navigation Button",
                "Earnings Graph Right Navigation Button",
            ]
        },
        data: {
            images: [
                "Earnings Data Calendar Navigation Right Button",
                "Earnings Data Calendar Navigation Right Button (Hover)",
                "Earnings Data Calendar Navigation Right Button (OnClick)",
                "Earnings Data Calendar Navigation Left Button",
                "Earnings Data Calendar Navigation Left Button (Hover)",
                "Earnings Data Calendar Navigation Left Button (OnClick)",
                "Earnings Data Downloads Edit Icon (Heading)",
                "Earnings Data Downloads Edit Icon (Active)",
                "Earnings Data Downloads Edit Icon (Hover)",
                "Earnings Data Downloads Edit Icon (OnClick)",
                "Earnings Data Downloads Edit Icon (Inactive)",
                "Earnings Data Downloads Icon",
                "Earnings Data Downloads Icon (OnClick)",
                "Earnings Data Downloads Icon (Hover)",
                "Earnings Data Referrals Icon",
                "Earnings Data Referrals Icon (OnClick)",
                "Earnings Data Referrals Icon (Hover)",
                "Earnings Data Sales Icon",
                "Earnings Data Sales Icon (OnClick)",
                "Earnings Data Sales Icon (Hover)",
            ]
        },
        payout: {
            images: [
                "Earnings Payout Checkmark",
            ]
        }
    }
}

module.exports = earnings;