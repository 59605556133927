/**
 * @typedef {Object} Resource
 * @property {number} price - The price of the resource.
 * @property {number} sale_price - The sale_price of the resource.
 * @property {string} sale_end - The sale_end of the resource.
 * @property {number} stalking - The stalking of the resource.
 */

class ResourceValidator {
    /**
     * A function that checks the validation of the Resource Price
     *
     * @param {Resource} resource
     * @returns {number} - The result can be one of the following:
     *   - 0: Represents "valid"
     *   - 1: Represents "invalid_price"
    */
    validatePrice(resource) {
        const price = parseFloat(resource.price);
        const files = resource?.resources_files_models ?? [];
        const premium = files.find(x => x.is_premium_file)
        if (price === 0) {
            if (premium) {
                return 1
            }
            return 0;
        }

        if (price < 1) {
            return 1;
        }

        if (price > 100) {
            return 1;
        }

        return 0
    }

    /**
     * A function that checks the validation of the Resource Sale Price
     *
     * @param {Resource} resource
     * @returns {number} - The result can be one of the following:
     *   - 0: Represents "valid"
     *   - 1: Represents "invalid_sale_price"
     *   - 2: Represents "high_sale_price"
     *   - 3: Represents "sale_price_min_with_date"
    */
    validateSalePrice(resource) {
        const price = parseFloat(resource.price);
        const salePrice = parseFloat(resource.sale_price);
        if (salePrice === 0) {

            if (["", "0", 0, null, undefined].includes(resource.sale_end)) {
                return 0;
            } else {
                return 3;
            }
        }

        if (salePrice >= price) {
            return 2;
        }
        // if (price !== 0) {
        // }

        if (salePrice < 1) {
            return 1;
        }

        if (salePrice > 100) {
            return 1;
        }

        return 0;
    }

    /**
     * A function that checks the validation of the Resource Sale Price
     *
     * @param {Resource} resource
     * @returns {number} - The result can be one of the following:
     *   - 0: Represents "valid"
     *   - 1: Represents "sale_end_invalid"
     *   - 2: Represents "sale_end_in_past"
     *   - 3: Represents "sale_end_not_within_thirty_days"
     *   - 4: Represents "sale_end_empty"
     *   - 5: Represents "sale_price_min_with_date"
    */
    validateSaleEnd(resource) {
        const salePrice = parseFloat(resource.sale_price);
        const saleEnd = resource.sale_end;
        if (salePrice === 0) {
            if (["", "0", 0, null, undefined].includes(resource.sale_end)) {
                return 0;
            } else {
                return 5;
            }
        }
        if (saleEnd?.includes('*')) {
            return 0;
        }
        if (saleEnd === "0" || saleEnd === "") {
            return 3;
        }
        const dateSplit = saleEnd?.split('/') ?? [];
        if (dateSplit.length !== 3) {
          return 1
        }
        const [month, day, year] = dateSplit;
        const date = new Date(year, parseInt(month) - 1, day);

        if (date.toString() === "Invalid Date") {
            return 1
        }

        const now = new Date()
        if (date < now) {
            return 2
        }

        const nowPlusThirtyDays = new Date(now);
        nowPlusThirtyDays.setDate(now.getDate() + 30);
        nowPlusThirtyDays.setHours(23, 59, 59, 999);
        if (date > nowPlusThirtyDays) {
            return 3;
        }

        return 0
    }
}


export default ResourceValidator;