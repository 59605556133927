
const helpbook = {
    images: [
        "Helpbook Left Panel Background",
        "Helpbook Search Icon",
        "Helpbook Menu Navigation Up Button (Inactive)",
        "Helpbook Menu Navigation Up Button (Active)",
        "Helpbook Menu Navigation Down Button (Inactive)",
        "Helpbook Menu Navigation Down Button (Active)",
        "Helpbook Video Play Button Default",
        "Helpbook Video Play Button OnClick",
        "Helpbook Video Play Button Hover",
        "Helpbook New Article Button",
        "Helpbook New Image Button",
        "Helpbook New Video Button",
        "Helpbook Substantive Change Checkmark",
        "Helpbook File Deletion Icon",
        "Helpbook File Deletion Icon (Hover)",
        "Helpbook File Deletion Icon (OnClick)",
        "Helpbook Media Updated Checkmark",

    ],
    children: {
        manage: {
            images: [
                `Helpbook New Article Button`,
                `Helpbook New Image Button`,
                `Helpbook New Video Button`,
            ]
        },
        acceptterms: {
            images: [
                'Helpbook Terms Accepted Splash'
            ]
        }
    }
}

module.exports = helpbook;