import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8ce5946e = () => interopDefault(import('../pages/offline.vue' /* webpackChunkName: "pages/offline" */))
const _8795d788 = () => interopDefault(import('../pages/payflow.vue' /* webpackChunkName: "pages/payflow" */))
const _aa3db946 = () => interopDefault(import('../pages/payflow/cancel.vue' /* webpackChunkName: "pages/payflow/cancel" */))
const _8ac2c816 = () => interopDefault(import('../pages/payflow/error.vue' /* webpackChunkName: "pages/payflow/error" */))
const _740fd9a0 = () => interopDefault(import('../pages/payflow/success.vue' /* webpackChunkName: "pages/payflow/success" */))
const _806e9ff2 = () => interopDefault(import('../pages/test/captcha.vue' /* webpackChunkName: "pages/test/captcha" */))
const _c76cc51a = () => interopDefault(import('../pages/test/colors.vue' /* webpackChunkName: "pages/test/colors" */))
const _53a4cbee = () => interopDefault(import('../pages/test/help-center.vue' /* webpackChunkName: "pages/test/help-center" */))
const _489186f6 = () => interopDefault(import('../pages/test/homepage-layout.vue' /* webpackChunkName: "pages/test/homepage-layout" */))
const _660d1527 = () => interopDefault(import('../pages/test/image-rendering.vue' /* webpackChunkName: "pages/test/image-rendering" */))
const _b2b374a2 = () => interopDefault(import('../pages/test/misc.vue' /* webpackChunkName: "pages/test/misc" */))
const _1c0b9e1d = () => interopDefault(import('../pages/test/pattern.vue' /* webpackChunkName: "pages/test/pattern" */))
const _1048e29a = () => interopDefault(import('../pages/test/secret.vue' /* webpackChunkName: "pages/test/secret" */))
const _0dbb5c90 = () => interopDefault(import('../pages/test/sign-up.vue' /* webpackChunkName: "pages/test/sign-up" */))
const _3fe23d84 = () => interopDefault(import('../pages/test/upload.vue' /* webpackChunkName: "pages/test/upload" */))
const _07569cb8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1438b93b = () => interopDefault(import('../pages/index/index.vue' /* webpackChunkName: "pages/index/index" */))
const _45a80d38 = () => interopDefault(import('../pages/index/admin.vue' /* webpackChunkName: "pages/index/admin" */))
const _82586c8a = () => interopDefault(import('../pages/index/admin/index.vue' /* webpackChunkName: "pages/index/admin/index" */))
const _43b9e5fc = () => interopDefault(import('../pages/index/admin/accounting.vue' /* webpackChunkName: "pages/index/admin/accounting" */))
const _701e454e = () => interopDefault(import('../pages/index/admin/email-all.vue' /* webpackChunkName: "pages/index/admin/email-all" */))
const _068e8deb = () => interopDefault(import('../pages/index/admin/exceptions.vue' /* webpackChunkName: "pages/index/admin/exceptions" */))
const _0e488a80 = () => interopDefault(import('../pages/index/admin/exceptions/advertising.vue' /* webpackChunkName: "pages/index/admin/exceptions/advertising" */))
const _72adb069 = () => interopDefault(import('../pages/index/admin/exceptions/payouts.vue' /* webpackChunkName: "pages/index/admin/exceptions/payouts" */))
const _19265247 = () => interopDefault(import('../pages/index/admin/exceptions/publishing.vue' /* webpackChunkName: "pages/index/admin/exceptions/publishing" */))
const _37d0e7ab = () => interopDefault(import('../pages/index/admin/exceptions/support.vue' /* webpackChunkName: "pages/index/admin/exceptions/support" */))
const _51dd1f6c = () => interopDefault(import('../pages/index/admin/settings.vue' /* webpackChunkName: "pages/index/admin/settings" */))
const _34e25d8a = () => interopDefault(import('../pages/index/admin/statistics.vue' /* webpackChunkName: "pages/index/admin/statistics" */))
const _043da3f1 = () => interopDefault(import('../pages/index/admin/users.vue' /* webpackChunkName: "pages/index/admin/users" */))
const _4c57cdcd = () => interopDefault(import('../pages/index/advertising.vue' /* webpackChunkName: "pages/index/advertising" */))
const _3cb40690 = () => interopDefault(import('../pages/index/advertising/index.vue' /* webpackChunkName: "pages/index/advertising/index" */))
const _fcfdd3a4 = () => interopDefault(import('../pages/index/advertising/create.vue' /* webpackChunkName: "pages/index/advertising/create" */))
const _76e093d2 = () => interopDefault(import('../pages/index/advertising/manage.vue' /* webpackChunkName: "pages/index/advertising/manage" */))
const _2fb26cf8 = () => interopDefault(import('../pages/index/advertising/_id.vue' /* webpackChunkName: "pages/index/advertising/_id" */))
const _d3f14d0a = () => interopDefault(import('../pages/index/advertising/_id/index.vue' /* webpackChunkName: "pages/index/advertising/_id/index" */))
const _4a5dfe36 = () => interopDefault(import('../pages/index/advertising/_id/approve.vue' /* webpackChunkName: "pages/index/advertising/_id/approve" */))
const _0b13a31e = () => interopDefault(import('../pages/index/advertising/_id/edit.vue' /* webpackChunkName: "pages/index/advertising/_id/edit" */))
const _d3ef4798 = () => interopDefault(import('../pages/index/advertising/_id/edit/index.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/index" */))
const _181c2dc2 = () => interopDefault(import('../pages/index/advertising/_id/edit/ad-spend.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/ad-spend" */))
const _1679f040 = () => interopDefault(import('../pages/index/advertising/_id/edit/button.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/button" */))
const _042406fb = () => interopDefault(import('../pages/index/advertising/_id/edit/code.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/code" */))
const _6a486a39 = () => interopDefault(import('../pages/index/advertising/_id/edit/delete.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/delete" */))
const _298912de = () => interopDefault(import('../pages/index/advertising/_id/edit/description.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/description" */))
const _6b864e4a = () => interopDefault(import('../pages/index/advertising/_id/edit/discount-code.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/discount-code" */))
const _5ee68e4c = () => interopDefault(import('../pages/index/advertising/_id/edit/identity.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/identity" */))
const _dc5b1f86 = () => interopDefault(import('../pages/index/advertising/_id/edit/image.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/image" */))
const _6f1b3e30 = () => interopDefault(import('../pages/index/advertising/_id/edit/payment.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/payment" */))
const _dfd38534 = () => interopDefault(import('../pages/index/advertising/_id/edit/review.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/review" */))
const _7742d151 = () => interopDefault(import('../pages/index/advertising/_id/edit/statement.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/statement" */))
const _0fe33900 = () => interopDefault(import('../pages/index/advertising/_id/edit/status.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/status" */))
const _13946d10 = () => interopDefault(import('../pages/index/advertising/_id/edit/styling.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/styling" */))
const _004e810e = () => interopDefault(import('../pages/index/advertising/_id/edit/subject.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/subject" */))
const _2787a6b4 = () => interopDefault(import('../pages/index/advertising/_id/edit/subtitle.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/subtitle" */))
const _0dd4b0da = () => interopDefault(import('../pages/index/advertising/_id/edit/targeting.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/targeting" */))
const _46dd22ba = () => interopDefault(import('../pages/index/advertising/_id/edit/title.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/title" */))
const _e785665e = () => interopDefault(import('../pages/index/advertising/_id/edit/url.vue' /* webpackChunkName: "pages/index/advertising/_id/edit/url" */))
const _59726ca4 = () => interopDefault(import('../pages/index/advertising/_id/submitted.vue' /* webpackChunkName: "pages/index/advertising/_id/submitted" */))
const _78f970d6 = () => interopDefault(import('../pages/index/donate.vue' /* webpackChunkName: "pages/index/donate" */))
const _20677ed9 = () => interopDefault(import('../pages/index/donate/index.vue' /* webpackChunkName: "pages/index/donate/index" */))
const _33324583 = () => interopDefault(import('../pages/index/donate/cancel.vue' /* webpackChunkName: "pages/index/donate/cancel" */))
const _d553cf5c = () => interopDefault(import('../pages/index/donate/completed.vue' /* webpackChunkName: "pages/index/donate/completed" */))
const _37936e4d = () => interopDefault(import('../pages/index/donate/payment.vue' /* webpackChunkName: "pages/index/donate/payment" */))
const _6253ccb9 = () => interopDefault(import('../pages/index/donation.vue' /* webpackChunkName: "pages/index/donation" */))
const _450fb708 = () => interopDefault(import('../pages/index/donation/index.vue' /* webpackChunkName: "pages/index/donation/index" */))
const _18365440 = () => interopDefault(import('../pages/index/donation/cancel.vue' /* webpackChunkName: "pages/index/donation/cancel" */))
const _19dd91a0 = () => interopDefault(import('../pages/index/donation/payment.vue' /* webpackChunkName: "pages/index/donation/payment" */))
const _180399b3 = () => interopDefault(import('../pages/index/donation/payment/index.vue' /* webpackChunkName: "pages/index/donation/payment/index" */))
const _4d5593a8 = () => interopDefault(import('../pages/index/donation/payment/completed.vue' /* webpackChunkName: "pages/index/donation/payment/completed" */))
const _2294fe50 = () => interopDefault(import('../pages/index/earnings.vue' /* webpackChunkName: "pages/index/earnings" */))
const _23c4ee5a = () => interopDefault(import('../pages/index/earnings/index.vue' /* webpackChunkName: "pages/index/earnings/index" */))
const _0f421b1e = () => interopDefault(import('../pages/index/earnings/activity.vue' /* webpackChunkName: "pages/index/earnings/activity" */))
const _d653348e = () => interopDefault(import('../pages/index/earnings/data.vue' /* webpackChunkName: "pages/index/earnings/data" */))
const _4f6af962 = () => interopDefault(import('../pages/index/earnings/graph.vue' /* webpackChunkName: "pages/index/earnings/graph" */))
const _dda39816 = () => interopDefault(import('../pages/index/earnings/payout.vue' /* webpackChunkName: "pages/index/earnings/payout" */))
const _30e4dfc0 = () => interopDefault(import('../pages/index/favorites.vue' /* webpackChunkName: "pages/index/favorites" */))
const _adbc8a0c = () => interopDefault(import('../pages/index/following.vue' /* webpackChunkName: "pages/index/following" */))
const _3629c468 = () => interopDefault(import('../pages/index/help.vue' /* webpackChunkName: "pages/index/help" */))
const _6700fad1 = () => interopDefault(import('../pages/index/helpbook.vue' /* webpackChunkName: "pages/index/helpbook" */))
const _40a06094 = () => interopDefault(import('../pages/index/helpbook/index.vue' /* webpackChunkName: "pages/index/helpbook/index" */))
const _0a3f2cb0 = () => interopDefault(import('../pages/index/helpbook/accept-terms.vue' /* webpackChunkName: "pages/index/helpbook/accept-terms" */))
const _3e2e9c93 = () => interopDefault(import('../pages/index/helpbook/manage.vue' /* webpackChunkName: "pages/index/helpbook/manage" */))
const _483577fc = () => interopDefault(import('../pages/index/helpbook/_id.vue' /* webpackChunkName: "pages/index/helpbook/_id" */))
const _8f21dd02 = () => interopDefault(import('../pages/index/helpbook/_id/index.vue' /* webpackChunkName: "pages/index/helpbook/_id/index" */))
const _380400a8 = () => interopDefault(import('../pages/index/helpbook/_id/video.vue' /* webpackChunkName: "pages/index/helpbook/_id/video" */))
const _443f475c = () => interopDefault(import('../pages/index/login.vue' /* webpackChunkName: "pages/index/login" */))
const _2b3c3671 = () => interopDefault(import('../pages/index/logout.vue' /* webpackChunkName: "pages/index/logout" */))
const _9980b96e = () => interopDefault(import('../pages/index/new.vue' /* webpackChunkName: "pages/index/new" */))
const _53a483fe = () => interopDefault(import('../pages/index/offers.vue' /* webpackChunkName: "pages/index/offers" */))
const _0a37de34 = () => interopDefault(import('../pages/index/offers/_index.vue' /* webpackChunkName: "pages/index/offers/_index" */))
const _3b119a31 = () => interopDefault(import('../pages/index/portfolio.vue' /* webpackChunkName: "pages/index/portfolio" */))
const _3b31335c = () => interopDefault(import('../pages/index/profile.vue' /* webpackChunkName: "pages/index/profile" */))
const _fa7788c8 = () => interopDefault(import('../pages/index/profile/tip-completed.vue' /* webpackChunkName: "pages/index/profile/tip-completed" */))
const _049c81b7 = () => interopDefault(import('../pages/index/profile/tip-summary.vue' /* webpackChunkName: "pages/index/profile/tip-summary" */))
const _e8ef8a06 = () => interopDefault(import('../pages/index/profile/_id.vue' /* webpackChunkName: "pages/index/profile/_id" */))
const _4a49cfc0 = () => interopDefault(import('../pages/index/profile/_id/index.vue' /* webpackChunkName: "pages/index/profile/_id/index" */))
const _2a91f4fb = () => interopDefault(import('../pages/index/profile/_id/about.vue' /* webpackChunkName: "pages/index/profile/_id/about" */))
const _cc29f216 = () => interopDefault(import('../pages/index/profile/_id/message.vue' /* webpackChunkName: "pages/index/profile/_id/message" */))
const _bb102eda = () => interopDefault(import('../pages/index/profile/_id/resources.vue' /* webpackChunkName: "pages/index/profile/_id/resources" */))
const _1297f025 = () => interopDefault(import('../pages/index/profile/_id/settings.vue' /* webpackChunkName: "pages/index/profile/_id/settings" */))
const _1c1fa84d = () => interopDefault(import('../pages/index/profile/_id/share.vue' /* webpackChunkName: "pages/index/profile/_id/share" */))
const _385f9218 = () => interopDefault(import('../pages/index/profile/_id/status.vue' /* webpackChunkName: "pages/index/profile/_id/status" */))
const _9dd68eee = () => interopDefault(import('../pages/index/profile/_id/tip.vue' /* webpackChunkName: "pages/index/profile/_id/tip" */))
const _6facf44c = () => interopDefault(import('../pages/index/profile/_id/tip/index.vue' /* webpackChunkName: "pages/index/profile/_id/tip/index" */))
const _2baaf15a = () => interopDefault(import('../pages/index/publishing.vue' /* webpackChunkName: "pages/index/publishing" */))
const _62b4e3f0 = () => interopDefault(import('../pages/index/resource-not-found.vue' /* webpackChunkName: "pages/index/resource-not-found" */))
const _c0e0c6e4 = () => interopDefault(import('../pages/index/resources.vue' /* webpackChunkName: "pages/index/resources" */))
const _70f2f339 = () => interopDefault(import('../pages/index/resources/_id.vue' /* webpackChunkName: "pages/index/resources/_id" */))
const _4e7f2a08 = () => interopDefault(import('../pages/index/resources/_id/index.vue' /* webpackChunkName: "pages/index/resources/_id/index" */))
const _79ce9912 = () => interopDefault(import('../pages/index/resources/_id/approve.vue' /* webpackChunkName: "pages/index/resources/_id/approve" */))
const _61463af5 = () => interopDefault(import('../pages/index/resources/_id/completed.vue' /* webpackChunkName: "pages/index/resources/_id/completed" */))
const _658b9150 = () => interopDefault(import('../pages/index/resources/_id/donation.vue' /* webpackChunkName: "pages/index/resources/_id/donation" */))
const _9dba4626 = () => interopDefault(import('../pages/index/resources/_id/downloaded.vue' /* webpackChunkName: "pages/index/resources/_id/downloaded" */))
const _ccf72e60 = () => interopDefault(import('../pages/index/resources/_id/edit.vue' /* webpackChunkName: "pages/index/resources/_id/edit" */))
const _0519d95a = () => interopDefault(import('../pages/index/resources/_id/edit/index.vue' /* webpackChunkName: "pages/index/resources/_id/edit/index" */))
const _5002792a = () => interopDefault(import('../pages/index/resources/_id/edit/categories.vue' /* webpackChunkName: "pages/index/resources/_id/edit/categories" */))
const _1f96d20c = () => interopDefault(import('../pages/index/resources/_id/edit/delete.vue' /* webpackChunkName: "pages/index/resources/_id/edit/delete" */))
const _54a09286 = () => interopDefault(import('../pages/index/resources/_id/edit/description.vue' /* webpackChunkName: "pages/index/resources/_id/edit/description" */))
const _3fa96b10 = () => interopDefault(import('../pages/index/resources/_id/edit/files.vue' /* webpackChunkName: "pages/index/resources/_id/edit/files" */))
const _5a08c050 = () => interopDefault(import('../pages/index/resources/_id/edit/grade.vue' /* webpackChunkName: "pages/index/resources/_id/edit/grade" */))
const _32136547 = () => interopDefault(import('../pages/index/resources/_id/edit/images.vue' /* webpackChunkName: "pages/index/resources/_id/edit/images" */))
const _7811308e = () => interopDefault(import('../pages/index/resources/_id/edit/keywords.vue' /* webpackChunkName: "pages/index/resources/_id/edit/keywords" */))
const _b977e542 = () => interopDefault(import('../pages/index/resources/_id/edit/pre-check.vue' /* webpackChunkName: "pages/index/resources/_id/edit/pre-check" */))
const _a23eef6c = () => interopDefault(import('../pages/index/resources/_id/edit/price.vue' /* webpackChunkName: "pages/index/resources/_id/edit/price" */))
const _c7a653f8 = () => interopDefault(import('../pages/index/resources/_id/edit/reading-level.vue' /* webpackChunkName: "pages/index/resources/_id/edit/reading-level" */))
const _16026a27 = () => interopDefault(import('../pages/index/resources/_id/edit/review.vue' /* webpackChunkName: "pages/index/resources/_id/edit/review" */))
const _303923de = () => interopDefault(import('../pages/index/resources/_id/edit/skills.vue' /* webpackChunkName: "pages/index/resources/_id/edit/skills" */))
const _bf93b552 = () => interopDefault(import('../pages/index/resources/_id/edit/standards.vue' /* webpackChunkName: "pages/index/resources/_id/edit/standards" */))
const _d461347e = () => interopDefault(import('../pages/index/resources/_id/edit/status.vue' /* webpackChunkName: "pages/index/resources/_id/edit/status" */))
const _5f299b1d = () => interopDefault(import('../pages/index/resources/_id/edit/subject-area.vue' /* webpackChunkName: "pages/index/resources/_id/edit/subject-area" */))
const _a3704c4e = () => interopDefault(import('../pages/index/resources/_id/edit/title.vue' /* webpackChunkName: "pages/index/resources/_id/edit/title" */))
const _fa8ba2c0 = () => interopDefault(import('../pages/index/resources/_id/feature.vue' /* webpackChunkName: "pages/index/resources/_id/feature" */))
const _dcdd85ba = () => interopDefault(import('../pages/index/resources/_id/feature/index.vue' /* webpackChunkName: "pages/index/resources/_id/feature/index" */))
const _78f8bcc8 = () => interopDefault(import('../pages/index/resources/_id/feature/completed.vue' /* webpackChunkName: "pages/index/resources/_id/feature/completed" */))
const _764ce82f = () => interopDefault(import('../pages/index/resources/_id/leave-tip.vue' /* webpackChunkName: "pages/index/resources/_id/leave-tip" */))
const _05df611c = () => interopDefault(import('../pages/index/resources/_id/leave-tip/index.vue' /* webpackChunkName: "pages/index/resources/_id/leave-tip/index" */))
const _5982f46b = () => interopDefault(import('../pages/index/resources/_id/leave-tip/completed.vue' /* webpackChunkName: "pages/index/resources/_id/leave-tip/completed" */))
const _bbfdbb74 = () => interopDefault(import('../pages/index/resources/_id/leave-tip/summary.vue' /* webpackChunkName: "pages/index/resources/_id/leave-tip/summary" */))
const _212d6547 = () => interopDefault(import('../pages/index/resources/_id/purchase.vue' /* webpackChunkName: "pages/index/resources/_id/purchase" */))
const _60749a8a = () => interopDefault(import('../pages/index/resources/_id/purchase/index.vue' /* webpackChunkName: "pages/index/resources/_id/purchase/index" */))
const _e5c818fa = () => interopDefault(import('../pages/index/resources/_id/purchase/completed.vue' /* webpackChunkName: "pages/index/resources/_id/purchase/completed" */))
const _4d99a52d = () => interopDefault(import('../pages/index/resources/_id/success.vue' /* webpackChunkName: "pages/index/resources/_id/success" */))
const _fd63202a = () => interopDefault(import('../pages/index/resources/_id/view.vue' /* webpackChunkName: "pages/index/resources/_id/view" */))
const _51673054 = () => interopDefault(import('../pages/index/sign-up.vue' /* webpackChunkName: "pages/index/sign-up" */))
const _029b27d7 = () => interopDefault(import('../pages/index/sign-up/index.vue' /* webpackChunkName: "pages/index/sign-up/index" */))
const _6bb89748 = () => interopDefault(import('../pages/index/sign-up/success.vue' /* webpackChunkName: "pages/index/sign-up/success" */))
const _6977d978 = () => interopDefault(import('../pages/index/support.vue' /* webpackChunkName: "pages/index/support" */))
const _e5690cd8 = () => interopDefault(import('../pages/index/terms-accepted.vue' /* webpackChunkName: "pages/index/terms-accepted" */))
const _11d47678 = () => interopDefault(import('../pages/index/tip.vue' /* webpackChunkName: "pages/index/tip" */))
const _24f247b2 = () => interopDefault(import('../pages/index/tip/_id/index.vue' /* webpackChunkName: "pages/index/tip/_id/index" */))
const _532eccab = () => interopDefault(import('../pages/index/tip/_id/completed.vue' /* webpackChunkName: "pages/index/tip/_id/completed" */))
const _e719da70 = () => interopDefault(import('../pages/index/_.vue' /* webpackChunkName: "pages/index/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/offline",
    component: _8ce5946e,
    name: "offline"
  }, {
    path: "/payflow",
    component: _8795d788,
    name: "payflow",
    children: [{
      path: "cancel",
      component: _aa3db946,
      name: "payflow-cancel"
    }, {
      path: "error",
      component: _8ac2c816,
      name: "payflow-error"
    }, {
      path: "success",
      component: _740fd9a0,
      name: "payflow-success"
    }]
  }, {
    path: "/test/captcha",
    component: _806e9ff2,
    name: "test-captcha"
  }, {
    path: "/test/colors",
    component: _c76cc51a,
    name: "test-colors"
  }, {
    path: "/test/help-center",
    component: _53a4cbee,
    name: "test-help-center"
  }, {
    path: "/test/homepage-layout",
    component: _489186f6,
    name: "test-homepage-layout"
  }, {
    path: "/test/image-rendering",
    component: _660d1527,
    name: "test-image-rendering"
  }, {
    path: "/test/misc",
    component: _b2b374a2,
    name: "test-misc"
  }, {
    path: "/test/pattern",
    component: _1c0b9e1d,
    name: "test-pattern"
  }, {
    path: "/test/secret",
    component: _1048e29a,
    name: "test-secret"
  }, {
    path: "/test/sign-up",
    component: _0dbb5c90,
    name: "test-sign-up"
  }, {
    path: "/test/upload",
    component: _3fe23d84,
    name: "test-upload"
  }, {
    path: "/",
    component: _07569cb8,
    children: [{
      path: "",
      component: _1438b93b,
      name: "index"
    }, {
      path: "admin",
      component: _45a80d38,
      children: [{
        path: "",
        component: _82586c8a,
        name: "index-admin"
      }, {
        path: "accounting",
        component: _43b9e5fc,
        name: "index-admin-accounting"
      }, {
        path: "email-all",
        component: _701e454e,
        name: "index-admin-email-all"
      }, {
        path: "exceptions",
        component: _068e8deb,
        name: "index-admin-exceptions",
        children: [{
          path: "advertising",
          component: _0e488a80,
          name: "index-admin-exceptions-advertising"
        }, {
          path: "payouts",
          component: _72adb069,
          name: "index-admin-exceptions-payouts"
        }, {
          path: "publishing",
          component: _19265247,
          name: "index-admin-exceptions-publishing"
        }, {
          path: "support",
          component: _37d0e7ab,
          name: "index-admin-exceptions-support"
        }]
      }, {
        path: "settings",
        component: _51dd1f6c,
        name: "index-admin-settings"
      }, {
        path: "statistics",
        component: _34e25d8a,
        name: "index-admin-statistics"
      }, {
        path: "users",
        component: _043da3f1,
        name: "index-admin-users"
      }]
    }, {
      path: "advertising",
      component: _4c57cdcd,
      children: [{
        path: "",
        component: _3cb40690,
        name: "index-advertising"
      }, {
        path: "create",
        component: _fcfdd3a4,
        name: "index-advertising-create"
      }, {
        path: "manage",
        component: _76e093d2,
        name: "index-advertising-manage"
      }, {
        path: ":id",
        component: _2fb26cf8,
        children: [{
          path: "",
          component: _d3f14d0a,
          name: "index-advertising-id"
        }, {
          path: "approve",
          component: _4a5dfe36,
          name: "index-advertising-id-approve"
        }, {
          path: "edit",
          component: _0b13a31e,
          children: [{
            path: "",
            component: _d3ef4798,
            name: "index-advertising-id-edit"
          }, {
            path: "ad-spend",
            component: _181c2dc2,
            name: "index-advertising-id-edit-ad-spend"
          }, {
            path: "button",
            component: _1679f040,
            name: "index-advertising-id-edit-button"
          }, {
            path: "code",
            component: _042406fb,
            name: "index-advertising-id-edit-code"
          }, {
            path: "delete",
            component: _6a486a39,
            name: "index-advertising-id-edit-delete"
          }, {
            path: "description",
            component: _298912de,
            name: "index-advertising-id-edit-description"
          }, {
            path: "discount-code",
            component: _6b864e4a,
            name: "index-advertising-id-edit-discount-code"
          }, {
            path: "identity",
            component: _5ee68e4c,
            name: "index-advertising-id-edit-identity"
          }, {
            path: "image",
            component: _dc5b1f86,
            name: "index-advertising-id-edit-image"
          }, {
            path: "payment",
            component: _6f1b3e30,
            name: "index-advertising-id-edit-payment"
          }, {
            path: "review",
            component: _dfd38534,
            name: "index-advertising-id-edit-review"
          }, {
            path: "statement",
            component: _7742d151,
            name: "index-advertising-id-edit-statement"
          }, {
            path: "status",
            component: _0fe33900,
            name: "index-advertising-id-edit-status"
          }, {
            path: "styling",
            component: _13946d10,
            name: "index-advertising-id-edit-styling"
          }, {
            path: "subject",
            component: _004e810e,
            name: "index-advertising-id-edit-subject"
          }, {
            path: "subtitle",
            component: _2787a6b4,
            name: "index-advertising-id-edit-subtitle"
          }, {
            path: "targeting",
            component: _0dd4b0da,
            name: "index-advertising-id-edit-targeting"
          }, {
            path: "title",
            component: _46dd22ba,
            name: "index-advertising-id-edit-title"
          }, {
            path: "url",
            component: _e785665e,
            name: "index-advertising-id-edit-url"
          }]
        }, {
          path: "submitted",
          component: _59726ca4,
          name: "index-advertising-id-submitted"
        }]
      }]
    }, {
      path: "donate",
      component: _78f970d6,
      children: [{
        path: "",
        component: _20677ed9,
        name: "index-donate"
      }, {
        path: "cancel",
        component: _33324583,
        name: "index-donate-cancel"
      }, {
        path: "completed",
        component: _d553cf5c,
        name: "index-donate-completed"
      }, {
        path: "payment",
        component: _37936e4d,
        name: "index-donate-payment"
      }]
    }, {
      path: "donation",
      component: _6253ccb9,
      children: [{
        path: "",
        component: _450fb708,
        name: "index-donation"
      }, {
        path: "cancel",
        component: _18365440,
        name: "index-donation-cancel"
      }, {
        path: "payment",
        component: _19dd91a0,
        children: [{
          path: "",
          component: _180399b3,
          name: "index-donation-payment"
        }, {
          path: "completed",
          component: _4d5593a8,
          name: "index-donation-payment-completed"
        }]
      }]
    }, {
      path: "earnings",
      component: _2294fe50,
      children: [{
        path: "",
        component: _23c4ee5a,
        name: "index-earnings"
      }, {
        path: "activity",
        component: _0f421b1e,
        name: "index-earnings-activity"
      }, {
        path: "data",
        component: _d653348e,
        name: "index-earnings-data"
      }, {
        path: "graph",
        component: _4f6af962,
        name: "index-earnings-graph"
      }, {
        path: "payout",
        component: _dda39816,
        name: "index-earnings-payout"
      }]
    }, {
      path: "favorites",
      component: _30e4dfc0,
      name: "index-favorites"
    }, {
      path: "following",
      component: _adbc8a0c,
      name: "index-following"
    }, {
      path: "help",
      component: _3629c468,
      name: "index-help"
    }, {
      path: "helpbook",
      component: _6700fad1,
      children: [{
        path: "",
        component: _40a06094,
        name: "index-helpbook"
      }, {
        path: "accept-terms",
        component: _0a3f2cb0,
        name: "index-helpbook-accept-terms"
      }, {
        path: "manage",
        component: _3e2e9c93,
        name: "index-helpbook-manage"
      }, {
        path: ":id",
        component: _483577fc,
        children: [{
          path: "",
          component: _8f21dd02,
          name: "index-helpbook-id"
        }, {
          path: "video",
          component: _380400a8,
          name: "index-helpbook-id-video"
        }]
      }]
    }, {
      path: "login",
      component: _443f475c,
      name: "index-login"
    }, {
      path: "logout",
      component: _2b3c3671,
      name: "index-logout"
    }, {
      path: "new",
      component: _9980b96e,
      name: "index-new"
    }, {
      path: "offers",
      component: _53a483fe,
      name: "index-offers",
      children: [{
        path: ":index",
        component: _0a37de34,
        name: "index-offers"
      }]
    }, {
      path: "portfolio",
      component: _3b119a31,
      name: "index-portfolio"
    }, {
      path: "profile",
      component: _3b31335c,
      name: "index-profile",
      children: [{
        path: "tip-completed",
        component: _fa7788c8,
        name: "index-profile-tip-completed"
      }, {
        path: "tip-summary",
        component: _049c81b7,
        name: "index-profile-tip-summary"
      }, {
        path: ":id?",
        component: _e8ef8a06,
        children: [{
          path: "",
          component: _4a49cfc0,
          name: "index-profile-id"
        }, {
          path: "about",
          component: _2a91f4fb,
          name: "index-profile-id-about"
        }, {
          path: "message",
          component: _cc29f216,
          name: "index-profile-id-message"
        }, {
          path: "resources",
          component: _bb102eda,
          name: "index-profile-id-resources"
        }, {
          path: "settings",
          component: _1297f025,
          name: "index-profile-id-settings"
        }, {
          path: "share",
          component: _1c1fa84d,
          name: "index-profile-id-share"
        }, {
          path: "status",
          component: _385f9218,
          name: "index-profile-id-status"
        }, {
          path: "tip",
          component: _9dd68eee,
          children: [{
            path: "",
            component: _6facf44c,
            name: "index-profile-id-tip"
          }]
        }]
      }]
    }, {
      path: "publishing",
      component: _2baaf15a,
      name: "index-publishing"
    }, {
      path: "resource-not-found",
      component: _62b4e3f0,
      name: "index-resource-not-found"
    }, {
      path: "resources",
      component: _c0e0c6e4,
      name: "index-resources",
      children: [{
        path: ":id?",
        component: _70f2f339,
        children: [{
          path: "",
          component: _4e7f2a08,
          name: "index-resources-id"
        }, {
          path: "approve",
          component: _79ce9912,
          name: "index-resources-id-approve"
        }, {
          path: "completed",
          component: _61463af5,
          name: "index-resources-id-completed"
        }, {
          path: "donation",
          component: _658b9150,
          name: "index-resources-id-donation"
        }, {
          path: "downloaded",
          component: _9dba4626,
          name: "index-resources-id-downloaded"
        }, {
          path: "edit",
          component: _ccf72e60,
          children: [{
            path: "",
            component: _0519d95a,
            name: "index-resources-id-edit"
          }, {
            path: "categories",
            component: _5002792a,
            name: "index-resources-id-edit-categories"
          }, {
            path: "delete",
            component: _1f96d20c,
            name: "index-resources-id-edit-delete"
          }, {
            path: "description",
            component: _54a09286,
            name: "index-resources-id-edit-description"
          }, {
            path: "files",
            component: _3fa96b10,
            name: "index-resources-id-edit-files"
          }, {
            path: "grade",
            component: _5a08c050,
            name: "index-resources-id-edit-grade"
          }, {
            path: "images",
            component: _32136547,
            name: "index-resources-id-edit-images"
          }, {
            path: "keywords",
            component: _7811308e,
            name: "index-resources-id-edit-keywords"
          }, {
            path: "pre-check",
            component: _b977e542,
            name: "index-resources-id-edit-pre-check"
          }, {
            path: "price",
            component: _a23eef6c,
            name: "index-resources-id-edit-price"
          }, {
            path: "reading-level",
            component: _c7a653f8,
            name: "index-resources-id-edit-reading-level"
          }, {
            path: "review",
            component: _16026a27,
            name: "index-resources-id-edit-review"
          }, {
            path: "skills",
            component: _303923de,
            name: "index-resources-id-edit-skills"
          }, {
            path: "standards",
            component: _bf93b552,
            name: "index-resources-id-edit-standards"
          }, {
            path: "status",
            component: _d461347e,
            name: "index-resources-id-edit-status"
          }, {
            path: "subject-area",
            component: _5f299b1d,
            name: "index-resources-id-edit-subject-area"
          }, {
            path: "title",
            component: _a3704c4e,
            name: "index-resources-id-edit-title"
          }]
        }, {
          path: "feature",
          component: _fa8ba2c0,
          children: [{
            path: "",
            component: _dcdd85ba,
            name: "index-resources-id-feature"
          }, {
            path: "completed",
            component: _78f8bcc8,
            name: "index-resources-id-feature-completed"
          }]
        }, {
          path: "leave-tip",
          component: _764ce82f,
          children: [{
            path: "",
            component: _05df611c,
            name: "index-resources-id-leave-tip"
          }, {
            path: "completed",
            component: _5982f46b,
            name: "index-resources-id-leave-tip-completed"
          }, {
            path: "summary",
            component: _bbfdbb74,
            name: "index-resources-id-leave-tip-summary"
          }]
        }, {
          path: "purchase",
          component: _212d6547,
          children: [{
            path: "",
            component: _60749a8a,
            name: "index-resources-id-purchase"
          }, {
            path: "completed",
            component: _e5c818fa,
            name: "index-resources-id-purchase-completed"
          }]
        }, {
          path: "success",
          component: _4d99a52d,
          name: "index-resources-id-success"
        }, {
          path: "view",
          component: _fd63202a,
          name: "index-resources-id-view"
        }]
      }]
    }, {
      path: "sign-up",
      component: _51673054,
      children: [{
        path: "",
        component: _029b27d7,
        name: "index-sign-up"
      }, {
        path: "success",
        component: _6bb89748,
        name: "index-sign-up-success"
      }]
    }, {
      path: "support",
      component: _6977d978,
      name: "index-support"
    }, {
      path: "terms-accepted",
      component: _e5690cd8,
      name: "index-terms-accepted"
    }, {
      path: "tip",
      component: _11d47678,
      name: "index-tip",
      children: [{
        path: ":id",
        component: _24f247b2,
        name: "index-tip-id"
      }, {
        path: ":id?/completed",
        component: _532eccab,
        name: "index-tip-id-completed"
      }]
    }, {
      path: "*",
      component: _e719da70,
      name: "index-all"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
